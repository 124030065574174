export default class Util {
  public static isObject(item: any) {
    return item && typeof item === 'object' && !Array.isArray(item);
  }

  public static merge(target: any, ...sources: any): any {
    if (!sources.length) return target;

    const source = sources.shift();

    if (this.isObject(target) && this.isObject(source)) {
      for (const key in source) {
        // if is file ignore
        if (
          this.isObject(source[key]) &&
          (source[key]['lastModified'] || source[key].length === 0)
        ) {
        } else if (this.isObject(source[key])) {
          if (!target[key]) {
            Object.assign(target, { [key]: {} });
          } else {
            target[key] = Object.assign({}, target[key]);
          }
          this.merge(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }

    return this.merge(target, ...sources);
  }

  /**
   * sleep
   */
  public static sleep(seconds: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, seconds * 1000);
    });
  }
}
