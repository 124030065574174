import React, { useState, useEffect, useRef } from 'react';
import 'react-day-picker/lib/style.css';
import 'firebase/firestore';
import 'firebase/auth';

import {
  Row,
  Col,
  Table,
  Form,
  Dropdown,
  DropdownButton,
  Pagination,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import firebase from 'firebase';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';

import { useToast } from '../../../hooks/Toast';
import Button from '../../../components/Button';
import CustomPagination from '../../../components/CustomPagination';
import api from '../../../services/api';
import Loader from '../../../components/Loader';
import { useAuth } from '../../../hooks/Auth2';
import Util from '../../../utils/util';

type FieldsItemType = {
  name: string;
};

const ParticipantsRsvp: React.FC<{ event: any }> = (props: any) => {
  const refFile = useRef<HTMLInputElement>(null);
  const { user } = useAuth();
  const { t, i18n } = useTranslation();
  const [active, setActive] = useState<number>(1);
  const [registerField, setRegisterField] = useState<any>({});
  const { register, setValue, handleSubmit, errors, reset } = useForm<any>({});
  const { addToast } = useToast();
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState('');
  const [filters, setFilters] = useState<any>({});
  const [pagination, setPagination] = useState<{ take: number; skip: number }>({
    take: 20,
    skip: 0,
  });
  const [fields, setFields] = useState<Array<FieldsItemType>>([]);
  const [participants, setParticipants] = useState<any[]>([]);
  const [resources, setResources] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [allParticipants, setAllParticipants] = useState<any[]>([]);
  const [allParticipantsIsBusy, setAllParticipantsIsBusy] = useState(false);

  function loadParticipants(page?: number, _filters?: any) {
    if (props.event.id) {
      setIsBusy(true);

      const _pagination = page
        ? { ...pagination, skip: (page - 1) * pagination.take }
        : pagination;

      api
        .post('/admin/participant/list', {
          filter: { eventId: props.event.id,"fields.country": {$exists:true, $nin:["","Brazil"]} , ...(_filters || filters) },
          pagination: _pagination,
        })
        .then((r) => {
          setParticipants(r.data.result);
          getFields(r.data.result);
          setCount(r.data.count);
        })
        .finally(() => {
          setIsBusy(false);
        });
    }
  }

  function getFields(participants: any[]): Array<FieldsItemType> {
    const fields: Array<string> = [];

    participants.forEach(
      (p) => p.fields && Object.keys(p.fields).map((item) => fields.push(item)),
    );

    console.log('participants fields');

    const items = Array.from(new Set(fields))
      .filter((f) => f !== 'CRM' && f !== 'UF' && !f.startsWith('__EMPTY'))
      .map<FieldsItemType>((f) => ({
        name: f,
      }));

    setFields(items);
    return items;
  }

  useEffect(() => {
    reset();
    setRegisterField({});
    loadParticipants();
  }, [props.params.id, props.event.id, pagination]);

  useEffect(() => {
    console.log('useEffect participants', props);
    if (props.event?.id) {
      (async () => {
        setAllParticipantsIsBusy(true);
        let _participants: any[] = [];
        let count = 0;
        let page = 1;
        let take = 1000;
        do {
          const _result = await api.post<{ result?: any[]; count?: number }>(
            `/admin/participant/list`,
            {
              filter: { eventId: props.event.id, ...(filters) },
              pagination: { skip: (page - 1) * take, take },
            },
          );

          if (_result.data.count) count = _result.data.count;

          if (_result.data.result)
            _participants = [..._participants, ..._result.data.result];

          page++;
          console.log('dowhile', { _participants, count });
        } while (count > _participants.length);
        setAllParticipants(_participants);
        setAllParticipantsIsBusy(false);
      })();
    }
  }, [props.event, filters]);


  function handleFilterChange(e: React.ChangeEvent<HTMLInputElement>) {
    const _value = e.currentTarget.value;
    setFilter(_value);
  }

  function handleFilterClick() {
    setActive(1);
    setFilters((prev: any) => {
      if (!filter) {
        const { name, email, ..._filter } = prev;
        loadParticipants(1, _filter);

        return _filter;
      }

      const _filters = {
        ...prev,
        name: filter.trim(),
        email: filter.trim(),
      };

      loadParticipants(1, _filters);

      return _filters;
    });
  }

  function handleEnterKeyPressed(e: React.KeyboardEvent<HTMLInputElement>) {
    console.log('key', { key: e.key, code: e.keyCode });
    if (e.key.toLowerCase() === 'enter') {
      handleFilterClick();
    }
  }

  const changePage = (page: number) => {
    setPagination({ ...pagination, skip: (page - 1) * pagination.take });
    setActive(page);
  };


  const handleAccept = async (entity: any) => {
    try {
      const updatedParticipant = { ...entity };
      updatedParticipant.statusRSVP = 1;
      updatedParticipant.approver = user?.email;
      setLoadingSubmit(true);
      const result = await api.post('/admin/participant', updatedParticipant);
      loadParticipants();

      addToast({
        type: 'success',
        title: t('successfull update'),
        description: t('your info was updated successfully'),
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: t('error on update'),
        description:
          error?.response?.data?.message ||
          error.message ||
          t('an error has ocurred on update, try again.'),
      });
    }
  };

  const handleDeny = async (entity: any) => {
    try {
      const updatedParticipant = { ...entity };
      updatedParticipant.statusRSVP = 2;
      updatedParticipant.approver = user?.email;
      setLoadingSubmit(true);
      const result = await api.post('/admin/participant', updatedParticipant);
      loadParticipants();

      addToast({
        type: 'success',
        title: t('successfull register'),
        description: t('your info was sent successfully'),
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: t('error on register'),
        description:
          error?.response?.data?.message ||
          error.message ||
          t('an error has ocurred on register, try again.'),
      });
    }
  };

  console.log('participants render', { participants, event: props?.event });

  return (
    <>
      <div>
        <div className="row mb-2">
          <div className="col-12 col-md">
            <div className="form-inline">
              <label>{t('filter')}: </label>

              <input
                type="text"
                name="filter"
                placeholder={t('search for participant name or email')}
                className="form-control flex-grow-1 ml-2 mr-2"
                value={filter}
                onChange={(e) => handleFilterChange(e)}
                onKeyUp={handleEnterKeyPressed}
              />
              <button
                className="btn btn-primary m-0"
                onClick={handleFilterClick}
              >
                Filtrar
              </button>
            </div>
          </div>
        </div>
      </div>

      <Table size="sm">
        <thead>
          <tr>
            <th>{t('name')}</th>
            <th>Email</th>
            <th>{t('specialty')}</th>
            <th>{t('country')}</th>
            <th>Status RSVP</th>
            <th>{t('update')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {isBusy && (
            <tr>
              <td colSpan={5} className="text-center">
                <Loader />
              </td>
            </tr>
          )}
          {!isBusy &&
              participants
              .sort((a: any, b: any) => a.statusRSVP - b.statusRSVP)
              .map(
                (item: any) =>
                  (
                    <tr>
                      <td>{item.name}</td>
                      <td>{item.email}</td>
                      <td>{item.fields.specialty}</td>
                      <td>{item.fields.country}</td>
                      <td>
                        {item.statusRSVP === 0
                          ? 'em Análise'
                          : item.statusRSVP === 1||
                            !!item.statusRSVP === false
                            ? t('approved')
                            : t('not approved')}
                      </td>
                      <td>
                        <Row>
                          <Col xl={3} md={6}>
                            <DropdownButton
                              disabled={item.statusRSVP}
                              title={t('update')}
                            >
                              <Dropdown.Item onClick={() => handleAccept(item)}>
                                {t('approve')}
                              </Dropdown.Item>
                              <Dropdown.Item onClick={() => handleDeny(item)}>
                                {t('refuse')}
                              </Dropdown.Item>
                            </DropdownButton>
                          </Col>
                        </Row>
                      </td>
                      <td></td>
                    </tr>
                  ),
              )}
        </tbody>
      </Table>
      {!isBusy && count > 0 && (
        <CustomPagination
          page={active}
          count={count}
          take={pagination.take}
          onPageChange={(page) => changePage(page)}
          size="sm"
        />
      )}
    </>
  );
};

export default ParticipantsRsvp;
