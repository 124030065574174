import React, { useState, useCallback, useEffect, useMemo } from 'react';
import 'react-day-picker/lib/style.css';
import 'firebase/firestore';
import 'firebase/auth';
import { useAuth } from '../../../hooks/FirebaseAuth';
import * as Enumerable from 'linq-es2015';
import api from '../../../services/api';
import Loader from '../../../components/Loader';
import * as Yup from 'yup';
import DashboardMasterPage from '../../../masterpages/DashboardMasterPage';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {
  Row,
  Col,
  Card,
  Tabs,
  Container,
  Tab,
  Table,
  Form,
  InputGroup,
  Modal,
  Dropdown,
  DropdownButton,
} from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import Datetime from 'react-datetime';
import { SketchPicker } from 'react-color';
import CustomButton from '../../../components/Button';
import { useForm } from 'react-hook-form';
import produce from 'immer';
import { Link, useHistory } from 'react-router-dom';
import { useToast } from '../../../hooks/Toast';
import firebase from 'firebase';
import moment from 'moment';
import { v4 } from 'uuid';
import { FiEdit } from 'react-icons/fi';
import { asEnumerable } from 'linq-es2015';
import { useTranslation } from 'react-i18next';
import Util from '../../../utils/util';
import ColorPicker from 'rc-color-picker';
import { Checkbox } from 'antd';
import { DatePicker, Space, Button } from 'antd';
import { useEmitter } from '../../../hooks/Emitter';
const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;
interface Entity {
  id: string;
  flag: string;
  label: string;
  language: string;
  default: boolean;
  urlAudio: string;
}
interface AudioSettings {
  id: string;
  flag: string;
  label: string;
  language: string;
  default: boolean;
  urlAudio: string;
}

interface AudioLang {
  createdAt: number;
  updateAt: number;
  flag: string;
  id: string;
  label: string;
  language: string;
}
interface Channels {
  id: string;
  name: string;
  key: string;
}

const Audio: React.FC<{
  event: any;
  setUrlImageCover(s: string): void;
  urlImageCover: string;
  setSaveVideoCover(s: string): void;
  channel: any;
}> = (props) => {
  //   const [entity, setEntity] = useState<Entity>({} as Entity);
  const { emitter, events } = useEmitter();
  const [loadDefa, setLoadDefa] = useState<boolean>(false);
  const [channelId, setChannelId] = useState<string>(props.channel.id);
  const { register, setValue, handleSubmit, errors, reset, watch } = useForm<
    any
  >({});
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [loadingNewAudio, setLoadingNewAudio] = useState<boolean>(false);
  const [isVisibleAudioDefault, setIsVisibleAudioDefault] = useState<boolean>(
    false,
  );
  const [audio, setAudio] = useState<AudioSettings[]>([]);
  const [audioLang, setAudioLang] = useState<AudioLang[]>([]);
  const [isCopyed, setIsCopyed] = useState<boolean>(false);
  const [loadingUpdateCover, setLoadingUpdateCover] = useState<boolean>(false);
  const { addToast } = useToast();

  const uploadImage = async (inputFile: any) => {
    if (typeof inputFile !== 'string' && inputFile?.length) {
      const uploaded: any = await firebase
        .storage()
        .ref(`/companies/${props.event.key}/customization/${v4()}`)
        .put(inputFile[0]);
      const url = (await uploaded.ref.getDownloadURL()) || '';
      props.setUrlImageCover(url);
    }
  };

  // const loadChannel = () => {
  //     if (!props?.event?.id) {
  //         return
  //     }
  //     api.get(`/admin/channel/all/${props?.event?.id}`).then(res => {
  //         setChannels(res.data)
  //     }).catch((error) => {
  //         console.log(error)
  //     }).finally(() => {
  //         setIsLoaded(true)
  //     })
  // }
  const loadAudio = () => {
    if (!channelId) return;
    api
      .get(`/admin/event/audio/${channelId}`)
      .then((res) => {
        setAudio(res.data);
        console.log('data', res.data);
        setIsVisibleAudioDefault(true);
      })
      .catch((error) => {
        console.log(error);
        // addToast({
        //     type: 'error',
        //     title: 'Erro',
        //     description:
        //         error?.response?.data?.message ||
        //         error.message ||
        //         'Ocorreu um erro ao fazer cadastro, tente novamente.',
        // });
      })
      .finally(() => {
        setIsLoaded(true);
      });
  };

  const deleteLang = (langId: string) => {
    api
      .delete(`/admin/language/audioLang/${channelId}/${langId}`)
      .then(() => {
        loadAudio();
      })
      .catch((error) => {
        console.log(error);
        addToast({
          type: 'error',
          title: 'Erro',
          description:
            error?.response?.data?.message ||
            error.message ||
            'Ocorreu um erro ao fazer cadastro, tente novamente.',
        });
      })
      .finally(() => {});
  };

  const updateVideoCover = () => {
    if (loadingUpdateCover) return;

    props.setSaveVideoCover('isSubmit');
  };

  const updateDefault = () => {
    if (loadDefa) return;
    setLoadDefa(true);
    api
      .put(`/admin/language/audioLang/${channelId}`, { lang: audio })
      .then(() => {
        loadAudio();
      })
      .catch((error) => {
        console.log(error);
        addToast({
          type: 'error',
          title: 'Erro',
          description:
            error?.response?.data?.message ||
            error.message ||
            'Ocorreu um erro ao fazer cadastro, tente novamente.',
        });
      })
      .finally(() => {
        setLoadDefa(false);
      });
  };

  const loadLang = () => {
    api
      .get('/admin/language')
      .then((res) => {
        setAudioLang(res.data);
        console.log('audiolang', res.data);
      })
      .catch((error) => {
        console.log(error);
        // addToast({
        //     type: 'error',
        //     title: 'Erro',
        //     description:
        //         error?.response?.data?.message ||
        //         error.message ||
        //         'Ocorreu um erro ao fazer cadastro, tente novamente.',
        // });
      });
  };
  useEffect(() => {
    // loadChannel()
  }, [props?.event?.id]);

  // useEffect(() => {
  //     if (props.event.id) {
  //         const unsubscribe = firebase
  //             .firestore()
  //             .collection('channel')
  //             // .orderBy('customization.channel_group')
  //             .where('eventId', '==', props.event.id)
  //             .onSnapshot((snapshot) => {
  //                 const docs = snapshot.docs.map((m) => m.data());

  //                 //   if (docs.length === 0) setIsFirstChannel(true);

  //                 const data: any = Enumerable.asEnumerable(docs)
  //                     .OrderBy((d) => d.customization?.channel_group)
  //                     .ThenBy((d) => d.name)
  //                     .ToArray();
  //                 console.log(data)
  //                 setChannels(data)
  //                 setIsLoaded(true)
  //             });

  //         return () => {
  //             unsubscribe();
  //         };
  //     }
  // }, [props.params.id, props.event.id]);
  useEffect(() => {
    loadLang();
    loadAudio();
  }, [channelId]);

  useEffect(() => {
    setChannelId(props.channel.id);
  }, [props?.channel?.id]);

  async function saveChannel(
    _channel: any,
    op: 'create' | 'update' | 'delete',
  ) {
    await api
      .post('/admin/channel', _channel)
      .then((r) => {
        let title = '';

        if (op === 'create') title = 'Audio cadastrado';
        else if (op === 'update') title = 'Audio editado';
        else if (op === 'delete') title = 'Audio removido';

        addToast({
          type: 'success',
          title,
          description: 'Sua informação foi enviada com sucesso!',
        });

        emitter.emit(events.AUDIO_SETTINGS_CHANGED, _channel);
      })
      .catch((e) => {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            e?.response?.data?.message ||
            e.message ||
            'Ocorreu um erro ao fazer cadastro, tente novamente.',
        });
      });
  }

  // set values into form dinamicaly
  function handleEditItem(item: any) {
    Object.keys(item).map((key) => setValue(key, item[key]));
  }

  const onSubmitNewLangAudio = (data: Entity) => {
    let voiceSettings: any[] = Array.from(props.channel?.voiceSettings || []);

    if (voiceSettings.length) {
      voiceSettings = voiceSettings.filter((v) => v.id !== data.id);
    }
    voiceSettings.push(data);

    const _data = {
      ...props.channel,
      voiceSettings,
    };
    if (data.language.length === 0 || data.urlAudio.length === 0) {
      return addToast({
        type: 'error',
        title: 'Error',
        description: 'Ocorreu um erro ao fazer cadastro, tente novamente.',
      });
    }
    if (loadingNewAudio) return;
    setLoadingNewAudio(true);

    saveChannel(_data, 'update')
      .then(() => {
        // loadAudio();
        reset();
      })
      .catch((error) => {
        console.log(error);
        addToast({
          type: 'error',
          title: 'Error',
          description:
            error?.response?.data?.message ||
            error.message ||
            'Ocorreu um erro ao fazer cadastro, tente novamente.',
        });
      })
      .finally(() => {
        setLoadingNewAudio(false);
      });
  };

  // if (isLoaded && channels.length === 0) {
  //     return (
  //         <div className="text-center" style={{ width: '100%' }}>
  //             Crie uma sala primeiro
  //         </div>
  //     )
  // }
  if (!isLoaded) {
    return (
      <div className="text-center" style={{ width: '100%' }}>
        <Loader />
      </div>
    );
  }
  return (
    <React.Fragment>
      <>
        <Form
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault();
            updateVideoCover();
          }}
        >
          <Form.Row>
            <Form.Group as={Col} md="6">
              <Form.Label htmlFor={'cover'}>Capa do Vídeo</Form.Label>
              <Form.File
                type="file"
                name={`cover`}
                custom
                label={
                  props.urlImageCover
                    ? 'Foi feito um upload de uma imagem'
                    : 'Selecione uma imagem para fazer o upload'
                }
                onChange={(e: any) => {
                  e.preventDefault();
                  const a: any = e.target.files;
                  if (String(a[0].name).length !== 0) {
                    uploadImage(a);
                  }
                }}
                autoComplete="off"
              />
              <div style={{ padding: 10 }} />
              {props.urlImageCover && (
                <>
                  <Form.Label htmlFor="email">
                    Clique no link para copiar a url para área de transferência
                  </Form.Label>
                  <CopyToClipboard
                    text={props.urlImageCover}
                    onCopy={() => setIsCopyed(true)}
                  >
                    <div
                      style={{
                        border: 'solid',
                        borderWidth: 1,
                        borderColor: '#e5e5e5',
                        padding: 7,
                        cursor: 'pointer',
                      }}
                    >
                      <span>{props.urlImageCover}</span>
                    </div>
                  </CopyToClipboard>
                </>
              )}
              <div style={{ padding: 5 }} />
              {isCopyed && (
                <span style={{ fontSize: 12, color: 'green' }}>
                  Link copiado para a área de transferência, cole-o no idioma
                  que você está editando
                </span>
              )}
              <div style={{ padding: 5 }} />

              <div style={{ padding: 5 }} />
              {/* {props.urlImageCover && <div id='tipouser' className={`input-group col-${Col} mb-3`}>
                            <Button loading={loadingUpdateCover} htmlType="submit">
                                Aplicar Imagem como capa do vídeo
                            </Button>
                        </div>} */}
            </Form.Group>
            <Form.Group className="text-center column" as={Col} md="6">
              {props.urlImageCover && (
                <img
                  src={props.urlImageCover}
                  className="img-rounded"
                  alt="Cinque Terre"
                  width="304"
                />
              )}
              <div style={{ padding: 5 }} />
              {props.urlImageCover && (
                <span
                  onClick={() => {
                    props.setUrlImageCover('');
                    props.setSaveVideoCover('isSubmit');
                  }}
                  className="cursor"
                >
                  remover
                </span>
              )}
            </Form.Group>
          </Form.Row>
        </Form>

        <hr />
        <Form
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault();
            updateDefault();
          }}
        >
          <Form.Row>
            <Form.Group as={Col} md="6">
              <label htmlFor="tipouser">Áudio Padrão</label>
              <div id="tipouser" className={`input-group col-${Col} mb-3`}>
                <div className="input-group-prepend">
                  <label
                    className="input-group-text"
                    htmlFor="inputGroupSelect01"
                  >
                    <img
                      src={
                        audio.length !== 0
                          ? audio[audio.findIndex((e) => e.default === true)]
                              ?.flag
                          : ''
                      }
                      width="20"
                    />
                  </label>
                </div>
                <select
                  value={
                    audio.length !== 0
                      ? audio[audio.findIndex((e) => e.default === true)]?.label
                      : ''
                  }
                  onChange={(es: any) => {
                    const index: number = audio.findIndex(
                      (res) => res.label === es.target.value,
                    );
                    const newAudio = produce(
                      audio,
                      (draftState: AudioSettings[]) => {
                        draftState.map((r: AudioSettings, i: number) => {
                          if (i === index) {
                            r.default = true;
                          } else {
                            r.default = false;
                          }
                        });
                      },
                    );
                    setAudio(newAudio);
                  }}
                  className="custom-select"
                >
                  <option
                    value={
                      audio.length !== 0
                        ? audio[
                            audio?.findIndex((e) => e.default === true) || 0
                          ]?.label
                        : ''
                    }
                  >
                    {audio.length !== 0
                      ? audio[audio.findIndex((e) => e.default === true)]?.label
                      : 'Selecione....'}
                  </option>
                  {audio.length !== 0 &&
                    audio?.map((res: AudioSettings, index: number) => {
                      if (
                        index !== audio.findIndex((e) => e.default === true)
                      ) {
                        return (
                          <option key={index} value={res.label}>
                            {res.label}
                          </option>
                        );
                      }
                    })}
                </select>
              </div>
              <div id="tipouser" className={`input-group col-${Col} mb-3`}>
                <Button loading={loadDefa} htmlType="submit">
                  Salvar Padrão
                </Button>
              </div>
            </Form.Group>
          </Form.Row>
        </Form>
        <hr />
      </>
      <Form autoComplete="off" onSubmit={handleSubmit(onSubmitNewLangAudio)}>
        <Form.Row>
          <Form.Group as={Col} md="6">
            <label htmlFor="tipouser">Novo áudio</label>
            <div id="tipouser" className={`input-group col-${Col} mb-3`}>
              <div className="input-group-prepend">
                <label
                  className="input-group-text"
                  htmlFor="inputGroupSelect01"
                >
                  <img src={watch('flag')} width="20" />
                </label>
              </div>

              <input ref={register} type="hidden" name="id" />
              <input ref={register} type="hidden" name="language" />
              <input ref={register} type="hidden" name="flag" />
              <select
                ref={register}
                name="label"
                onChange={(es: any) => {
                  setValue(
                    'label',
                    audioLang[
                      audioLang.findIndex((e) => e.label === es.target.value)
                    ].label,
                  );
                  setValue(
                    'flag',
                    audioLang[
                      audioLang.findIndex((e) => e.label === es.target.value)
                    ].flag,
                  );
                  setValue(
                    'language',
                    audioLang[
                      audioLang.findIndex((e) => e.label === es.target.value)
                    ].language,
                  );
                  setValue(
                    'id',
                    audioLang[
                      audioLang.findIndex((e) => e.label === es.target.value)
                    ].id,
                  );
                }}
                className="custom-select"
              >
                <option value="">{'Selecione....'}</option>
                {audioLang.length !== 0 &&
                  audioLang.map((res: AudioLang, index: number) => {
                    if (
                      index !==
                      audioLang.findIndex(
                        (e) => e.label === watch('voiceSettings[label]'),
                      )
                    ) {
                      return (
                        <option key={index} value={res.label}>
                          {res.label}
                        </option>
                      );
                    }
                  })}
              </select>
            </div>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label htmlFor="email">URL Áudio</Form.Label>
            <Form.Control
              ref={register}
              type="text"
              name="urlAudio"
              autoComplete="off"
            />
            <>
              {errors[`urlAudio`] && (
                <span className="error">URL áudio é obrigatório</span>
              )}
            </>
          </Form.Group>
          <Form.Group
            as={Col}
            sm={12}
            className="mt-3"
            style={{ textAlign: 'right' }}
          >
            <Button loading={loadingNewAudio} htmlType="submit">
              Salvar
            </Button>
          </Form.Group>
        </Form.Row>
      </Form>
      <Table size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Language</th>
            <th>Label</th>
            <th>default</th>
            <th>url</th>
            <th>Atualizar</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {!isLoaded && (
            <tr>
              <td colSpan={12} className="text-center">
                <Loader />
              </td>
            </tr>
          )}
          {isLoaded &&
            props?.channel?.voiceSettings?.length !== 0 &&
            props?.channel?.voiceSettings?.map(
              (item: AudioSettings, i: number) => (
                <tr key={i}>
                  <th scope="row">{i + 1}</th>
                  <td>{item.language}</td>
                  <td>{item.label}</td>

                  <td>{item.default ? 'sim' : 'não'}</td>
                  <td className="textopeg">{item.urlAudio}</td>
                  <td>
                    <Row>
                      <Col xl={3} md={6}>
                        <DropdownButton title="Editar">
                          <Dropdown.Item onClick={() => handleEditItem(item)}>
                            Editar
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => deleteLang(item.id)}>
                            Deletar
                          </Dropdown.Item>
                        </DropdownButton>
                      </Col>
                    </Row>
                  </td>
                  <td></td>
                </tr>
              ),
            )}
        </tbody>
      </Table>
    </React.Fragment>
  );
};
export default Audio;
