import React, {
  useCallback,
  useRef,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Row,
  Col,
  Input,
  Alert,
  Container,
  Label,
  FormGroup,
} from 'reactstrap';

import { Link, useHistory } from 'react-router-dom';
import { FiLogIn, FiMail, FiLock } from 'react-icons/fi';
import * as Yup from 'yup';
import { useAuth } from '../../hooks/Auth2';
import { useToast } from '../../hooks/Toast';
import getValidationErrors from '../../utils/getValidationErrors';
import Button from '../../components/Button';
import { AnimationContainer } from './styles';
import DefaultMasterPage from '../../masterpages/DefaultMasterPage';
import { useEvent } from '../../hooks/EventContext';

import logo from '../../assets/images/logo.png';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { signinValidationSchema } from '../../validationSchemas/signin';
import CustomField from '../../components/CustomField';

interface SignInFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = (props: any) => {
  const { signIn } = useAuth();
  const [isBusy, setIsBusy] = useState(false);
  const { addToast } = useToast();
  const history = useHistory();
  const eventContext = useEvent();

  async function handleOnSubmit(data: any) {
    console.log('handleOnSubmit', data);

    try {
      setIsBusy(true);

      await signIn({
        email: data.email,
        password: data.password,
        eventKey: props.params.event,
      });

      history.push(`/dashboard`);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro na autenticaçāo',
        description:
          error?.response?.data?.message ||
          error.message ||
          'Ocorreu um erro ao fazer cadastro, tente novamente.',
      });
    } finally {
      setIsBusy(false);
    }
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/">
          <i className="mdi mdi-home-variant h2 text-white"></i>
        </Link>
      </div>

      <div>
        <Container fluid className="p-0">
          <Row className="no-gutters">
            <Col lg={4}>
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <Row className="justify-content-center">
                    <Col lg={9}>
                      <div>
                        <div className="text-center">
                          <div>
                            <Link to="/" className="logo">
                              <img src={logo} height="120" alt="logo" />
                            </Link>
                          </div>

                          <h4 className="font-size-18 mt-4">Bem vindo</h4>
                          <p className="text-muted">RSTCom Admin</p>
                        </div>

                        {/* {this.props.loginError && this.props.loginError ? (
                          <Alert color="danger">{this.props.loginError}</Alert>
                        ) : null} */}

                        <div className="p-2 mt-5">
                          <Formik
                            initialValues={{ email: '', password: '' }}
                            validationSchema={signinValidationSchema}
                            onSubmit={(data) => handleOnSubmit(data)}
                          >
                            <Form className="form-horizontal">
                              <FormGroup className="auth-form-group-custom mb-4">
                                <i className="ri-user-2-line auti-custom-input-icon"></i>
                                <Label htmlFor="email">E-mail</Label>
                                <CustomField
                                  name="email"
                                  type="text"
                                  id="email"
                                  placeholder="Informe seu e-mail"
                                />
                                <ErrorMessage
                                  component="div"
                                  name="email"
                                  className="invalid-feedback"
                                />
                              </FormGroup>

                              <FormGroup className="auth-form-group-custom mb-4">
                                <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                <Label htmlFor="password">Senha</Label>
                                <CustomField
                                  name="password"
                                  type="password"
                                  id="password"
                                  placeholder="Informe sua senha"
                                />
                                <ErrorMessage
                                  component="div"
                                  name="password"
                                  className="invalid-feedback"
                                />
                              </FormGroup>

                              <div className="mt-4 text-center">
                                <Button
                                  loading={isBusy}
                                  color="primary"
                                  className="w-md waves-effect waves-light"
                                  type="submit"
                                >
                                  Entrar
                                </Button>
                              </div>
                            </Form>
                          </Formik>
                        </div>

                        <div className="mt-5 text-center">
                          <p>© 2020 RSTCOM.</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <div className="authentication-bg">
                <div className="bg-overlay"></div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SignIn;
