import React, { useEffect, useRef, useState } from 'react';
import {
  Col,
  Dropdown,
  DropdownButton,
  Form,
  Row,
  Table,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import firebase from 'firebase';

import api from '../../../../../services/api';
import { useToast } from '../../../../../hooks/Toast';
import { Button } from 'antd';
import { Spinner } from 'reactstrap';
import { useEmitter } from '../../../../../hooks/Emitter';

type FormData = {
  index: number;
  name_languages: [];
  link_languages: [];
  order: string;
};

interface IExternalLinksTab {
  channel: any;
}

const ExternalLinksTab: React.FC<IExternalLinksTab> = ({ channel }) => {
  const { emitter, events } = useEmitter();
  const attachmentRef = useRef<HTMLInputElement>(null);
  const { addToast } = useToast();
  const link_key = 'externallink';
  const [editItem, setEditItem] = useState<any>({});
  const [links, setLinks] = useState([]);
  const { t, i18n } = useTranslation();
  const [preview_attachment, setPreviewAttachment] = useState('');
  const [isBusy, setIsBusy] = useState<boolean>(false);

  const { register, setValue, reset, handleSubmit, errors } = useForm<any>();

  useEffect(() => {
    if (channel?.resources) {
      const _resources = channel.resources.map((v: any, i: number) => ({
        ...v,
        index: i,
      }));
      const _links = _resources.filter((r: any) => r.key === link_key);
      setLinks(_links);
    }
  }, [channel.resources]);

  async function upload(inputFile: any, eventKey: string) {
    if (typeof inputFile !== 'string' && inputFile.length) {
      const uploaded: any = await firebase
        .storage()
        .ref(`/companies/${eventKey}/externallinks/${v4()}`)
        .put(inputFile[0]);
      return (await uploaded.ref.getDownloadURL()) || '';
    }

    return '';
  }

  async function save(data: any) {
    try {
      setIsBusy(true);
      data.key = link_key;
      data.tab = true;

      const _channel = { ...channel };

	  data.name_languages = {...editItem.name_languages, ...data.name_languages}
	  data.link_languages = {...editItem.link_languages, ...data.link_languages}

      if (preview_attachment) {
        data.attachment = await upload(
          attachmentRef.current?.files,
          channel.eventId,
        );
      }

      if (!editItem.index) {
        _channel.resources.push(data);
      } else
        _channel.resources = _channel.resources.map((r: any, i: number) => {
          if (i === editItem.index) r = data;

          return r;
        });

      await saveChannel(_channel, editItem.index ? 'update' : 'create');

      reset();
      setEditItem({});
      setPreviewAttachment('');

      if (attachmentRef?.current) attachmentRef.current.value = '';
    } catch (error) {
    } finally {
      setIsBusy(false);
    }
  }

  async function saveChannel(
    _channel: any,
    op: 'create' | 'update' | 'delete',
  ) {
    await api
      .post('/admin/channel', _channel)
      .then((r) => {
        let title = '';

        if (op === 'create') title = 'Link externo cadastrado';
        else if (op === 'update') title = 'Link externo editado';
        else if (op === 'delete') title = 'Link externo removido';

        addToast({
          type: 'success',
          title: t("successfull register"),
          description: t("your info was sent successfully"),
        });

        emitter.emit(events.EXTERNAL_LINK_CHANGED, _channel);
      })
      .catch((e) => {
        addToast({
          type: 'error',
          title: t("error on register"),
          description:
            errors?.response?.data?.message ||
            errors.message ||
            t("an error has ocurred on register, try again."),
        });
      });
  }

  function edit(item: any, index: number) {
    setEditItem(item);
    Object.keys(item).map((v) => {
      setValue(v, item[v]);
    });
    setValue('id', index);
  }

  function deleteEntity(item: any, index: number) {
    if (window.confirm(t("are you sure that you want to delete this?"))) {
      const _channel = { ...channel };

      _channel.resources = _channel.resources.filter(
        (v: any, i: number) => i !== item.index,
      );

      saveChannel(_channel, 'delete');
    }
  }

  function handleAttachmentChange(e: React.ChangeEvent<HTMLInputElement>) {
    const _files = e.currentTarget.files;

    if (!_files?.length) return;

    const reader = new FileReader();
    reader.readAsDataURL(_files[0]);
    reader.onload = () => setPreviewAttachment(reader.result as string);
    reader.onerror = (error) => alert(error);
  }

  function handleApplyAttachmentLink() {
    i18n.languages.map(
      (lang: string) =>
        i18n.language == lang &&
        setValue(`link_languages[${lang}]`, editItem?.attachment),
    );
  }

  // function getDefaultLang() {
  //   const _defaultLangs = props.event?.settings?.languages?.filter(
  //     (v: any) => v.default,
  //   );

  //   if (_defaultLangs.length) return _defaultLangs[0];
  // }

  return (
    <div>
      <form onSubmit={handleSubmit(save)}>
        <div className="form-row">
          {i18n.languages.map(
            (lang: string) =>
              i18n.language == lang && (
                <Form.Group key={lang} as={Col} md="6">
                  <Form.Label htmlFor={`name_languages[${lang}]`}>
                    Nome de exibição
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name={`name_languages[${lang}]`}
                    defaultValue={
                      (editItem?.name_languages || {})[lang] || editItem.name
                    }
                    autoComplete="off"
                    ref={register({ required: true })}
                  />
                  <>
                    {errors[`name_languages`] && (
                      <span className="error">Nome é obrigatório</span>
                    )}
                  </>
                </Form.Group>
              ),
          )}

          <Form.Group as={Col} md="6">
            <Form.Label htmlFor={`order`}>Ordem</Form.Label>
            <Form.Control
              type="number"
              name={`order`}
              autoComplete="off"
              ref={register({ required: true })}
            />
            <>
              {errors[`order`] && (
                <span className="error">Ordem é obrigatória</span>
              )}
            </>
          </Form.Group>

          {i18n.languages.map(
            (lang: string) =>
              i18n.language == lang && (
                <Form.Group key={lang} as={Col} md="6">
                  <Form.Label htmlFor={`link_languages[${lang}]`}>
                    Link
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name={`link_languages[${lang}]`}
                    defaultValue={
                      (editItem?.link_languages || {})[lang] || editItem.link
                    }
                    autoComplete="off"
                    ref={register({ required: true })}
                  />
                  <>
                    {errors[`link_languages`] && (
                      <span className="error">Link é obrigatório</span>
                    )}
                  </>
                </Form.Group>
              ),
          )}

          <div className="col-6">
            <div className="row">
              <Form.Group className="mb-2" as={Col} md="12">
                <Form.Label htmlFor="attachment">Anexo</Form.Label>
                <div className="custom-file">
                  <Form.File
                    ref={attachmentRef}
                    label="Subir arquivo"
                    name="attachment"
                    onChange={handleAttachmentChange}
                  />
                </div>
              </Form.Group>

              {(!!preview_attachment || !!editItem?.attachment) && (
                <div className="col-12 mb-3">
                  <a
                    href={editItem?.attachment}
                    className="d-block"
                    target="_blank"
                  >
                    <img
                      src={preview_attachment || editItem?.attachment}
                      style={{ height: '100px' }}
                    />
                  </a>

                  {editItem?.attachment && (
                    <button
                      className="btn btn-primary mt-2"
                      type="button"
                      onClick={handleApplyAttachmentLink}
                    >
                      Aplicar Anexo
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <button className="btn btn-primary" type="submit">
          {!isBusy ? (
            'Salvar Link'
          ) : (
            <Spinner className="ml-3 mr-3" size={'sm'} />
          )}
        </button>
      </form>

      <Table style={{ tableLayout: 'fixed' }} size="sm">
        <thead>
          <tr>
            <th style={{ width: '10%' }}>#</th>
            <th style={{ width: '25%' }}>{t("name")}</th>
            <th style={{ width: '35%' }}>Url</th>
            <th style={{ width: '10%' }}>{t("order")}</th>
            <th style={{ width: '15%' }}>{t("update")}</th>
          </tr>
        </thead>
        <tbody>
          {links.map((item: any, i: number) => (
            <tr key={i}>
              <th scope="row">{i + 1}</th>
              <td>{(item.name_languages || {})[i18n.language]}</td>
              <td>
                <a
                  target="__blank"
                  href={(item.link_languages || {})[i18n.language]}
                >
                  {(item.link_languages || {})[i18n.language]}
                </a>
              </td>
              <td>{item.order}</td>
              <td>
                <Row>
                  <Col xl={3} md={6}>
                    <DropdownButton title={t("update")}>
                      <Dropdown.Item onClick={() => edit(item, i)}>
                        {t("edit")}
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => deleteEntity(item, i)}>
                        {t("delete")}
                      </Dropdown.Item>
                    </DropdownButton>
                  </Col>
                </Row>
              </td>
              <td />
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ExternalLinksTab;
