import React, { useState, useCallback, useEffect, useMemo } from 'react';
import 'react-day-picker/lib/style.css';
import 'firebase/firestore';
import 'firebase/auth';
import { useAuth } from '../../../hooks/Auth2';
import api from '../../../services/api';
import * as Yup from 'yup';
import DashboardMasterPage from '../../../masterpages/DashboardMasterPage';
import { Container } from './styles';
import {
  Row,
  Col,
  Card,
  Tabs,
  Tab,
  Table,
  Form,
  InputGroup,
  Modal,
  Dropdown,
  DropdownButton,
} from 'react-bootstrap';
import Datetime from 'react-datetime';
import { SketchPicker } from 'react-color';
import CustomButton from '../../../components/Button';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { useToast } from '../../../hooks/Toast';
import firebase from 'firebase';
import moment from 'moment';
import { v4 } from 'uuid';
import { FiEdit } from 'react-icons/fi';
import { asEnumerable } from 'linq-es2015';
import { useTranslation } from 'react-i18next';
import Util from '../../../utils/util';
import ColorPicker from 'rc-color-picker';
import { Checkbox } from 'antd';
import { DatePicker, Space, Button } from 'antd';
const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;

const Reports: React.FC<{ event: any }> = (props: any) => {
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { user } = useAuth();
  const { t, i18n } = useTranslation();
  const [registerField, setRegisterField] = useState<any>({});
  const { register, setValue, handleSubmit, errors, reset } = useForm<any>({});
  const history = useHistory();
  const { addToast } = useToast();
  const [reports, setReports] = useState<any[]>([]);
  const [showElement, setShowElement] = useState<any>({});
  const [resources, setResources] = useState<any>({});
  const [checkedList, setCheckedList] = useState<any[]>([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  const onChange = (checkedList: any) => {
    setCheckedList(checkedList);
    setIndeterminate(
      !!checkedList.length && checkedList.length < resources.list?.length,
    );
    setCheckAll(checkedList.length === resources.list?.length);
  };

  const onCheckAllChange = (e: any) => {
    setCheckedList(e.target.checked ? resources.list : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const onSubmit = async function (data: any) {
    try {
      setLoadingSubmit(true);

      const register = { ...registerField, ...data };
      register.eventId = props.event.id;

      console.log('submit', { register, registerField, data });

      for (const report of data.key) {
        const result = await api.post('/admin/report', {
          ...register,
          key: report,
        });
      }
      setRegisterField({});
      reset();
      setCheckedList([]);

      addToast({
        type: 'success',
        title: t("successfull register"),
        description: t("your info was sent successfully"),
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: t("error on register"),
        description:
          error?.response?.data?.message ||
          error.message ||
          t("an error has ocurred on register, try again."),
      });
    }

    setLoadingSubmit(false);
  };

  async function upload(inputFile: any, eventKey: string) {
    if (typeof inputFile !== 'string' && inputFile.length) {
      const uploaded: any = await firebase
        .storage()
        .ref(`/companies/${eventKey}/customization/${v4()}`)
        .put(inputFile[0]);
      return (await uploaded.ref.getDownloadURL()) || '';
    }

    return '';
  }

  function edit(entity: any) {
    setRegisterField(entity);

    for (const key in entity) {
      if (entity.hasOwnProperty(key)) {
        const element = entity[key];
        try {
          if (key != 'resources' && key != 'picture') {
            setValue(key, element);
          }
        } catch (error) {}
      }
    }

    setCheckedList(entity.resources?.map((a: any) => a.name) || []);
  }

  async function deleteEntity(entity: any) {
    if (window.confirm(t("are you sure that you want to delete this?"))) {
      const result = await api.delete('/admin/report/' + entity.id);
    }
  }

  useEffect(() => {
    reset();
    setRegisterField({});

    if (props.event.id) {
      const unsubscribe = firebase
        .firestore()
        .collection('report')
        .where('eventId', '==', props.event.id)
        .onSnapshot((snapshot) => {
          let docs = snapshot.docs.map((m) => m.data());
          docs = docs.sort((a: any, b: any) => {
            if (a.createdAt > b.createdAt) return -1;
            else if (a.createdAt < b.createdAt) return 1;
            else return 0;
          });
          setReports(docs);
        });

      return () => {
        unsubscribe();
      };
    }
  }, [props.params.id, props.event.id]);

  useEffect(() => {
    if (!resources.list) {
      const data = firebase
        .firestore()
        .collection('settings')
        .doc('resources')
        .get()
        .then((doc) => {
          setResources(doc.data());
        });
    }
  }, [props.params.id]);

  function getReportNameByKey(key: string): string | undefined {
    const _keys: any = {
      'report-sessions': 'Sessões',
      'report-sessions-firebase': 'Presença',
      'report-access': 'Acessos',
      'report-questions': 'Perguntas',
      'report-questions-by-key': 'Perguntas por chave',
      'report-messages': 'Mensagens',
      'report-timeline': 'Timeline',
      'report-survey': 'Pesquisa',
      'report-participants': 'Participantes',
      'report-votation': 'Votação',
      'report-special': 'Relatório Especial',
      'report-analytics': 'Relatório Analytics',
      'report-analytics2': 'Relatório Analytics RD',
      'report-showroom-analytics': 'Relatório Analytics ShowRoom',
    };

    return _keys[key];
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Row>
          <Form.Group as={Col} md="6">
            <Form.Label htmlFor="report">{t("report")}</Form.Label>
            <Form.Control
              name="key"
              as="select"
              style={{ minHeight: 150 }}
              multiple
              value={registerField.report}
              onChange={(e: any) => {
                setRegisterField({
                  ...registerField,
                  companyId: e.target.value,
                });
              }}
              ref={register({ required: true })}
            >
              <option value="">{t("please select a report")}</option>
              <option value="report-sessions">
              {t("general report access")}
              </option>
              <option value="report-messages">{t("chat report")}</option>
              <option value="report-timeline">{t("timeline report")}</option>
              <option value="report-survey">{t("survey report")}</option>
              <option value="report-participants">{t("enrolled report")}</option>
              <option value="report-questions">
              {t("ask speaker report")}
              </option>
              <option value="report-questions-by-key">
              {t("ask key report")}
              </option>
              <option value="report-votation">{t("votation report")}</option>
              <option value="report-analytics">
              {t("analytics report")} - Google
              </option>
              <option value="report-analytics2">
              {t("analytics report")} - MongoDB
              </option>
              <option value="report-showroom-analytics">
              {t("showroom report")} Analytics
              </option>
              <option value="report-special">{t("special report")}</option>
            </Form.Control>
            <>
              {errors[`report`] && (
                <span className="error">{t("report is obligatory")}</span>
              )}
            </>
          </Form.Group>

          <Form.Group as={Col} md="6">
            <Form.Label htmlFor="email">{t("recipient's email")}</Form.Label>
            <Form.Control
              defaultValue={registerField.email}
              type="text"
              name="email"
              autoComplete="off"
              ref={register({ required: true })}
            />
            <>
              {errors[`email`] && (
                <span className="error">
                  {t("recipient's email is obligatory")}
                </span>
              )}
            </>
          </Form.Group>

          <Form.Group as={Col} md="6">
            <Form.Label htmlFor="customization[date]">{t("period")}</Form.Label>
            <RangePicker
              name="period"
              showTime
              value={[
                registerField.start ? moment(registerField.start) : null,
                registerField.end ? moment(registerField.end) : null,
              ]}
              onChange={(e) => {
                setRegisterField({
                  ...registerField,
                  start: e?.[0]?.valueOf(),
                  end: e?.[1]?.valueOf(),
                });
              }}
            />
          </Form.Group>
        </Form.Row>
        <Form.Group
          as={Col}
          sm={12}
          className="mt-3"
          style={{ textAlign: 'right' }}
        >
          <Button loading={loadingSubmit} htmlType="submit">
          {t("request report")}
          </Button>
        </Form.Group>
      </Form>

      <Table size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>{t("report")}</th>
            <th>{t("sender")}</th>
            <th>{t("receiver")}</th>
            <th>{t("download report")}</th>
            <th>Status</th>
            <th>{t("date")}</th>
          </tr>
        </thead>
        <tbody>
          {reports.map((item: any, i: number) => (
            <tr key={i}>
              <th scope="row">{item.id}</th>
              <td>
                {item.link && (
                  <a href={item.link}>{getReportNameByKey(item.key)}</a>
                )}
                {!item.link && getReportNameByKey(item.key)}
              </td>
              <td>{item.user?.name}</td>
              <td>{item.email}</td>
              <td>
                {item.link && (
                  <a href={item.link}>{getReportNameByKey(item.key)}</a>
                )}
              </td>
              <td>{item.status}</td>
              <td>
                {moment(item.createdAt).utc().format('DD/MM/YYYY hh:mm:ss')}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default Reports;
