import axios, { AxiosError } from 'axios';
import { useAuth } from '../hooks/Auth2';
import { refreshToken } from './session';

const debug = 'http://localhost:3120/api';
const emulator = 'http://localhost:3333/rstcom20/us-central1/api';
const sandbox = 'https://us-central1-rstcom-sandbox.cloudfunctions.net/api';
const remote = 'https://us-central1-rstcom20.cloudfunctions.net/api';

let url = remote;

if (document.location.href.indexOf('admin-homologacao.rstcom.stream') >= 0)
  url = sandbox;
else if (document.location.href.indexOf('localhost') > -1) {
  url = debug;
  // url = sandbox;
}
// else if (document.location.href.indexOf('127.0.0.1') > -1) {
//   url = debug;
// }
//  else if (document.location.href.indexOf('127.0.0.1') > -1) {
//   url = debug;
// }
// else if (document.location.href.indexOf('localhost:3331') > -1) {
//   url = emulator;
// }
console.log(url);
const api = axios.create({
  baseURL: url,
});

api.interceptors.request.use(async (config) => {
  const _token = localStorage.getItem('@Storage:token');

  if (!!_token) {
    config.headers['authorization'] = `bearer ${_token}`;
  }
  return config;
});

// api.interceptors.response.use(
//   (res) => {
//     // console.log("intercept reponse", res);
//     return res;
//   },
//   async (error: AxiosError) => {
//     const { response } = error;

//     if (response?.status === 401) {
//       const _token = await refreshToken();

//       if (_token) {
//         error.config.headers['authorization'] = `bearer ${_token}`;
//         api.defaults.headers.authorization = `bearer ${_token}`;
//         return api.request(error.config);
//       }
//     }

//     // console.log("intercept error unhandled", error);

//     return Promise.reject(error);
//   },
// );

export default api;
