import React, { useState, useEffect, useRef } from 'react';
import 'react-day-picker/lib/style.css';
import 'firebase/firestore';
import 'firebase/auth';

import {
    Row,
    Col,
    Table,
    Form,
    Dropdown,
    DropdownButton,
    Pagination,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import firebase from 'firebase';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { useToast } from '../../../hooks/Toast';
import Button from '../../../components/Button';
import CustomPagination from '../../../components/CustomPagination';
import api from '../../../services/api';
import Loader from '../../../components/Loader';
import { useAuth } from '../../../hooks/Auth';

const LP: React.FC<{ event: any }> = (props: any) => {
    const [registerField, setRegisterField] = useState<any>({});
    const { register, setValue, handleSubmit, errors, reset } = useForm<any>({});
    const [loading, setLoading] = useState<boolean>(false)
    const [entity, setEntity] = useState<any>({})
    const { t } = useTranslation();

    const { addToast } = useToast();

    const uploadImageCenter = (e: any) => {
        e.preventDefault()
        const a: any = e.target.files;
        if (String(a[0].name).length !== 0) {
            var reader = new FileReader();
            var file = a[0];
            reader.onload = function (upload: any) {
                console.log(upload.target.result)
                setEntity({ ...entity, center: upload.target.result })
                return upload.target.result

            };
            reader.readAsDataURL(file);
        }
    }

    const uploadImageLeft = (e: any) => {
        e.preventDefault()
        const a: any = e.target.files;
        if (String(a[0].name).length !== 0) {
            var reader = new FileReader();
            var file = a[0];
            reader.onload = function (upload: any) {
                console.log(upload.target.result)
                setEntity({ ...entity, left: upload.target.result })
                return upload.target.result

            };
            reader.readAsDataURL(file);
        }
    }

    const uploadImageRight = (e: any) => {
        e.preventDefault()
        const a: any = e.target.files;
        if (String(a[0].name).length !== 0) {
            var reader = new FileReader();
            var file = a[0];
            reader.onload = function (upload: any) {
                console.log(upload.target.result)
                setEntity({ ...entity, right: upload.target.result })
                return upload.target.result

            };
            reader.readAsDataURL(file);
        }
    }



    useEffect(() => {
        if (props?.params?.id) {
            api.get(`/admin/event/lp/${props?.params?.id}`).then(r => {
                setRegisterField(r.data)
            }).catch(error => {
                console.log(error)
                // addToast({
                //     type: 'error',
                //     title: 'Erro no cadastro',
                //     description:
                //         error?.response?.data?.message ||
                //         error.message ||
                //         'Ocorreu um erro ao fazer cadastro, tente novamente.',
                // });
            })
        }

    }, [props?.params?.id])


    const onSubmit = (data: any) => {
        if (loading) return;
        setLoading(true)
        const datas = {
            ...registerField,
            ...data,
            left: entity?.left,
            right: entity?.right,
            center: entity?.center,
            id: props?.params?.id
        }
        api.post('/admin/event/update', datas).then(res => {
            addToast({
                type: 'success',
                title: t("successfull register"),
                description: t("your info was sent successfully"),
            });
        }).catch((error) => {
            addToast({
                type: 'error',
                title: 'Erro no cadastro',
                description:
                    error?.response?.data?.message ||
                    error.message ||
                    t("an error has ocurred on register, try again."),
            });
        }).finally(() => {
            setLoading(false)
        })

    }


    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Row>
                    <h6>Section About</h6>
                    <hr style={{ width: '100%' }} />
                    <Form.Group as={Col} md="12">
                        <Form.Label htmlFor="title">{t("title")}</Form.Label>
                        <Form.Control
                            defaultValue={registerField.title}
                            type="text"
                            name="title"
                            autoComplete="off"
                            ref={register({ required: true })}
                        />
                        <>
                            {errors[`title`] && (
                                <span className="error">{t("title is obligatory")}</span>
                            )}
                        </>
                    </Form.Group>

                    <Form.Group as={Col} md="6">
                        <Form.Label htmlFor="paragrafo1">{t("paragraph")} 1</Form.Label>
                        <Form.Control
                            defaultValue={registerField.paragrafo1}
                            type="text"
                            name="paragrafo1"
                            autoComplete="off"
                            ref={register({ required: true })}
                        />
                        <>
                            {errors[`paragrafo1`] && (
                                <span className="error">{t("paragraph 1 is obligatory")}</span>
                            )}
                        </>
                    </Form.Group>


                    <Form.Group as={Col} md="6">
                        <Form.Label htmlFor="paragrafo2">{t("paragraph")} 2</Form.Label>
                        <Form.Control
                            defaultValue={registerField.paragrafo2}
                            type="text"
                            name="paragrafo2"
                            autoComplete="off"
                            ref={register({ required: true })}
                        />
                        <>
                            {errors[`paragrafo2`] && (
                                <span className="error">{t("paragraph 2 is obligatory")}</span>
                            )}
                        </>
                    </Form.Group>


                    <Form.Group as={Col} md="6">
                        <Form.Label htmlFor={'left'}>{t("left image")} (<span style={{ color: 'red', fontSize: 11 }}>*285x190</span>)</Form.Label>
                        <Form.File
                            type="file"
                            custom
                            name={`left`}
                            defaultValue={registerField?.left}
                            label={entity?.left ? t("it was made an image upload") : t("select an image to upload")}
                            onChange={(e: any) => uploadImageLeft(e)}
                            autoComplete="off"

                        />
                        <div style={{ padding: 10 }} />
                        {Boolean(entity?.left || registerField.left) && <span onClick={() => {
                            setEntity({ ...entity, left: '', })
                            setRegisterField({ ...registerField, left: '' })
                        }} style={{ color: 'red', width: '100%', textAlign: 'center', cursor: 'pointer' }}>{t("remove image")}</span>}

                    </Form.Group>
                    <Form.Group className='text-center column' as={Col} md="6">
                        {Boolean(entity?.left || registerField.left) && <img src={entity?.left ? entity?.left : registerField.left} alt="Cinque Terre" width="304" />}
                    </Form.Group>


                    <Form.Group as={Col} md="6">
                        <Form.Label htmlFor={'center'}>{t("middle image")} (<span style={{ color: 'red', fontSize: 11 }}>*570x380</span>)</Form.Label>
                        <Form.File
                            type="file"
                            name={`center`}
                            defaultValue={registerField?.center}
                            custom
                            onChange={(e: any) => uploadImageCenter(e)}
                            label={entity?.center ? t("it was made an image upload") : t("select an image to upload")}
                            autoComplete="off"

                        />
                        <div style={{ padding: 10 }} />
                        {Boolean(entity?.center || registerField.center) && <span onClick={() => {
                            setEntity({ ...entity, center: '' })
                            setRegisterField({ ...registerField, center: '' })
                        }} style={{ color: 'red', width: '100%', textAlign: 'center', cursor: 'pointer' }}>{t("remove image")}</span>}

                    </Form.Group>
                    <Form.Group className='text-center column' as={Col} md="6">
                        {Boolean(entity?.center || registerField.center) && <img src={entity?.center || registerField.center} className="img-rounded" alt="Cinque Terre" width="304" />}
                    </Form.Group>

                    <Form.Group as={Col} md="6">
                        <Form.Label htmlFor={'right'}>{t("right image")} (<span style={{ color: 'red', fontSize: 11 }}>*228x152</span>)</Form.Label>
                        <Form.File
                            type="file"
                            name={`right`}
                            defaultValue={registerField.right}
                            custom
                            onChange={(e: any) => uploadImageRight(e)}
                            label={entity?.right ? t("it was made an image upload") : t("select an image to upload")}
                            autoComplete="off"

                        />
                        <div style={{ padding: 10 }} />
                        {Boolean(entity?.right || registerField.right) && <span onClick={() => {
                            setEntity({ ...entity, right: '' })
                            setRegisterField({ ...registerField, right: '' })
                        }} style={{ color: 'red', width: '100%', textAlign: 'center', cursor: 'pointer' }}>{t("remove image")}</span>}
                    </Form.Group>
                    <Form.Group className='text-center column' as={Col} md="6">
                        {Boolean(entity?.right || registerField.right) && <img src={entity?.right || registerField.right} className="img-rounded" alt="Cinque Terre" width="304" />}

                    </Form.Group>

                </Form.Row>
                <Form.Group
                    as={Col}
                    sm={12}
                    className="mt-3"
                    style={{ textAlign: 'right' }}>
                    <Button loading={loading} type="submit">
                    {t("save")}
                    </Button>
                </Form.Group>
            </Form>
        </>
    );
};

export default LP;
