import React, { useState, useCallback, useEffect, useMemo } from 'react';
import 'react-day-picker/lib/style.css';
import 'firebase/firestore';
import 'firebase/auth';
import firebase from 'firebase';
import { CSVLink, CSVDownload } from 'react-csv';

import { useAuth } from '../../hooks/Auth2';
import api from '../../services/api';
import DashboardMasterPage from '../../masterpages/DashboardMasterPage';
import {
  Row,
  Col,
  Card,
  Tabs,
  Tab,
  Container,
  Nav,
  Table,
  DropdownButton,
  Dropdown,
  Pagination,
  TabPane,
} from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';

import Breadcrumbs from '../../components/Common/Breadcrumb';
import { useForm } from 'react-hook-form';
import { useToast } from '../../hooks/Toast';
import CustomPagination from '../../components/CustomPagination';
import Loader from '../../components/Loader';
import { Spinner } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const CustomOption = ({
  innerRef,
  innerProps,
}: {
  innerRef: any;
  innerProps: any;
}) => <div ref={innerRef} {...innerProps} />;

const ChatsManager: React.FC<{ event?: any; channel?: any }> = (props) => {
  const [cleanChatIsBusy, setCleanChatIsBusy] = useState(false);
  const [active, setActive] = useState<number>(1);
  const [registerField, setRegisterField] = useState<any>({});
  const { register, setValue, handleSubmit, errors, reset } = useForm<any>({});
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState('');
  const [filters, setFilters] = useState<any>({});
  const [pagination, setPagination] = useState<{ take: number; skip: number }>({
    take: 20,
    skip: 0,
  });
  const [eventValue, setEventValue] = useState<string>();
  const [chat, setChat] = useState<any>();
  const [events, setEvents] = useState<any[]>([]);
  const [channels, setChannels] = useState<any[]>([]);
  const [event, setEvent] = useState<any>({});
  const [channel, setChannel] = useState<any>({});

  const [isBusy, setIsBusy] = useState(false);
  const [channelsIsBusy, setChannelsIsBusy] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const { t } = useTranslation();

  async function loadEvents() {
    try {
      if (events.length && eventValue)
        return events.filter(
          (v) =>
            ((v.key || '') as string)
              .toLowerCase()
              .includes(eventValue?.toLowerCase() || '') ||
            ((v.name || '') as string)
              .toLowerCase()
              .includes(eventValue?.toLowerCase() || ''),
        );

      const _response = await api.get('/admin/event/all');
      setEvents(_response.data);
      return _response.data;
    } catch (error) {
      return [];
    }
  }

  async function loadChannels() {
    try {
      setChannelsIsBusy(true);

      if (events.length && eventValue)
        return events.filter((v) =>
          ((v.name || '') as string)
            .toLowerCase()
            .includes(eventValue?.toLowerCase() || ''),
        );

      const _response = await api.get(`/admin/channel/all/${event.id}`);
      setChannels(_response.data);
      return _response.data;
    } catch (error) {
      return [];
    } finally {
      setChannelsIsBusy(false);
    }
  }

  async function loadChat() {
    try {
      setIsEmpty(false);
      setIsBusy(true);

      const _channel = props.channel || channel;
      const _event = props.event || event;

      const _messagesRef = firebase.firestore().collection('chat');
      const query = _messagesRef;
      // .where('channelId', '==', _channel.id)
      // .where('eventId', '==', _event.id);
      query
        .where('channelId', '==', _channel.id)
        // .doc(_channel.id)
        .get()
        .then((snap) => {
          try {
            console.log('get chat messages');
            const docs = snap.docs;
            const _chat: any = docs[0]?.data();

            console.log('chat', _chat);

            if (!_chat.messages || _chat.messages.length === 0) {
              setIsEmpty(true);
            }

            setChat(_chat);
          } catch (error) {
            console.log('Error on load chat messages');
          }
        })
        .finally(() => {
          setIsBusy(false);
        });
    } catch (error) {
      console.log('Error on get chat messages', error);
    } finally {
    }
  }

  // useEffect(() => {
  //   reset();
  //   setRegisterField({});
  //   loadChats();
  // }, [event.id, channel.id, pagination]);

  useEffect(() => {
    setChannels([]);
    setChannel({});
    setChat({});

    if (event.id) loadChannels();
  }, [event.id]);

  useEffect(() => {
    setChat([]);

    if (channel.id) loadChat();
  }, [channel.id]);

  function handleFilterChange(e: React.ChangeEvent<HTMLInputElement>) {
    const _value = e.currentTarget.value;
    setFilter(_value);
  }

  // function handleFilterClick() {
  //   setActive(1);
  //   setFilters((prev: any) => {
  //     if (!filter) {
  //       const { name, email, ..._filter } = prev;
  //       loadChats(1, _filter);

  //       return _filter;
  //     }

  //     const _filters = {
  //       ...prev,
  //       name: filter,
  //       email: filter,
  //     };

  //     loadChats(1, _filters);

  //     return _filters;
  //   });
  // }

  const changePage = (page: number) => {
    setPagination({ ...pagination, skip: (page - 1) * pagination.take });
    setActive(page);
  };

  async function deleteEntity(entity: any) {
    if (window.confirm('Você tem certeza que deseja deletar isto?')) {
      const result = await api.delete('/admin/participant/' + entity.id);
      // loadChats();
    }
  }

  async function handleCleanChatClick() {
    if (window.confirm(t("are you sure that you want to clear the chat?"))) {
      try {
        if (chat?.messages?.length) {
          setCleanChatIsBusy(true);

          const _response = await api.post(
            `/admin/chat/clean/${chat.id}`,
            chat,
          );

          if (_response.status === 201) {
            alert(t("chat cleared successfully"));
            loadChat();
          }
        }
      } catch (error) {
      } finally {
        setCleanChatIsBusy(false);
      }
    }
  }

  function handleExportChatClick() {
    try {
    } catch (error) {}
  }

  function handleInputChange(newValue: string) {
    const inputValue = newValue.replace(/\W/g, '');
    setEventValue(inputValue);
    return inputValue;
  }

  useEffect(() => {
    if (event.id && channel.id) {
      loadChat();
    }
  }, [event.id, channel.id]);

  useEffect(() => {
    if (props.event?.id && props.channel?.id) {
      loadChat();
    }
  }, [props.event?.id, props.channel?.id]);

  return (
    <Row>
      {/* <Col sm={12} className="btn-page">
      <Card className="">
        <Card.Body>
          </Card.Body>
      </Card>
    </Col> */}

      <Col sm={12} className="btn-page">
        <Card className="">
          <Card.Body style={{ minHeight: '50vh' }}>
            {!props.event?.id && !props.channel?.id && (
              <div className="row">
                <div className="col-12">
                  <div className="form-row">
                    <div className="col-6">
                      <label>{t("event")}</label>
                      <AsyncSelect
                        cacheOptions
                        loadOptions={loadEvents}
                        placeholder={t("select an event")}
                        defaultOptions
                        // components={{ Option: CustomOption }}
                        getOptionValue={(option) => option['id']}
                        getOptionLabel={(option) =>
                          `${option['key']} - ${option['name']}`
                        }
                        onInputChange={handleInputChange}
                        onChange={(e) => setEvent(e)}
                      />
                      {/* <input
                      type="text"
                      name="filter"
                      placeholder="Pesquise pelo Nome ou Key do evento"
                      className="form-control flex-grow-1 ml-2 mr-2"
                      value={filter}
                      onChange={(e) => handleFilterChange(e)}
                      onKeyUp={handleEnterKeyPressed}
                    /> */}
                    </div>
                    <div className="col-6">
                      <label>{t("room")}</label>

                      <Select
                        defaultOptions
                        options={channels}
                        placeholder={t("select a channel")}
                        isLoading={channelsIsBusy}
                        getOptionValue={(option) => option['id']}
                        getOptionLabel={(option) =>
                          `${option['key']} - ${option['name']}`
                        }
                        value={channel.id ? channel : null}
                        onInputChange={handleInputChange}
                        onChange={(e) => setChannel(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {((event.id && channel.id) ||
              (props.event?.id && props.channel?.id)) && (
              <>
                <div className="row mb-2 mt-3">
                  <div className="col-12">
                    <div className="form-inline">
                      {/* <label>Filtro: </label>

                    <input
                      type="text"
                      name="filter"
                      placeholder="Pesquise pelo Nome ou Key do evento"
                      className="form-control flex-grow-1 ml-2 mr-2"
                      value={filter}
                      onChange={(e) => handleFilterChange(e)}
                      onKeyUp={handleEnterKeyPressed}
                    /> */}
                      <button
                        className="btn btn-primary m-0"
                        onClick={handleCleanChatClick}
                      >
                        {t("clear chat")}
                        {cleanChatIsBusy && <Spinner size="sm" />}
                      </button>
                      {chat?.messages?.length && (
                        <CSVLink
                          className="btn btn-primary m-0 ml-3"
                          data={chat?.messages}
                        >
                          {t("export messages")}
                        </CSVLink>
                      )}
                    </div>
                  </div>
                  <div className="col-6 d-flex align-items-end"></div>
                </div>
                <Table
                  striped
                  hover
                  className="table-condensed"
                  style={{ tableLayout: 'fixed' }}
                >
                  <thead>
                    <tr>
                      <th>{t("participant")}</th>
                      <th
                        style={{
                          maxWidth: '70%',
                        }}
                      >
                        {t("text")}
                      </th>
                      <th>{t("date/hour")}</th>
                      {/* <th>Ações</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {isBusy && (
                      <tr>
                        <td colSpan={3} className="text-center">
                          <Loader />
                        </td>
                      </tr>
                    )}

                    {isEmpty && !isBusy && (
                      <tr>
                        <td colSpan={3} className="text-center">
                          <h6>{t("cleaned chat")}</h6>
                        </td>
                      </tr>
                    )}

                    {!isBusy &&
                      chat?.messages &&
                      chat.messages.map((item: any, i: number) => (
                        // (!!item.active && item.active == 'true') &&
                        <tr key={i}>
                          <td>
                            {!!item.userName && item.userName.toUpperCase()}
                          </td>
                          <td
                            style={{
                              maxWidth: '70%',
                              wordWrap: 'break-word',
                            }}
                          >
                            {item.content}
                          </td>
                          <td>
                            {moment(item.createdAt).format('DD/MM/YYYY HH:mm')}
                          </td>
                          {/* <td>
                          <DropdownButton title="Atualizar">
                            <Dropdown.Item
                              onClick={() => deleteEntity(item)}
                            >
                              Deletar
                            </Dropdown.Item>
                          </DropdownButton>
                        </td> */}
                        </tr>
                      ))}
                  </tbody>
                </Table>
                {!isBusy && count > 0 && (
                  <CustomPagination
                    page={active}
                    count={count}
                    take={pagination.take}
                    onPageChange={(page) => changePage(page)}
                    size="sm"
                  />
                )}
              </>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default ChatsManager;
