import React, { useCallback, useRef, useContext, useState } from 'react';
import * as allIcons from 'react-icons/fi';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import api from '../../services/api';
import { useToast } from '../../hooks/Toast';
import Button from '../../components/Button';
import { Container, Content, AnimationContainer } from './styles';
import DefaultMasterPage from '../../masterpages/DefaultMasterPage';
import { useEvent } from '../../hooks/EventContext';
import { asEnumerable } from 'linq-es2015';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../hooks/Auth2';

const icons: any = allIcons;

interface SignUpFormData {
  eventKey: string;
  name: string;
  email: string;
  password: string;
}

const SignUp: React.FC = (props: any) => {
  const { register, setValue, handleSubmit, errors } = useForm<FormData>();
  const errorsAny: any = errors;
  const { addToast } = useToast();
  const history = useHistory();
  const eventContext = useEvent();
  const [passwordMessage, setPasswordMessage] = useState<any>();
  const { user } = useAuth();

  // if (user && user.id) {
  //   history.replace(`/${props.params.event}/dashboard`);
  // }

  const onSubmit = handleSubmit(async (data: any) => {
    try {

      const schema = Yup.object().shape({
        email: Yup.string()
          .required('E-mail é obrigatório')
          .email('Digite um e-mail válido'),
        password: Yup.string().min(6, 'Senha deve ter no mínimo 6 digitos'),
      });

      await schema.validate(data, {
        abortEarly: true,
      });

      data.eventKey = props.params.event;
      const participant = await api.post('/participant', data);

      history.push(`/${eventContext.eventKey}/signin`);

      addToast({
        type: 'success',
        title: 'Cadastro realizado',
        description: 'Você já pode fazer seu login!',
      });


    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro no cadastro',
        description: error?.response?.data?.message || error.message || 'Ocorreu um erro ao fazer cadastro, tente novamente.',
      });
    }
  });

  const emailValidation = (email: string) => {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
  };

  const passwordValidation = (password: string) => {
    let validation = <></>;

    // Validate lowercase letters
    var lowerCaseLetters = /[a-z]/g;
    if (!password.match(lowerCaseLetters)) {
      validation = <React.Fragment> {validation} <span className="error">A senha deve ter uma letra minúscula</span><br /></React.Fragment>
    }

    // Validate capital letters
    var upperCaseLetters = /[A-Z]/g;
    if (!password.match(upperCaseLetters)) {
      validation = <React.Fragment> {validation} <span className="error">A senha deve ter uma letra maiúscula</span><br /></React.Fragment>
    }

    // Validate numbers
    var numbers = /[0-9]/g;
    if (!password.match(numbers)) {
      validation = <React.Fragment> {validation} <span className="error">A senha deve ter ao menos um número</span><br /></React.Fragment>
    }

    // Validate length
    if (password.length < 8) {
      validation = <React.Fragment> {validation} <span className="error">A senha deve ter ao menos 8 caracteres</span><br /></React.Fragment>
    }

    setPasswordMessage(validation);

    return validation !== <></>
  };

  return (
    <DefaultMasterPage {...props}>
      <AnimationContainer>
        <form onSubmit={onSubmit}>
          <h1>Cadastre-se</h1>
          {asEnumerable(eventContext?.event?.registerFields || []).OrderBy((f: any) => f.order).Select((field: any, i) => {
            let formResult: JSX.Element = <></>;
            formResult = field.type == 'text' ? (<input type="text" className="form-control form-text" key={i} name={`dyn-${field.name.replace(/ /gi, '').toLowerCase()}`} placeholder={field.name} ref={register({ required: field.required })} />) : formResult;
            formResult = field.type == 'select' ? (
              <select className="form-control form-text" key={i} name={`dyn-${field.name.replace(/ /gi, '').toLowerCase()}`} ref={register({ required: field.required })} placeholder={field.name}>
                <option value="">{field.name}</option>
                {
                  asEnumerable(field?.dataList || []).OrderBy((f: any) => f.order).Select((value: any, i) => (
                    <option key={i} value={value}>{value}</option>
                  ))
                }
              </select>
            ) : formResult;
            return <React.Fragment key={i}>{formResult} <>{errorsAny[`dyn-${field.name.replace(/ /gi, '').toLowerCase()}`] && <span className="error">{field.name} é obrigatório</span>}</></React.Fragment>;
          }).ToArray()}

          <input name="email" className="form-control form-text" type="text" ref={register({ required: true, validate: emailValidation })} placeholder="E-mail Corporativo" />
          <>{errorsAny[`email`] && <span className="error">E-mail é obrigatório</span>}</>

          <input name="password" className="form-control form-text" type="password" ref={register({ required: true, validate: passwordValidation })} placeholder="Senha" />
          <>{errorsAny[`password`] && <span className="error">Senha é obrigatória</span>}</>
          <br />
          <>{passwordMessage}</>

          <div style={{ textAlign: 'center' }}>
            {asEnumerable(eventContext?.event?.terms || []).OrderBy((f: any) => f.order).Select((term: any, i) => (
              <div key={i} style={{ padding: '20px', fontSize: '9px', marginBottom: '0' }}>
                {term.description}
                <div>
                  <input name={`term-${i}`} type="checkbox" ref={register({ required: true })} /> Aceito
                </div>
              </div>
            )).ToArray()}
          </div>

          <Button type="submit">Cadastrar</Button>
          <Link className="link" to={`/${props.params.event}/signin`}>
            Voltar para o login
          </Link>
        </form>
      </AnimationContainer>
    </DefaultMasterPage>
  );
};

export default SignUp;
