import React, { useState, useCallback, useEffect, useMemo } from 'react';
import 'react-day-picker/lib/style.css';
import 'firebase/firestore';
import 'firebase/auth';
import { useAuth } from '../../../hooks/Auth2';
import api from '../../../services/api';
import * as Yup from 'yup';
import DashboardMasterPage from '../../../masterpages/DashboardMasterPage';
import { Container } from './styles';
import {
  Row,
  Col,
  Card,
  Tabs,
  Tab,
  Table,
  Form,
  InputGroup,
  Dropdown,
  DropdownButton,
} from 'react-bootstrap';
import Datetime from 'react-datetime';
import { SketchPicker } from 'react-color';
import CustomButton from '../../../components/Button';
import { Controller, useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { useToast } from '../../../hooks/Toast';
import firebase from 'firebase';
import moment from 'moment';
import { v4 } from 'uuid';
import { FiEdit } from 'react-icons/fi';
import { asEnumerable } from 'linq-es2015';
import { useTranslation } from 'react-i18next';
import Util from '../../../utils/util';
import ColorPicker from 'rc-color-picker';
import { Checkbox, Modal, Button } from 'antd';
import RichTextEditor from 'react-rte';
import './index.css';

const CheckboxGroup = Checkbox.Group;

type FormData = {
  order?: number;
  name?: string;
  facebook?: string;
  linkedin?: string;
  twitter?: string;
  instagram?: string;
  crm?: string;
  profession?: { [lang: string]: string };
  description?: { [lang: string]: string };
  picture?: string;
};

const Speakers: React.FC<{ event: any }> = (props: any) => {
  const [editorState, setEditorState] = React.useState(() =>
    RichTextEditor.createEmptyValue(),
  );
  const { user } = useAuth();
  const { t, i18n } = useTranslation();
  const [registerField, setRegisterField] = useState<FormData>({});
  const { register, setValue, handleSubmit, errors, reset, watch } = useForm<
    FormData
  >({
    defaultValues: {
      order: 1,
      name: '',
      facebook: '',
      linkedin: '',
      twitter: '',
      instagram: '',
      crm: '',
      profession: {},
      description: {},
    },
  });
  const history = useHistory();
  const { addToast } = useToast();
  const [speakers, setSpeakers] = useState<any[]>([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [resources, setResources] = useState<any>({});
  const [checkedList, setCheckedList] = useState<any[]>([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  const onChange = (checkedList: any) => {
    setCheckedList(checkedList);
    setIndeterminate(
      !!checkedList.length && checkedList.length < resources.list?.length,
    );
    setCheckAll(checkedList.length === resources.list?.length);
  };

  const onCheckAllChange = (e: any) => {
    setCheckedList(e.target.checked ? resources.list : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const onSubmit = async function ({ description, ...data }: any) {
    try {
      setLoadingSubmit(true);
      // const schema = Yup.object().shape({
      //   email: Yup.string()
      //     .required('E-mail é obrigatório')
      //     .email('Digite um e-mail válido'),
      //   password: Yup.string().min(6, 'Senha deve ter no mínimo 6 digitos'),
      // });

      // await schema.validate(data, {
      //   abortEarly: true,
      // });

      const register = { ...registerField, ...data };
      register.eventId = props.event.id;
      register.picture =
        (await upload(data.picture, props.event.key)) || registerField.picture;

      console.log('onSubmit', { registerField, data, register });

      const result = await api.post('/admin/speaker', register);

      setRegisterField({});
      reset();
      setCheckedList([]);
      setEditorState(RichTextEditor.createValueFromString('', 'html'));

      addToast({
        type: 'success',
        title: t('successfull register'),
        description: t('your info was sent successfully'),
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: t('error on register'),
        description:
          error?.response?.data?.message ||
          error.message ||
          t('an error has ocurred on register, try again.'),
      });
    }

    setLoadingSubmit(false);
  };

  async function upload(inputFile: any, eventKey: string) {
    if (typeof inputFile !== 'string' && inputFile.length) {
      const uploaded: any = await firebase
        .storage()
        .ref(`/companies/${eventKey}/customization/${v4()}`)
        .put(inputFile[0]);
      return (await uploaded.ref.getDownloadURL()) || '';
    }

    return '';
  }

  function edit(entity: any) {
    console.log('edit item', entity);

    setRegisterField(entity);

    for (const key in entity) {
      if (entity.hasOwnProperty(key)) {
        const element = entity[key];
        try {
          if (key != 'resources' && key != 'picture') {
            setValue(key, element);
          }
          //  else if (key == 'description') {
          //   Object.keys(entity).map((lang) =>
          //     setValue(`${key}[${lang}]`, entity[lang]),
          //   );
          // }
        } catch (error) {}
      }
    }

    setEditorState(
      RichTextEditor.createValueFromString(
        entity.description?.[i18n.language] || '',
        'html',
      ),
    );
    setCheckedList(entity.resources?.map((a: any) => a.name) || []);
  }

  async function deleteEntity(entity: any) {
    if (window.confirm(t('are you sure that you want to delete this?'))) {
      const result = await api.delete('/admin/speaker/' + entity.id);
    }
  }

  function getDefaultLang() {
    const _defaultLangs = props.event?.settings?.languages?.filter(
      (v: any) => v.default,
    );

    if (_defaultLangs?.length) return _defaultLangs[0];
  }

  const defaultLang = useMemo(() => {
    const _defaultLangs = props.event?.settings?.languages?.filter(
      (v: any) => v.default,
    );

    if (_defaultLangs?.length) return _defaultLangs[0];
    else return undefined;
  }, [props.event]);

  useEffect(() => {
    reset();
    setRegisterField({});

    if (props.event.id) {
      const unsubscribe = firebase
        .firestore()
        .collection('speaker')
        .where('eventId', '==', props.event.id)
        .onSnapshot((snapshot) => {
          const docs = snapshot.docs.map((m) => m.data());
          const spe = docs.sort(function (a: any, b: any) {
            return a.order - b.order;
          });

          console.log('snapshot speakers', spe);
          setSpeakers(spe);
        });

      return () => {
        unsubscribe();
      };
    }
  }, [props.params.id, props.event.id]);

  useEffect(() => {
    if (!resources.list) {
      const data = firebase
        .firestore()
        .collection('settings')
        .doc('resources')
        .get()
        .then((doc) => {
          setResources(doc.data());
        });
    }
  }, [props.params.id]);

  useEffect(() => {
    if (i18n.language) {
      setEditorState(
        RichTextEditor.createValueFromString(
          registerField.description?.[i18n.language] || '',
          'html',
        ),
      );
    }
  }, [i18n.language]);

  const DescriptionInputs = React.useMemo(() => {
    if (registerField['description'])
      setValue('description', registerField['description']);

    return (
      <>
        {i18n.languages.map((lang) => (
          <input
            key={`description[${lang}]`}
            ref={register()}
            name={`description[${lang}]`}
            type="hidden"
          />
        ))}
      </>
    );
  }, [i18n.languages, registerField]);

  console.log('speaker render', {
    language: i18n.languages,
    description: watch('description'),
    registerField,
  });

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Row>
          <Form.Group as={Col} md="4">
            <Form.Label htmlFor="picture">{t('image')}</Form.Label>
            <div className="custom-file">
              <Form.File
                label={t('upload file')}
                name="picture"
                ref={register}
              />
            </div>
            <a href={registerField.picture} target="_blank">
              <img src={registerField.picture} style={{ height: '50px' }} />
            </a>
          </Form.Group>

          <div className="col-md-8"></div>

          <Form.Group as={Col} md="2">
            <Form.Label htmlFor="order">{t('order')}</Form.Label>
            <Form.Control
              defaultValue={registerField.order || 1}
              type="number"
              name="order"
              placeholder={t('order')}
              autoComplete="off"
              ref={register({ required: true })}
            />
            <>
              {errors[`order`] && (
                <span className="error">{t('order is obligatory')}</span>
              )}
            </>
          </Form.Group>

          <Form.Group as={Col} md="10">
            <Form.Label htmlFor="name">{t('name')}</Form.Label>
            <Form.Control
              type="text"
              name="name"
              autoComplete="off"
              ref={register({ required: true })}
            />
            <>
              {errors[`name`] && (
                <span className="error">{t('name is obligatory')}</span>
              )}
            </>
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label htmlFor="email">E-mail</Form.Label>
            <Form.Control
              type="text"
              name="email"
              autoComplete="off"
              ref={register({})}
            />
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label htmlFor="facebook">Facebook</Form.Label>
            <Form.Control
              type="text"
              name="facebook"
              autoComplete="off"
              ref={register({})}
            />
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label htmlFor="twitter">Twitter</Form.Label>
            <Form.Control
              type="text"
              name="twitter"
              autoComplete="off"
              ref={register({})}
            />
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label htmlFor="instagram">Instagram</Form.Label>
            <Form.Control
              type="text"
              name="instagram"
              autoComplete="off"
              ref={register({})}
            />
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label htmlFor="linkedin">LinkedIn</Form.Label>
            <Form.Control
              type="text"
              name="linkedin"
              autoComplete="off"
              ref={register({})}
            />
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label htmlFor="crm">CRM</Form.Label>
            <Form.Control
              type="text"
              name="crm"
              autoComplete="off"
              ref={register()}
            />
          </Form.Group>

          {i18n.languages.map((lang: string) => {
            const isCurrent = i18n.language == lang;
            const isDefault = defaultLang?.language === lang;
            const error = (errors as any)?.profession?.[lang] || null;

            return (
              <Form.Group
                as={Col}
                md="6"
                style={{ display: isCurrent ? '' : 'none' }}
              >
                <Form.Label htmlFor={`profession[${lang}]`}>
                  {t('profession')}
                </Form.Label>
                <Form.Control
                  type={isCurrent ? 'text' : 'hidden'}
                  defaultValue={(registerField?.profession || {})[lang]}
                  name={`profession[${lang}]`}
                  autoComplete="off"
                  ref={register({ required: isDefault })}
                />
                <>
                  {error && (
                    <span className="error">
                      {t('professional description')}
                    </span>
                  )}
                </>
              </Form.Group>
            );
          })}

          {i18n.languages.map(
            (lang: string) =>
              i18n.language == lang && (
                <Form.Group as={Col} md="12">
                  <Form.Label htmlFor={`description[${lang}]`}>
                    {t('description')}
                  </Form.Label>

                  <RichTextEditor
                    className="custom-richtext"
                    value={editorState}
                    onChange={(value) => {
                      const _value = value.toString('html');

                      setRegisterField((prev) => ({
                        ...prev,
                        description: { ...prev.description, [lang]: _value },
                      }));
                      setEditorState(value);
                    }}
                  />
                  {/* <>
                  {errors?.description?.[i18n.language] && (
                    <span className="error">Descrição é obrigatória</span>
                  )}
                </> */}
                </Form.Group>
              ),
          )}
        </Form.Row>
        <Form.Group
          as={Col}
          sm={12}
          className="mt-3"
          style={{ textAlign: 'right' }}
        >
          <Button loading={loadingSubmit} htmlType="submit">
            {t('save')}
          </Button>
        </Form.Group>
      </Form>

      <Table size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>{t('name')}</th>
            <th>{t('order')}</th>
            <th>{t('update')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {speakers.map((item: any, i: number) => (
            <tr key={i}>
              <th scope="row">{i + 1}</th>
              <td>{item.name}</td>
              <td>{item.order}</td>
              <td>
                <Row>
                  <Col xl={3} md={6}>
                    <DropdownButton title={t('update')}>
                      <Dropdown.Item onClick={() => edit(item)}>
                        {t('edit')}
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => deleteEntity(item)}>
                        {t('delete')}
                      </Dropdown.Item>
                    </DropdownButton>
                  </Col>
                </Row>
              </td>
              <td></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default Speakers;
