import React, { useState, useCallback, useEffect, useMemo } from 'react';
import 'react-day-picker/lib/style.css';
import 'firebase/firestore';
import 'firebase/auth';
import firebase from 'firebase';
import { useForm } from 'react-hook-form';
import { Container } from 'react-bootstrap';

import DashboardMasterPage from '../../masterpages/DashboardMasterPage';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import ChatsManager from '../../components/ChatsManager';
import api from '../../services/api';

const CustomOption = ({
  innerRef,
  innerProps,
}: {
  innerRef: any;
  innerProps: any;
}) => <div ref={innerRef} {...innerProps} />;

const Chats: React.FC = (props) => {
  const [cleanChatIsBusy, setCleanChatIsBusy] = useState(false);
  const [active, setActive] = useState<number>(1);
  const [registerField, setRegisterField] = useState<any>({});
  const { register, setValue, handleSubmit, errors, reset } = useForm<any>({});
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState('');
  const [filters, setFilters] = useState<any>({});
  const [pagination, setPagination] = useState<{ take: number; skip: number }>({
    take: 20,
    skip: 0,
  });
  const [eventValue, setEventValue] = useState<string>();
  const [chat, setChat] = useState<any>();
  const [events, setEvents] = useState<any[]>([]);
  const [channels, setChannels] = useState<any[]>([]);
  const [event, setEvent] = useState<any>({});
  const [channel, setChannel] = useState<any>({});

  const [isBusy, setIsBusy] = useState(false);
  const [channelsIsBusy, setChannelsIsBusy] = useState(false);

  async function loadEvents() {
    try {
      if (events.length && eventValue)
        return events.filter(
          (v) =>
            ((v.key || '') as string)
              .toLowerCase()
              .includes(eventValue?.toLowerCase() || '') ||
            ((v.name || '') as string)
              .toLowerCase()
              .includes(eventValue?.toLowerCase() || ''),
        );

      const _response = await api.get('/admin/event/all');
      setEvents(_response.data);
      return _response.data;
    } catch (error) {
      return [];
    }
  }

  async function loadChannels() {
    try {
      setChannelsIsBusy(true);

      if (events.length && eventValue)
        return events.filter((v) =>
          ((v.name || '') as string)
            .toLowerCase()
            .includes(eventValue?.toLowerCase() || ''),
        );

      const _response = await api.get(`/admin/channel/all/${event.id}`);
      setChannels(_response.data);
      return _response.data;
    } catch (error) {
      return [];
    } finally {
      setChannelsIsBusy(false);
    }
  }

  async function loadChat() {
    try {
      setIsBusy(true);

      const _messagesRef = firebase.firestore().collection('chat');
      const query = _messagesRef
        .where('channelId', '==', channel.id)
        .where('eventId', '==', event.id);
      query
        .get()
        .then((snap) => {
          console.log('get chat messages');
          snap.forEach((doc) => {
            const _chat = doc.data();

            console.log('chat', _chat);

            setChat(_chat);
          });
        })
        .finally(() => {
          setIsBusy(false);
        });
    } catch (error) {
      console.log('Error on get chat messages', error);
    } finally {
    }
  }

  // useEffect(() => {
  //   reset();
  //   setRegisterField({});
  //   loadChats();
  // }, [event.id, channel.id, pagination]);

  useEffect(() => {
    setChannels([]);
    setChannel({});
    setChat({});

    if (event.id) loadChannels();
  }, [event.id]);

  useEffect(() => {
    setChat([]);

    if (channel.id) loadChat();
  }, [channel.id]);

  function handleFilterChange(e: React.ChangeEvent<HTMLInputElement>) {
    const _value = e.currentTarget.value;
    setFilter(_value);
  }

  // function handleFilterClick() {
  //   setActive(1);
  //   setFilters((prev: any) => {
  //     if (!filter) {
  //       const { name, email, ..._filter } = prev;
  //       loadChats(1, _filter);

  //       return _filter;
  //     }

  //     const _filters = {
  //       ...prev,
  //       name: filter,
  //       email: filter,
  //     };

  //     loadChats(1, _filters);

  //     return _filters;
  //   });
  // }

  const changePage = (page: number) => {
    setPagination({ ...pagination, skip: (page - 1) * pagination.take });
    setActive(page);
  };

  async function deleteEntity(entity: any) {
    if (window.confirm('Você tem certeza que deseja deletar isto?')) {
      const result = await api.delete('/admin/participant/' + entity.id);
      // loadChats();
    }
  }

  async function handleCleanChatClick() {
    try {
      if (chat?.messages?.length) {
        setCleanChatIsBusy(true);

        const _response = await api.post(`/admin/chat/clean/${chat.id}`, chat);

        if (_response.status === 201) {
          alert('Chat limpo com sucesso');
          loadChat();
        }
      }
    } catch (error) {
    } finally {
      setCleanChatIsBusy(false);
    }
  }

  function handleInputChange(newValue: string) {
    const inputValue = newValue.replace(/\W/g, '');
    setEventValue(inputValue);
    return inputValue;
  }

  useEffect(() => {
    if (event.id && channel.id) {
      loadChat();
    }
  }, [event.id, channel.id]);

  return (
    <DashboardMasterPage {...props}>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Chats"
              breadcrumbItems={[
                { title: 'Admin', link: '#' },
                { title: 'Chats', link: '#' },
              ]}
            />
            <ChatsManager />
          </Container>
        </div>
      </React.Fragment>
    </DashboardMasterPage>
  );
};

export default Chats;
