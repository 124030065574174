import React, { useState, useCallback, useEffect, useMemo } from 'react';
import 'react-day-picker/lib/style.css';
import 'firebase/firestore';
import 'firebase/auth';
import { useAuth } from '../../../hooks/Auth2';
import api from '../../../services/api';
import * as Yup from 'yup';
import DashboardMasterPage from '../../../masterpages/DashboardMasterPage';
import { Container } from './styles';
import {
  Row,
  Col,
  Card,
  Tabs,
  Tab,
  Table,
  Form,
  InputGroup,
  Dropdown,
  DropdownButton,
} from 'react-bootstrap';
import Datetime from 'react-datetime';
import { SketchPicker } from 'react-color';
import CustomButton from '../../../components/Button';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { useToast } from '../../../hooks/Toast';
import firebase from 'firebase';
import moment from 'moment';
import { v4 } from 'uuid';
import { FiEdit } from 'react-icons/fi';
import { asEnumerable } from 'linq-es2015';
import { useTranslation } from 'react-i18next';
import Util from '../../../utils/util';
import ColorPicker from 'rc-color-picker';
import { Checkbox, Modal, Button } from 'antd';
import InputMask from 'react-input-mask';

const CheckboxGroup = Checkbox.Group;

const Schedules: React.FC<{ channel: any }> = (props: any) => {
  const { user } = useAuth();
  const { t, i18n } = useTranslation();
  const [registerField, setRegisterField] = useState<any>({});
  const { register, setValue, handleSubmit, errors, reset, watch } = useForm<
    any
  >({});
  const history = useHistory();
  const { addToast } = useToast();
  const [schedule, setSchedule] = useState<any>({});
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [speakers, setSpeakers] = useState<any[]>([]);
  const [checkedList, setCheckedList] = useState<any[]>([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  const onChange = (checkedList: any) => {
    setCheckedList(checkedList);
    setIndeterminate(
      !!checkedList.length && checkedList.length < speakers?.length,
    );
    setCheckAll(checkedList.length === speakers?.length);
  };

  const onCheckAllChange = (e: any) => {
    setCheckedList(e.target.checked ? speakers.map((a: any) => a.name) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const onSubmit = async function (data: any) {
    try {
      setLoadingSubmit(true);
      // const schema = Yup.object().shape({
      //   email: Yup.string()
      //     .required('E-mail é obrigatório')
      //     .email('Digite um e-mail válido'),
      //   password: Yup.string().min(6, 'Senha deve ter no mínimo 6 digitos'),
      // });

      // await schema.validate(data, {
      //   abortEarly: true,
      // });
      const doc = schedule;

      if (data.start)
        data.start = parseInt(moment(data.start, 'HH:mm').format('x'));

      if (data.end) data.end = parseInt(moment(data.end, 'HH:mm').format('x'));

      const register = Util.merge(registerField, data);
      register.speakers = asEnumerable(speakers)
        .Where((l: any) => checkedList.indexOf(l.name) > -1)
        .ToArray();
      if (!register.id) {
        register.id = v4();
        doc.programation_language = doc.programation_language || [];
        doc.programation_language.push(register);
      } else {
        for (const key in doc.programation_language) {
          if (
            Object.prototype.hasOwnProperty.call(doc.programation_language, key)
          ) {
            const element = doc.programation_language[key];
            if (element.id == register.id) {
              doc.programation_language[key] = register;
            }
          }
        }
      }

      doc.eventId = props.event.id;
      doc.channelId = props.channel.id;
      // alert(JSON.stringify(data));
      const result = await api.post('/admin/schedule', doc);

      setRegisterField({});
      reset();
      setValue('start', '');
      setValue('end', '');
      setCheckedList([]);

      addToast({
        type: 'success',
        title: 'Cadastro realizado',
        description: 'Sua informação foi enviada com sucesso!',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro no cadastro',
        description:
          error?.response?.data?.message ||
          error.message ||
          'Ocorreu um erro ao fazer cadastro, tente novamente.',
      });
    }

    setLoadingSubmit(false);
  };

  async function upload(inputFile: any, eventKey: string) {
    if (typeof inputFile !== 'string' && inputFile.length) {
      const uploaded: any = await firebase
        .storage()
        .ref(`/companies/${eventKey}/customization/${v4()}`)
        .put(inputFile[0]);
      return (await uploaded.ref.getDownloadURL()) || '';
    }

    return '';
  }

  function edit(entity: any) {
    let { start, end, ..._entity } = entity;

    if (start) _entity.start = moment(start).format('HH:mm');
    if (end) _entity.end = moment(end).format('HH:mm');

    setRegisterField(_entity);

    for (const key in entity) {
      if (entity.hasOwnProperty(key)) {
        const element = entity[key];
        try {
          if (key != 'resources' && key != 'picture') {
            setValue(key, element);
          }

          if (key === 'start' && element)
            setValue(key, moment(element).format('HH:mm'));

          if (key === 'end' && element)
            setValue(key, moment(element).format('HH:mm'));
        } catch (error) {}
      }
    }

    setCheckedList(entity.speakers?.map((a: any) => a.name) || []);
  }

  async function deleteEntity(entity: any) {
    if (window.confirm('Você tem certeza que deseja deletar isto?')) {
      schedule.programation_language = asEnumerable(
        schedule.programation_language || [],
      )
        .Where((c: any) => entity.id != c.id)
        .ToArray();
      const result = await api.post('/admin/schedule', schedule);
    }
  }

  useEffect(() => {
    reset();
    setRegisterField({});

    if (props.channel.id) {
      const unsubscribe = firebase
        .firestore()
        .collection('schedule')
        .doc(props.channel.id)
        .onSnapshot((snapshot) => {
          const doc = snapshot.data() || {};
          setSchedule(doc);
        });

      return () => {
        unsubscribe();
      };
    }
  }, [props.params.id, props.channel.id]);

  useEffect(() => {
    if (props.params.id) {
      const unsubscribe = firebase
        .firestore()
        .collection('speaker')
        .where('eventId', '==', props.params.id)
        .onSnapshot((snapshot) => {
          const docs = snapshot.docs.map((m) => m.data());
          setSpeakers(docs);
        });

      return () => {
        unsubscribe();
      };
    }
  }, [props.params.id]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Row>
          {i18n.languages.map(
            (lang: string) =>
              i18n.language == lang && (
                <Form.Group key={lang} as={Col} md="6">
                  <Form.Label htmlFor={`title[${lang}]`}>
                    Título da Programação
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name={`title[${lang}]`}
                    autoComplete="off"
                    defaultValue={(registerField?.title || {})[lang]}
                    ref={register({ required: true })}
                  />
                  <>
                    {errors[`title`] && (
                      <span className="error">Título é obrigatório</span>
                    )}
                  </>
                </Form.Group>
              ),
          )}

          <Form.Group as={Col} md="2">
            <Form.Label htmlFor="order">Ordem</Form.Label>
            <Form.Control
              defaultValue={registerField.order || 1}
              type="number"
              name="order"
              autoComplete="off"
              ref={register({ required: true })}
            />
            <>
              {errors[`order`] && (
                <span className="error">Ordem é obrigatória</span>
              )}
            </>
          </Form.Group>

          {i18n.languages.map(
            (lang: string) =>
              i18n.language == lang && (
                <Form.Group key={lang} as={Col} md="12">
                  <Form.Label htmlFor={`description[${lang}]`}>
                    Descrição
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name={`description[${lang}]`}
                    autoComplete="off"
                    defaultValue={(registerField?.description || {})[lang]}
                    ref={register({ required: true })}
                  />
                  <>
                    {errors[`description`] && (
                      <span className="error">Descrição é obrigatório</span>
                    )}
                  </>
                </Form.Group>
              ),
          )}

          <Form.Group as={Col} md="2">
            <Form.Label htmlFor="start">Inicio</Form.Label>
            <InputMask
              mask="99:99"
              name="start"
              value={registerField.start}
              onChange={(e) => {
                setRegisterField({ ...registerField, start: e.target.value });
              }}
            >
              {(inputProps: any) => (
                <Form.Control
                  ref={register({ required: false })}
                  type="text"
                  autoComplete="off"
                  value={inputProps.value}
                  name={inputProps.name}
                  {...inputProps}
                />
              )}
            </InputMask>

            {/* <Form.Control
              defaultValue={registerField.date}
              type="text"
              name="start"
              autoComplete="off"
              ref={register({ required: false })}
            /> */}
          </Form.Group>

          <Form.Group as={Col} md="2">
            <Form.Label htmlFor="end">Fim</Form.Label>
            <InputMask
              mask="99:99"
              name="end"
              value={registerField.end}
              onChange={(e) => {
                setRegisterField({ ...registerField, end: e.target.value });
              }}
            >
              {(inputProps: any) => (
                <Form.Control
                  ref={register({ required: false })}
                  type="text"
                  autoComplete="off"
                  value={inputProps.value}
                  name={inputProps.name}
                  {...inputProps}
                />
              )}
            </InputMask>
          </Form.Group>

          <Form.Group as={Col} md="6">
            <Form.Label htmlFor="name">Palestrantes</Form.Label>
            <div className="site-checkbox-all-wrapper">
              <Checkbox
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
              >
                Check all
              </Checkbox>
            </div>
            <br />
            <CheckboxGroup
              options={speakers?.map((a: any) => a.name)}
              value={checkedList}
              onChange={onChange}
            />
          </Form.Group>

          <Form.Group as={Col} md="5">
            <Form.Label htmlFor="status">Status</Form.Label>
            <Form.Control
              name="status"
              as="select"
              value={registerField.status || 'enabled'}
              onChange={(e) => {
                setRegisterField({ ...registerField, status: e.target.value });
              }}
              ref={register({ required: true })}
            >
              <option value="enabled">Enabled</option>
              <option value="disabled">Disabled</option>
            </Form.Control>
            <>
              {errors[`status`] && (
                <span className="error">Status é obrigatória</span>
              )}
            </>
          </Form.Group>
        </Form.Row>
        <Form.Group
          as={Col}
          sm={12}
          className="mt-3"
          style={{ textAlign: 'right' }}
        >
          <Button loading={loadingSubmit} htmlType="submit">
            Salvar
          </Button>
        </Form.Group>
      </Form>

      <Table size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Nome</th>
            <th>Descrição</th>
            <th>Ordem</th>
            <th>Atualizar</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {schedule?.programation_language?.map((item: any, i: number) => (
            <tr key={i}>
              <th scope="row">{i + 1}</th>
              <td>{item.title[i18n.language]}</td>
              <td>{item.description[i18n.language]}</td>
              <td>{item.order}</td>
              <td>
                <Row>
                  <Col xl={3} md={6}>
                    <DropdownButton title="Atualizar">
                      <Dropdown.Item onClick={() => edit(item)}>
                        Editar
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => deleteEntity(item)}>
                        Deletar
                      </Dropdown.Item>
                    </DropdownButton>
                  </Col>
                </Row>
              </td>
              <td></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default Schedules;
