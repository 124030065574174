import React, { ButtonHTMLAttributes } from 'react';

import { Spinner } from 'reactstrap';

import { Conteiner } from './style';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  loadingColor?: string;
};

const Button: React.FC<ButtonProps> = ({
  children,
  loading,
  loadingColor,
  ...rest
}) => {
  return (
    <Conteiner type="button" {...rest}>
      {loading ? <Spinner size="sm" color={loadingColor || ''} /> : children}
    </Conteiner>
  );
};

export default Button;
