import React, { Component, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
  Col,
  Media,
} from 'reactstrap';
import SimpleBar from 'simplebar-react';
import 'firebase/firestore';
import firebase from 'firebase';

//i18b
// import { withNamespaces } from "react-i18next";

//Import images
import avatar4 from '../../../assets/images/users/avatar-4.jpg';
import { useAuth } from '../../../hooks/Auth2';
import { INotification } from '../../../interfaces/INotification';
import moment from 'moment';

class NotificationDropdown extends Component<any> {
  state: any;

  constructor(props: any) {
    super(props);
    this.state = {
      menu: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState: any) => ({
      menu: !prevState.menu,
    }));
  }

  render() {
    const notifications = this.props.notifications as Array<INotification>;

    console.log('render notifications', notifications);

    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block"
        >
          <DropdownToggle
            tag="button"
            className="btn header-item noti-icon waves-effect"
            id="page-header-notifications-dropdown"
          >
            <i className="ri-notification-3-line"></i>
            {!!notifications.length && <span className="noti-dot"></span>}
          </DropdownToggle>
          <DropdownMenu
            right
            className="dropdown-menu-lg p-0"
            aria-labelledby="page-header-notifications-dropdown"
          >
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0"> {this.props.t('Notifications')} </h6>
                </Col>
                {/* <div className="col-auto">
                  <Link to="#" className="small">
                    {' '}
                    {this.props.t('View All')}
                  </Link>
                </div> */}
              </Row>
            </div>
            <SimpleBar style={{ maxHeight: '230px' }}>
              {notifications?.map((notification) => (
                <Link to="#" className="text-reset notification-item">
                  <Media>
                    <div className="avatar-xs mr-3">
                      <span className="avatar-title bg-success rounded-circle font-size-16">
                        <i className="ri-checkbox-circle-line"></i>
                      </span>
                    </div>
                    <Media body>
                      <h6 className="mt-0 mb-1">
                        {this.props.t(notification.title)}
                      </h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">
                          {this.props.t(notification.description)}
                        </p>
                        <p className="mb-0">
                          <i className="mdi mdi-clock-outline"></i>{' '}
                          {moment(notification.createdAt).fromNow()}
                        </p>
                      </div>
                    </Media>
                  </Media>
                </Link>
              ))}
            </SimpleBar>
            {/* <div className="p-2 border-top">
              <Link
                to="#"
                className="btn btn-sm btn-link font-size-14 btn-block text-center"
              >
                <i className="mdi mdi-arrow-right-circle mr-1"></i>
                {this.props.t(' View More')}
              </Link>
            </div> */}
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

const NotificationWrapper: React.FC = (props) => {
  const [notifications, setNotifications] = useState<any[]>([]);
  const { user } = useAuth();

  useEffect(() => {
    let query = !user?.agencyId
      ? firebase
          .firestore()
          .collection('notification')
          .orderBy('createdAt', 'desc')
          .limit(20)
      : firebase
          .firestore()
          .collection('notification')
          .where('agencyId', '==', user.agencyId)
          .where('status', '==', 'new')
          .limit(20);

    const unsubscribe = query.onSnapshot(
      (result) => {
        const docs = result.docs.map((item) => ({
          id: item.id,
          ...item.data(),
        }));
        setNotifications(docs);
      },
      (err) => console.log('notification snapshot err', err),
    );

    return () => {
      unsubscribe?.();
    };
  }, [user]);

  return <NotificationDropdown {...props} notifications={notifications} />;
};

export default withTranslation()(NotificationWrapper);
