import React, { useState, useCallback, useEffect, useMemo } from 'react';
import 'react-day-picker/lib/style.css';
import 'firebase/firestore';
import 'firebase/auth';
import { useAuth } from '../../../hooks/Auth2';
import api from '../../../services/api';
import * as Yup from 'yup';
import DashboardMasterPage from '../../../masterpages/DashboardMasterPage';
import { Row, Col, Card, Form, Container } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { useToast } from '../../../hooks/Toast';
import { Checkbox } from 'antd';
import Button from '../../../components/Button';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { ILanguage } from '../../../interfaces/Languages';
import { v4 } from 'uuid';
import firebase from 'firebase';
import { useTranslation } from 'react-i18next';

const CompanyDetails: React.FC<any> = (props: any) => {
  const { user } = useAuth();
  const [entity, setEntity] = useState<any>({});
  const [previewFlag, setPreviewFlag] = useState<string>('');
  const [isBusy, setIsBusy] = useState(false);
  const { register, setValue, handleSubmit, errors, reset, watch } = useForm<
    ILanguage
  >({
    defaultValues: {
      label: '',
      language: '',
      image: '',
      flag: '',
    },
  });
  const history = useHistory();
  const { addToast } = useToast();
  const {t}= useTranslation();

  async function upload(inputFile: any) {
    if (typeof inputFile !== 'string' && inputFile?.length) {
      const uploaded: any = await firebase
        .storage()
        .ref(`/assets/languages/${v4()}`)
        .put(inputFile[0]);
      return (await uploaded.ref.getDownloadURL()) || '';
    }

    return '';
  }

  const onSubmit = async (data: any) => {
    try {
      setIsBusy(true);
      const language = { ...data };

      if (previewFlag) language.flag = await upload(language.image);

      const result = await api.post('/admin/language', language);

      if (!props.modal && !props?.params?.id && result.data?.id) {
        history.replace(`/language/${result.data?.id}`);
      }

      addToast({
        type: 'success',
        title: t("successfull register"),
        description: t("your info was sent successfully"),
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: t("error on register"),
        description:
          error?.response?.data?.message ||
          error.message ||
          t("an error has ocurred on register, try again."),
      });
    } finally {
      setIsBusy(false);
    }
  };

  useEffect(() => {
    if (props?.params?.id) {
      reset();
      api.get<ILanguage>(`admin/language/${props.params.id}`).then((result) => {
        // setEntity(result.data);
        const language = result.data;
        Object.keys(language).forEach((key) =>
          setValue(key, language[key as keyof ILanguage]),
        );
      });
    }

    return () => {};
  }, [props?.params?.id]);

  function handleVideoCoverChange(e: React.ChangeEvent<HTMLInputElement>) {
    const _files = e.currentTarget.files;

    if (!_files?.length) return;

    const reader = new FileReader();
    reader.readAsDataURL(_files[0]);
    reader.onload = () => setPreviewFlag(reader.result as string);
    reader.onerror = (error) => alert(error);
  }

  console.log('Languages render', {
    params: props.params,
    flag: watch('flag'),
  });

  const content = (
    <Row>
      <Col sm={12} className="btn-page">
        <Card className="card-body">
          <Card.Body>
            <h6>Dados da linguagem</h6>
            <hr />
            <Form onSubmit={handleSubmit(onSubmit)}>
              <input type="hidden" name="id" ref={register()} />

              <Form.Row>
                <Form.Group as={Col} md="3">
                  <Form.Label htmlFor="language">{t("language")}</Form.Label>
                  <Form.Control
                    type="text"
                    name="language"
                    placeholder="ex: pt, es, en"
                    autoComplete="off"
                    ref={register({ required: true })}
                  />
                  <>
                    {errors?.language && (
                      <span className="error">{t("name is obligatory")}</span>
                    )}
                  </>
                </Form.Group>

                <Form.Group as={Col} md="9">
                  <Form.Label htmlFor="name">{t("language name")}</Form.Label>
                  <Form.Control
                    name="label"
                    placeholder={t("language name")}
                    autoComplete="off"
                    ref={register({ required: true })}
                  />
                  <>
                    {errors?.label && (
                      <span className="error">
                        {t("language name is obligatory")}
                      </span>
                    )}
                  </>
                </Form.Group>

                <div className="col-12">
                  <div className="row">
                    <Form.Group className="mb-2" as={Col} md="12">
                      <Form.Label htmlFor="image">{t("flag")}</Form.Label>
                      <div className="custom-file">
                        <Form.File
                          label={t("upload file")}
                          name="image"
                          ref={register()}
                          onChange={handleVideoCoverChange}
                        />
                      </div>
                      <input type="hidden" name="flag" ref={register()} />

                      <>
                        {errors?.image && (
                          <span className="error">{t("flag is obligatory")}</span>
                        )}
                      </>
                    </Form.Group>

                    {(!!previewFlag || !!watch('flag')) && (
                      <div className="col-12 mb-3">
                        <a
                          href={previewFlag || watch('flag')}
                          className="d-block"
                          target="_blank"
                        >
                          <img
                            src={previewFlag || watch('flag')}
                            style={{ height: '200px' }}
                          />
                        </a>
                      </div>
                    )}
                  </div>
                </div>

                <Form.Group as={Col} sm={12} className="mt-3">
                  <Button loading={isBusy} type="submit">
                    {t("save")}
                  </Button>
                </Form.Group>
              </Form.Row>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );

  return (
    <>
      {!props.modal && (
        <DashboardMasterPage {...props}>
          <React.Fragment>
            <div className="page-content">
              <Container fluid>
                <Breadcrumbs
                  title={t("companies")}
                  breadcrumbItems={[
                    { title: 'Admin', link: '#' },
                    { title: 'Dashboard', link: '#' },
                  ]}
                />
                {content}
              </Container>
            </div>
          </React.Fragment>
        </DashboardMasterPage>
      )}

      {props.modal && content}
    </>
  );
};

export default CompanyDetails;
