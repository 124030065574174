import React, { Component, useEffect, useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import firebase from 'firebase';

//i18n
// import i18n from '../../../i18n';
// import { withNamespaces } from 'react-i18next';

// falgs
import usFlag from '../../../assets/images/flags/us.jpg';
import spain from '../../../assets/images/flags/spain.jpg';
import brazil from '../../../assets/images/flags/brazil.png';
import italy from '../../../assets/images/flags/italy.jpg';
import russia from '../../../assets/images/flags/russia.jpg';
import { useTranslation } from 'react-i18next';
import { ILanguage } from '../../../interfaces/Languages';

// const flags: any = {
//   pt: brazil,
//   en: usFlag,
//   es: spain,
// };

const LanguageDropdown: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [languages, setLanguages] = useState<ILanguage[]>([]);
  const [state, setState] = useState({
    menu: false,
    flag: usFlag,
  });

  function toggle() {
    setState((prevState: any) => ({
      ...prevState,
      menu: !prevState.menu,
    }));
  }

  const changeLanguageAction = (language: any) => {
    if (
      window.confirm(
        'Se você mudar o idioma, pode perder as informações ainda não salvas, deseja continuar?',
      )
    ) {
      i18n.changeLanguage(language);
      localStorage.setItem('defaultLanguage', language);
    }
  };

  useEffect(() => {
    firebase
      .firestore()
      .collection('language')
      .get()
      .then((snapshot) => {
        setLanguages(
          snapshot?.docs?.map<ILanguage>(
            (a) => ({ id: a.id, ...a.data() } as ILanguage),
          ) || [],
        );
      });
  }, []);

  let flags: any = {};

  languages.map((lang) => (flags[lang.language] = lang.flag));

  return (
    <React.Fragment>
      {languages.length > 0 && (
        <Dropdown
          isOpen={state.menu}
          toggle={toggle}
          className="d-none d-sm-inline-block"
        >
          <DropdownToggle tag="button" className="btn header-item waves-effect">
            <img
              className=""
              src={flags[i18n.language]}
              alt="Header Language"
              height="16"
            />
            <span className="align-middle">{` ${i18n.language.toUpperCase()}`}</span>
          </DropdownToggle>

          <DropdownMenu right>
            {languages.map((lang) => (
              <DropdownItem
                key={lang.id}
                href=""
                active={i18n.language === lang.language ? true : false}
                onClick={() => changeLanguageAction(lang.language)}
                className=" notify-item"
              >
                <img src={lang.flag} alt="user" className="mr-1" height="12" />{' '}
                <span className="align-middle">
                  {lang.language.toUpperCase()}
                </span>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      )}
    </React.Fragment>
  );
};

export default LanguageDropdown;
