import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownButton, Pagination, Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import api from '../../../services/api';
import Loader from '../../../components/Loader';
import { useAuth } from '../../../hooks/Auth2';
import { useTranslation } from 'react-i18next';
import firebase from 'firebase';

interface IEventsTab {
  type: 'active' | 'inactive';
}

const EventsTab: React.FC<IEventsTab> = ({ type }) => {
  const { user } = useAuth();
  const [isBusy, setIsBusy] = useState(false);
  const [active, setActive] = useState<number>(1);
  const history = useHistory();
  const [list, setList] = useState<any[]>([]);
  const [update, setUpdate] = useState<number>(0);
  const [filter, setFilter] = useState('');
  const [filters, setFilters] = useState<any>({});
  const [pagination, setPagination] = useState<{ take: number; skip: number }>({
    take: 20,
    skip: 0,
  });
  const [count, setCount] = useState<number>(0);
  const { t } = useTranslation();
  const [agencies, setAgencies] = useState<any[]>([]);
  const [companies, setCompanies] = useState<any[]>([]);

  const loadEvents = React.useCallback(() => {
    setIsBusy(true);
    let _filters: any = {};

    _filters = {
      ...filters,
      ...(type === 'active'
        ? {
            $or: [
              { active: { $exists: false } },
              { active: null },
              { active: true },
              { active: 'true' },
            ],
          }
        : { active: false }),
    };

    if (user?.agencyId) _filters.agencyId = user?.agencyId;

    api
      .post('admin/event/list', {
        filter: _filters,
        pagination,
      })
      .then((result) => {
        setList(result.data.result);
        setCount(result.data.count);
      })
      .finally(() => {
        setIsBusy(false);
      });

    firebase
      .firestore()
      .collection('company')
      .get()
      .then((snapshot) => {
        setCompanies(
          snapshot?.docs
            ?.map((a) => a.data())
            ?.filter((c) => !user?.agencyId || c.agencyId === user.agencyId) ||
            [],
        );
      })
      .catch((e) => console.log('não foi possivel carregar as empresas', e));

    api.get('/admin/agency/all').then((r) => setAgencies(r.data));
  }, [pagination, filters]);

  useEffect(() => {
    //fire
    loadEvents();
    return () => {};
  }, [pagination, filters]);

  function edit(entity: any) {
    history.push(`/event/${entity.id}`);
  }

  function deleteEntity(entity: any) {
    if (window.confirm(t('are you sure that you want to delete this?'))) {
      api.delete(`admin/event/${entity.id}`).then((result) => {
        setUpdate(update + 1);
      });
    }
  }

  function handleFilterChange(e: React.ChangeEvent<HTMLInputElement>) {
    const _value = e.currentTarget.value;
    setFilter(_value);
  }

  function handleFilterClick() {
    setFilters((prev: any) => {
      if (!filter) {
        const { name, key, ..._filter } = prev;
        return _filter;
      }

      const _regex = new RegExp(`${filter}`);
      return {
        ...prev,
        name: filter,
        key: filter,
      };
    });
  }

  function handleEnterKeyPressed(e: React.KeyboardEvent<HTMLInputElement>) {
    console.log('key', { key: e.key, code: e.keyCode });
    if (e.key.toLowerCase() === 'enter') {
      handleFilterClick();
    }
  }

  function getClient(entity: any) {
    const result = companies.map((c) => {
      if (c.id === entity.companyId) return c.name;
    });

    return result;
  }
  function getAgency(entity: any) {
    const result = agencies.map((a) => {
      if (a.id === entity.agencyId) return a.name;
      else {
        return '';
      }
    });

    return result;
  }

  const changePage = (page: number) => {
    setPagination({ ...pagination, skip: (page - 1) * pagination.take });
    setActive(page);
  };

  let items = [];
  for (let num = 1; num <= Math.ceil(count / 20); num++) {
    items.push(
      <Pagination.Item
        onClick={(e: any) => changePage(num)}
        key={num}
        active={num === active}
      >
        {num}
      </Pagination.Item>,
    );
  }

  return (
    <>
      <div className="row mb-2">
        <div className="col-12">
          <div className="form-inline">
            <label>{t('filter')}: </label>

            <input
              type="text"
              name="filter"
              placeholder={t('search for event name or key')}
              className="form-control flex-grow-1 ml-2 mr-2"
              value={filter}
              onChange={(e) => handleFilterChange(e)}
              onKeyUp={handleEnterKeyPressed}
            />
            <button className="btn btn-primary m-0" onClick={handleFilterClick}>
              Filtrar
            </button>
          </div>
        </div>
        <div className="col-6 d-flex align-items-end"></div>
      </div>
      <Table striped hover className="table-condensed">
        <thead>
          <tr>
            <th>{t('event')}</th>
            <th>Key</th>
            <th>{t('event data')}</th>
            <th>{t('event hour')}</th>
            <th>{t('client')}</th>
            <th>{t('agency')}</th>
            <th>{t('event url')}</th>
            <th>{t('actions')}</th>
          </tr>
        </thead>
        <tbody>
          {isBusy && (
            <tr>
              <td colSpan={8} className="text-center">
                <Loader />
              </td>
            </tr>
          )}

          {!isBusy &&
            list
              .filter(
                (e) =>
                  !filters?.filter ||
                  e.key.indexOf(filters.filter) >= 0 ||
                  e.name.indexOf(filters.filter) >= 0,
              )
              .map((item: any, i: number) => (
                // (!!item.active && item.active == 'true') &&
                <tr key={i}>
                  <td>
                    {!!item.name
                      ? item.name.toUpperCase()
                      : item.key.toUpperCase()}
                  </td>
                  <td>{item.key}</td>
                  <td>{item.eventdate}</td>
                  <td>{item.eventhour || '-'}</td>
                  <td>{getClient(item)}</td>
                  <td>{getAgency(item)}</td>
                  <td>
                    <a
                      target="_blank"
                      href={`https://${
                        item?.custom_domain
                          ? item.custom_domain
                          : '4yourlive.com'
                      }/${item?.key}`}
                    >
                      Link
                    </a>
                  </td>
                  <td>
                    <DropdownButton title={t('update')}>
                      <Dropdown.Item onClick={() => edit(item)}>
                        {t('edit')}
                      </Dropdown.Item>
                      {/* <Dropdown.Item onClick={() => deleteEntity(item)}>
                        Deletar
                      </Dropdown.Item> */}
                    </DropdownButton>
                  </td>
                </tr>
              ))}
        </tbody>
      </Table>
      <Pagination size="sm">{items}</Pagination>
    </>
  );
};

export default EventsTab;
