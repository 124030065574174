import styled, { keyframes, css } from 'styled-components';
import { shade } from 'polished';
//import mainBackground from '../../assets/images/wxYi9eq.png';

interface ContainerPros {
  customization: any;
}

export const Container = styled.div<ContainerPros>`

#image-bg {

  ${(props) => css`
  background: ${props.customization.banner};
  `}
}

.form-s {
  display: flex;
  align-items: center;
  justify-content: center;
}

`;
