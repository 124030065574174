import React from 'react';
import { useAuth } from '../../../hooks/Auth2';

import DashboardMasterPage from '../../../masterpages/DashboardMasterPage';
import {
  Row,
  Col,
  Card,
  Tabs,
  Tab,
  Container,
  Nav,
  Table,
  DropdownButton,
  Dropdown,
  Pagination,
  TabPane,
} from 'react-bootstrap';

import EventsTab from './../EventsTab';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { useTranslation } from 'react-i18next';

const Rsvp: React.FC = (props: any) => {
  const { user } = useAuth();
  const { t } = useTranslation();

  return (
    <DashboardMasterPage {...props}>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={t("events")}
              breadcrumbItems={[
                { title: 'Admin', link: '#' },
                { title: 'Dashboard', link: '#' },
              ]}
            />
            <Row>
              <Col sm={12} className="btn-page">
                <Card className="user-profile-list card-body">
                  <Card.Body>
                    <Tabs defaultActiveKey="active" className="mb-3">
                      <Tab eventKey="active" title={`Eventos`}>
                        <TabPane>
                          <EventsTab type="active" />
                        </TabPane>
                      </Tab>
                    </Tabs>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </DashboardMasterPage>
  );
};

export default Rsvp;
