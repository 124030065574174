import styled, { css } from 'styled-components';
import BsContainer from 'react-bootstrap/Container';

export const Container = styled(BsContainer)`
  .event-data-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h5 {
      margin-bottom: 0;
    }

    .publish-production {
      button {
        margin: 0;
      }
    }
  }
`;
