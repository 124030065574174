import React, { useState, useCallback, useEffect, useMemo } from 'react';
import 'react-day-picker/lib/style.css';
import 'firebase/firestore';
import 'firebase/auth';
import firebase from 'firebase';
import { CSVLink, CSVDownload } from 'react-csv';

import { useAuth } from '../../hooks/Auth2';
import api from '../../services/api';
import DashboardMasterPage from '../../masterpages/DashboardMasterPage';
import {
  Row,
  Col,
  Card,
  Tabs,
  Tab,
  Container,
  Nav,
  Table,
  DropdownButton,
  Dropdown,
  Pagination,
  TabPane,
} from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';

import Breadcrumbs from '../../components/Common/Breadcrumb';
import { useForm } from 'react-hook-form';
import { useToast } from '../../hooks/Toast';
import CustomPagination from '../../components/CustomPagination';
import Loader from '../../components/Loader';
import { Spinner } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const TimelinesManager: React.FC<{ event?: any; channel?: any }> = (props) => {
  const [cleanTimelineIsBusy, setCleanTimelineIsBusy] = useState(false);
  const [active, setActive] = useState<number>(1);
  const [registerField, setRegisterField] = useState<any>({});
  const { register, setValue, handleSubmit, errors, reset } = useForm<any>({});
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState('');
  const [filters, setFilters] = useState<any>({});
  const [pagination, setPagination] = useState<{ take: number; skip: number }>({
    take: 20,
    skip: 0,
  });
  const [eventValue, setEventValue] = useState<string>();
  const [timeline, setTimeline] = useState<any[]>([]);
  const [events, setEvents] = useState<any[]>([]);
  const [channels, setChannels] = useState<any[]>([]);
  const [event, setEvent] = useState<any>({});
  const [channel, setChannel] = useState<any>({});

  const [isBusy, setIsBusy] = useState(false);
  const [channelsIsBusy, setChannelsIsBusy] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const { t } = useTranslation();

  async function loadEvents() {
    try {
      if (events.length && eventValue)
        return events.filter(
          (v) =>
            ((v.key || '') as string)
              .toLowerCase()
              .includes(eventValue?.toLowerCase() || '') ||
            ((v.name || '') as string)
              .toLowerCase()
              .includes(eventValue?.toLowerCase() || ''),
        );

      const _response = await api.get('/admin/event/all');
      setEvents(_response.data);
      return _response.data;
    } catch (error) {
      return [];
    }
  }

  async function loadChannels() {
    try {
      setChannelsIsBusy(true);

      if (events.length && eventValue)
        return events.filter((v) =>
          ((v.name || '') as string)
            .toLowerCase()
            .includes(eventValue?.toLowerCase() || ''),
        );

      const _response = await api.get(`/admin/channel/all/${event.id}`);
      setChannels(_response.data);
      return _response.data;
    } catch (error) {
      return [];
    } finally {
      setChannelsIsBusy(false);
    }
  }

  async function loadTimeline() {
    try {
      setIsEmpty(false);
      setIsBusy(true);

      const _channel = props.channel || channel;
      const _event = props.event || event;

      const _messagesRef = firebase.firestore().collection('timeline');
      const query = _messagesRef;

      query
        .where('channelId', '==', _channel.id)
        .where('eventId', '==', _event.id)
        .get()
        .then((snap) => {
          try {
            const docs = snap.docs;
            const _timelines: any = docs.map((item) => ({
              id: item.id,
              ...item.data(),
            }));

            if (!_timelines || _timelines.length === 0) {
              setIsEmpty(true);
            }

            setTimeline(_timelines);
          } catch (error) {
            console.log('Error on load chat messages');
          }
        })
        .finally(() => {
          setIsBusy(false);
        });
    } catch (error) {
      console.log('Error on get chat messages', error);
    } finally {
    }
  }

  useEffect(() => {
    setChannels([]);
    setChannel({});
    setTimeline([]);

    if (event.id) loadChannels();
  }, [event.id]);

  useEffect(() => {
    setTimeline([]);

    if (channel.id) loadTimeline();
  }, [channel.id]);

  const changePage = (page: number) => {
    setPagination({ ...pagination, skip: (page - 1) * pagination.take });
    setActive(page);
  };

  async function handleCleanTimelineClick() {
    if (window.confirm(t("are you sure that you want to clear the timeline?"))) {
      try {
        if (timeline?.length) {
          setCleanTimelineIsBusy(true);

          const _response = await api.post(
            `/admin/timeline/clean/${event.id}/${channel.id}`,
            timeline,
          );

          if (_response.status === 201) {
            alert('timeline cleared successfully');
            loadTimeline();
          }
        }
      } catch (error) {
      } finally {
        setCleanTimelineIsBusy(false);
      }
    }
  }

  function handleInputChange(newValue: string) {
    const inputValue = newValue.replace(/\W/g, '');
    setEventValue(inputValue)
    return inputValue;
  }

  async function deleteEntity(entity: any) {
    try{
      if (window.confirm(t("are you sure that you want to delete this?"))) {
        await api.delete('/admin/timeline/' + entity.id);
     }
    } catch (error) {
      loadTimeline();
    }
  }

  useEffect(() => {
    if (event.id && channel.id) {
      loadTimeline();
    }
  }, [event.id, channel.id]);

  useEffect(() => {
    if (props.event?.id && props.channel?.id) {
      loadTimeline();
    }
  }, [props.event?.id, props.channel?.id]);

  return (
    <Row>
      {/* <Col sm={12} className="btn-page">
      <Card className="">
        <Card.Body>
          </Card.Body>
      </Card>
    </Col> */}

      <Col sm={12} className="btn-page">
        <Card className="">
          <Card.Body style={{ minHeight: '50vh' }}>
            {!props.event?.id && !props.channel?.id && (
              <div className="row">
                <div className="col-12">
                  <div className="form-row">
                    <div className="col-6">
                      <label>{t("event")}</label>
                      <AsyncSelect
                        cacheOptions
                        loadOptions={loadEvents}
                        placeholder={t("select an event")}
                        defaultOptions
                        // components={{ Option: CustomOption }}
                        getOptionValue={(option) => option['id']}
                        getOptionLabel={(option) =>
                          `${option['key']} - ${option['name']}`
                        }
                        onInputChange={handleInputChange}
                        onChange={(e) => setEvent(e)}
                      />
                      {/* <input
                      type="text"
                      name="filter"
                      placeholder="Pesquise pelo Nome ou Key do evento"
                      className="form-control flex-grow-1 ml-2 mr-2"
                      value={filter}
                      onChange={(e) => handleFilterChange(e)}
                      onKeyUp={handleEnterKeyPressed}
                    /> */}
                    </div>
                    <div className="col-6">
                      <label>{t("room")}</label>

                      <Select
                        defaultOptions
                        options={channels}
                        placeholder={t("select a channel")}
                        isLoading={channelsIsBusy}
                        getOptionValue={(option) => option['id']}
                        getOptionLabel={(option) =>
                          `${option['key']} - ${option['name']}`
                        }
                        value={channel.id ? channel : null}
                        onInputChange={handleInputChange}
                        onChange={(e) => setChannel(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {((event.id && channel.id) ||
              (props.event?.id && props.channel?.id)) && (
              <>
                <div className="row mb-2 mt-3">
                  <div className="col-12">
                    <div className="form-inline">
                      {/* <label>Filtro: </label>

                    <input
                      type="text"
                      name="filter"
                      placeholder="Pesquise pelo Nome ou Key do evento"
                      className="form-control flex-grow-1 ml-2 mr-2"
                      value={filter}
                      onChange={(e) => handleFilterChange(e)}
                      onKeyUp={handleEnterKeyPressed}
                    /> */}
                      <button
                        className="btn btn-primary m-0"
                        onClick={handleCleanTimelineClick}
                      >
                          {t("clear Timeline")}
                        {cleanTimelineIsBusy && <Spinner size="sm" />}
                      </button>
                      {!!timeline?.length && (
                        <CSVLink
                          className="btn btn-primary m-0 ml-3"
                          data={timeline}
                        >
                          {t("export Timeline")}
                        </CSVLink>
                      )}
                    </div>
                  </div>
                  <div className="col-6 d-flex align-items-end"></div>
                </div>
                <Table
                  striped
                  hover
                  className="table-condensed"
                  style={{ tableLayout: 'fixed' }}
                >
                  <thead>
                    <tr>
                      <th>{t("image")}</th>
                      <th>{t("participant")}</th>
                      <th
                        style={{
                          maxWidth: '70%',
                        }}
                      >
                        {t("text")}
                      </th>
                      <th>{t("date/hour")}</th>
                      <th>{t("actions")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isBusy && (
                      <tr>
                        <td colSpan={4} className="text-center">
                          <Loader />
                        </td>
                      </tr>
                    )}

                    {isEmpty && !isBusy && (
                      <tr>
                        <td colSpan={4} className="text-center">
                          <h6>{t("cleaned Timeline")}</h6>
                        </td>
                      </tr>
                    )}

                    {!isBusy &&
                      timeline &&
                      timeline.map((item: any, i: number) => (
                        // (!!item.active && item.active == 'true') &&
                        <tr key={i}>
                          <td>
                            <img className="w-100" src={item.imgUrl} />
                          </td>
                          <td>
                            {!!item?.user?.name &&
                              item?.user?.name?.toUpperCase()}
                          </td>
                          <td
                            style={{
                              maxWidth: '70%',
                              wordWrap: 'break-word',
                            }}
                          >
                            {item.text}
                          </td>
                          <td>
                            {moment(item.createdAt).format('DD/MM/YYYY HH:mm')}
                          </td>
                          <td>
                          <DropdownButton title={t("update")}>
                            <Dropdown.Item
                              onClick={() => deleteEntity(item)}
                            >
                              {t("delete")}
                            </Dropdown.Item>
                          </DropdownButton>
                        </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                {!isBusy && count > 0 && (
                  <CustomPagination
                    page={active}
                    count={count}
                    take={pagination.take}
                    onPageChange={(page) => changePage(page)}
                    size="sm"
                  />
                )}
              </>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default TimelinesManager;
