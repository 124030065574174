let config: any = {};

config = {
  apiKey: 'AIzaSyDfJSXyth8DU3XuyXIBFoCMFNbk-bVDr18',
  authDomain: 'rstcom20.firebaseapp.com',
  databaseURL: 'https://rstcom20.firebaseio.com',
  projectId: 'rstcom20',
  storageBucket: 'rstcom20.appspot.com',
  messagingSenderId: '686169769841',
  appId: '1:686169769841:web:a9db88906c56cc3a4c18d0',
  measurementId: 'G-4NS0ZXCWXX',
};

if (
  document.location.href.indexOf('admin-homologacao.rstcom.stream') >= 0 ||
  document.location.href.indexOf('localhost') >= 0
)
  config = {
    apiKey: 'AIzaSyBiOIdNAKIv3Wv7EmkPs4v4SJ1UL_3RI_Q',
    authDomain: 'rstcom-sandbox.firebaseapp.com',
    databaseURL: 'https://rstcom-sandbox.firebaseio.com',
    projectId: 'rstcom-sandbox',
    storageBucket: 'rstcom-sandbox.appspot.com',
    messagingSenderId: '742326776675',
    appId: '1:742326776675:web:3d6589d2523509bd94d956',
  };

export default config;
