import React from 'react';
import { Provider } from 'react-redux';

import { AuthProvider } from './Auth2';
import { ToastProvider } from './Toast';
import { I18Provider } from './i18';
import { EventProvider } from './EventContext';
import { EmitterProvider } from './Emitter';
import { FirebaseProvider } from './Firebase';
import store from '../store';

const AppProvider: React.FC = ({ children, ...rest }) => (
  <Provider store={store}>
    <EmitterProvider>
      <FirebaseProvider>
        <I18Provider>
          <AuthProvider>
            <ToastProvider>
              <EventProvider {...rest}>{children}</EventProvider>
            </ToastProvider>
          </AuthProvider>
        </I18Provider>
      </FirebaseProvider>
    </EmitterProvider>
  </Provider>
);

export default AppProvider;
