import React, { useState, useEffect, useRef } from 'react';
import 'react-day-picker/lib/style.css';
import 'firebase/firestore';
import 'firebase/auth';

import {
  Row,
  Col,
  Table,
  Form,
  Dropdown,
  DropdownButton,
  Pagination,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import firebase from 'firebase';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';

import { useToast } from '../../../hooks/Toast';
import Button from '../../../components/Button';
import CustomPagination from '../../../components/CustomPagination';
import api from '../../../services/api';
import Loader from '../../../components/Loader';
import { useAuth } from '../../../hooks/Auth';

type FieldsItemType = {
  name: string;
};
interface Entity {
  id: string;
  name: string;
  email: string;
  fields: {
    avatar: string;
  }
}

const ImagePaticipant: React.FC<{ event: any }> = (props: any) => {


  const { t } = useTranslation();
  const { addToast } = useToast();


  const onChangeImage = (e: any) => {
    e.preventDefault()
    const a: any = e.target.files;
    if (String(a[0].name).length !== 0) {
      var reader = new FileReader();
      var file = a[0];
      reader.onload = function (upload: any) {
        setEntity({
          ...entity, fields: {
            ...entity.fields,
            avatar: upload.target.result
          }
        })
        console.log(upload.target.result)
      };
      reader.readAsDataURL(file);
    }
  }

  const [entity, setEntity] = useState<Entity>({} as Entity)
  const [data, setData] = useState<Entity[]>([]);
  const [isBusy, setIsBusy] = useState<boolean>(true);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);


  const load = () => {
    api.get(`/admin/participant/imagesParticipant/${props.params.id}`).then(r => {
      setData(r.data)
    }).catch(error => {
      addToast({
        type: 'error',
        title: 'Erro no cadastro',
        description:
          error?.response?.data?.message ||
          error.message ||
          'Ocorreu um erro ao fazer cadastro, tente novamente.',
      });
    }).finally(() => {
      if (isBusy) {
        setIsBusy(false)
      }
    })
  }

  useEffect(() => {
    load()
  }, [])









  const deleteImage = (id: string) => {
    api.put(`/admin/participant/imagesParticipant/delete`, { id: id })
      .then(() => {
        load()
      })
      .catch(error => {
        addToast({
          type: 'error',
          title: 'Erro',
          description:
            error?.response?.data?.message ||
            error.message ||
            t("an error has ocurred on register, try again."),
        });
      }).finally(() => {

      })

  }


  const onSubmit = async function () {
    setLoadingSubmit(true)
    api.put('/admin/participant/imagesParticipant', entity).then(r => {
      load()
      setEntity({
        id: '',
        name: '',
        email: '',
        fields: { avatar: '' }
      })
    }).catch(error => {
      addToast({
        type: 'error',
        title: 'Erro no cadastro',
        description:
          error?.response?.data?.message ||
          error.message ||
          t("an error has ocurred on register, try again."),
      });
    }).finally(() => {
      setLoadingSubmit(false)

    })
  };

  return (
    <>
      {entity?.id && <Form onSubmit={(e) => {
        e.preventDefault()
        onSubmit()
      }}>
        <Form.Row>
          <Form.Group as={Col} md="6">
            <Form.Label htmlFor="key">{t("name")}</Form.Label>
            <Form.Control
              type="text"
              value={entity?.name}
              disabled
              name="name"
              autoComplete="off"
            />
          </Form.Group>

          <Form.Group as={Col} md="6">
            <Form.Label htmlFor="email">{t("email")}</Form.Label>
            <Form.Control
              type="text"
              value={entity?.email}
              disabled
              name="email"
              autoComplete="off"
            />
          </Form.Group>


          <Form.Group as={Col} md="6">
            <Form.Label htmlFor={'ima'}>{t("participant image")}</Form.Label>
            <Form.File
              type="file"
              name={`ima`}
              custom
              label={entity?.fields?.avatar ? t("it was made an image upload") : t("select an image to upload")}
            onChange={(e: any) => onChangeImage(e)}
            autoComplete="off"

                        />
            <div style={{ padding: 10 }} />

          </Form.Group>
          <Form.Group className='text-center column' as={Col} md="6">
            {entity?.fields?.avatar && <img src={entity?.fields?.avatar} className="img-rounded" alt="Cinque Terre" width="304" />}
          </Form.Group>


          <Form.Group
            as={Col}
            sm={12}
            className="mt-3"
            style={{ textAlign: 'right' }}>
            <Button loading={loadingSubmit} type="submit">
              {t("save")}
            </Button>
          </Form.Group>


        </Form.Row>
      </Form>}


      <Table size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>{t("name")}</th>
            <th>{t("email")}</th>
            <th>{t("image")}</th>
            <th>{t("update")}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {isBusy && (
            <tr>
              <td colSpan={5} className="text-center">
                <Loader />
              </td>
            </tr>
          )}
          {!isBusy &&
            data.map((item: Entity, i: number) => (
              <tr key={i}>
                <th scope="row">{i + 1}</th>
                <td>{item.name}</td>
                <td>{item.email}</td>
                <td><img style={{ maxHeight: 150 }} src={item.fields.avatar} /></td>
                <td>
                  <Row>
                    <Col xl={3} md={6}>
                      <DropdownButton title={t("update")}>
                        <Dropdown.Item onClick={() => setEntity(item)}>
                          {t("edit imagem")}
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => deleteImage(String(item.id))}>
                          {t("delete imagem")}
                        </Dropdown.Item>
                      </DropdownButton>
                    </Col>
                  </Row>
                </td>
                <td></td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
};

export default ImagePaticipant;
