import React, { useState, useCallback, useEffect, useMemo } from 'react';
import 'react-day-picker/lib/style.css';
import 'firebase/firestore';
import 'firebase/auth';
import { useAuth } from '../../../hooks/Auth2';
import api from '../../../services/api';
import * as Yup from 'yup';
import DashboardMasterPage from '../../../masterpages/DashboardMasterPage';
import { Row, Col, Card, Form, Container, Alert, Table } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { useToast } from '../../../hooks/Toast';
import { Checkbox } from 'antd';
import Button from '../../../components/Button';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import RichTextEditor from 'react-rte';
import ReactInputMask from 'react-input-mask';
import Papa from 'papaparse';
import { Progress } from 'reactstrap';
import { read, utils } from 'xlsx';
import { useTranslation } from 'react-i18next';

type FormData = {
  subject: string;
  html: string;
  schedule_date: string;
  schedule_hour: string;
  recipients: string;
};

const UserDetails: React.FC<any> = (props: any) => {
  const { user } = useAuth();
  const [editorState, setEditorState] = React.useState(() =>
    RichTextEditor.createEmptyValue(),
  );
  const [entity, setEntity] = useState<any>({});
  const [agencies, setAgencies] = useState<any[]>([]);
  const [recipients, setRecipients] = useState<any[]>();
  const { register, setValue, handleSubmit, errors, reset, watch } = useForm<
    FormData
  >({ defaultValues: {} });
  const history = useHistory();
  const { addToast } = useToast();
  const {t}= useTranslation();

  const onSubmit = handleSubmit(async (data: any) => {
    try {
      const _data = { ...data };
      _data.recipients = JSON.parse(data.recipients);

      if (entity?.id) _data.id = entity.id;
      if (!entity?.id) _data.status = 'pending';

      const result = await api.post('/admin/email', {
        ..._data,
      });

      if (!props.modal && !props?.params?.id && result.data?.id) {
        history.replace(`/email/${result.data?.id}`);
      }

      addToast({
        type: 'success',
        title: t("successfull register"),
        description: t("your info was sent successfully"),
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: t("error on register"),
        description:
          error?.response?.data?.message ||
          error.message ||
          t("an error has ocurred on register, try again."),
      });
    }
  });

  function getJsonFromCSV(value: string) {
    const items = Papa.parse(value, {});

    return items.data;
  }

  function getJsonFromXLSX(value: Uint8Array) {
    const workbook = read(value, { type: 'array' });

    console.log('XLSX', { names: workbook.SheetNames });

    const items = utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);

    return items;
  }

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    console.log('File change', { target: e.target, current: e.currentTarget });
    // Assuming only image
    var file = e?.target?.files?.[0];

    if (!file) return;

    var reader = new FileReader();
    reader.onload = () => {
      console.log('url', { result: reader.result, type: file?.type }); // Would see a path?

      if (reader.result) {
        let json: any[] = [];

        if (file?.type && file.type.indexOf('csv') >= 0)
          json = getJsonFromCSV(reader.result.toString());

        if (
          file?.type &&
          (file.type.indexOf(
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          ) >= 0 ||
            file.type.indexOf('xls') >= 0 ||
            file.type.indexOf('xlsx') >= 0)
        )
          json = getJsonFromXLSX(new Uint8Array(reader.result as ArrayBuffer));

        console.log('onChange', { json });
        setValue('recipients', JSON.stringify(json));
        // setRecipients(json);
      }
    };

    if (file?.type && file.type.indexOf('csv') >= 0) reader.readAsDataURL(file);
    else reader.readAsArrayBuffer(file);

    // TODO: concat files for setState
  }

  useEffect(() => {
    reset();

    if (props?.params?.id) {
      api.get(`admin/email/details/${props.params.id}`).then((result: any) => {
        setEntity(result.data);
      });
    } else {
      api.get(`admin/email/default`).then((result: any) => {
        setEntity(result.data);
      });
    }

    return () => {};
  }, [props?.params?.id]);

  useEffect(() => {
    if (entity?.id) {
      console.log('useEffect', { entity });
      Object.keys(entity).map((key) => {
        if (key === 'recipients') setValue(key, JSON.stringify(entity[key]));
        // else if (key === 'schedule_date')
        //   setValue(key, Number(entity[key]?.replace(/[/]/g, '')));
        // else if (key === 'schedule_hour')
        //   setValue(key, entity[key]?.replace(/[:]/g, ''));
        else setValue(key, entity[key]);
      });
    }
  }, [entity]);

  return (
    <>
      {!props.modal && (
        <DashboardMasterPage {...props}>
          <React.Fragment>
            <div className="page-content">
              <Container fluid>
                <Breadcrumbs
                  title="Novo E-mail"
                  breadcrumbItems={[
                    { title: 'Admin', link: '#' },
                    { title: 'E-mails', link: '#' },
                  ]}
                />
                <Row>
                  <Col sm={12} className="btn-page">
                    <Card className="card-body">
                      <Card.Body>
                        <h6>{t("email datas")}</h6>
                        <hr />
                        <Form onSubmit={onSubmit}>
                          <Form.Row>
                            <Form.Group as={Col} md="12">
                              <Form.Label htmlFor="subject">{t("subject")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="subject"
                                id="subject"
                                autoComplete="off"
                                ref={register({ required: true })}
                              />
                              <>
                                {errors[`subject`] && (
                                  <span className="error">
                                    {t("subject is obligatory")}
                                  </span>
                                )}
                              </>
                            </Form.Group>

                            <Form.Group as={Col} md="2">
                              <Form.Label htmlFor="schedule_date">
                                {t("scheduling date")}
                              </Form.Label>
                              <ReactInputMask
                                mask="99/99/9999"
                                maskChar="_"
                                name="schedule_date"
                              >
                                {(inputProps: any) => (
                                  <Form.Control
                                    ref={register({ required: false })}
                                    type="text"
                                    autoComplete="off"
                                    value={inputProps.value}
                                    name={inputProps.name}
                                    {...inputProps}
                                  />
                                )}
                              </ReactInputMask>
                              <>
                                {errors[`schedule_date`] && (
                                  <span className="error">
                                    {t("date is obligatory")}
                                  </span>
                                )}
                              </>
                            </Form.Group>

                            <Form.Group as={Col} md="2">
                              <Form.Label htmlFor="schedule_hour">
                                {t("scheduling hour")}
                              </Form.Label>
                              <ReactInputMask
                                mask="99:99"
                                maskChar="_"
                                name="schedule_hour"
                              >
                                {(inputProps: any) => (
                                  <Form.Control
                                    ref={register({ required: false })}
                                    type="text"
                                    autoComplete="off"
                                    value={inputProps.value}
                                    name={inputProps.name}
                                    {...inputProps}
                                  />
                                )}
                              </ReactInputMask>
                              <>
                                {errors[`schedule_hour`] && (
                                  <span className="error">
                                    {t("hour is obligatory")}
                                  </span>
                                )}
                              </>
                            </Form.Group>

                            <div className="col-12">
                              <Form.Row>
                                <Form.Group as={Col} md="6">
                                  <Form.Label htmlFor={`html`}>
                                    Template
                                  </Form.Label>

                                  {/* <input
                                    ref={register}
                                    type="hidden"
                                    name="html"
                                  /> */}
                                  <textarea
                                    className="form-control"
                                    ref={register}
                                    name="html"
                                    rows={10}
                                    // value={editorState}
                                    onChange={(e) => {
                                      // const _value = value.toString('html');

                                      setValue('html', e.target.value);
                                      // setEditorState(value);
                                    }}
                                  />
                                  {/* <RichTextEditor
                                    className="custom-richtext"
                                    value={editorState}
                                    onChange={(value) => {
                                      const _value = value.toString('html');

                                      setValue('html', _value);
                                      setEditorState(value);
                                    }}
                                  /> */}
                                  <>
                                    {errors[`html`] && (
                                      <span className="error">
                                        {t("template is obligatory")}
                                        Template é obrigatório
                                      </span>
                                    )}
                                  </>
                                </Form.Group>

                                <Form.Group as={Col} md="6">
                                  <Form.Label htmlFor={`html`}>
                                    {t("receivers")}
                                  </Form.Label>

                                  <input
                                    ref={register({ required: true })}
                                    name="recipients"
                                    type="hidden"
                                  />
                                  <Form.Control
                                    type="file"
                                    name="file"
                                    id="file"
                                    onChange={onChange}
                                  />
                                  <>
                                    {errors[`recipients`] && (
                                      <span className="error">
                                        {t("receivers is obligatory")}
                                      </span>
                                    )}
                                  </>

                                  <Table
                                    striped
                                    hover
                                    id="data-table-zero"
                                    className="table-condensed"
                                  >
                                    <thead>
                                      <tr>
                                        <th>{t("name")}</th>
                                        <th>E-mail</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {(watch('recipients')
                                        ? JSON.parse(watch('recipients'))
                                        : []
                                      )?.map((item: any) => (
                                        <tr>
                                          <td>{item?.name}</td>
                                          <td>{item?.email}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>
                                </Form.Group>
                              </Form.Row>
                            </div>

                            <Form.Group as={Col} sm={12} className="mt-3">
                              <Button type="submit">{t("save")}</Button>
                            </Form.Group>
                          </Form.Row>
                        </Form>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          </React.Fragment>
        </DashboardMasterPage>
      )}

      {/* {props.modal && content} */}
    </>
  );
};

export default UserDetails;
