import React, { useEffect, useState } from 'react';
import { Pagination, PaginationProps } from 'react-bootstrap';
import { PaginationLink } from 'reactstrap';

// import { Container } from './styles';

interface ICustomPagination extends PaginationProps {
  count: number;
  take: number;
  page: number;
  onPageChange?(newPage: number): void;
}

const CustomPagination: React.FC<ICustomPagination> = ({
  page,
  count,
  take,
  onPageChange,
}) => {
  const maxItems = 11;
  const middleItems = Math.ceil(maxItems / 2);
  const pages = Math.ceil(count / take);
  const [items, setItems] = useState<any[]>([]);

  function loadPaginationItems() {
    const _itens = [];

    let num = 1;
    let _maxItems = pages > maxItems ? maxItems : pages;

    window.console.log('pagination', {
      num,
      _maxItems,
      page,
      pages,
      maxItems,
    });

    if (
      page >= middleItems &&
      page + middleItems <= pages &&
      pages > maxItems
    ) {
      num = page - middleItems + 1;
      _maxItems = page + middleItems - 1;
    } else if (page + middleItems > pages && pages > maxItems) {
      num = pages - maxItems + 1;
      _maxItems = num + maxItems - 1;
    }

    for (num; num <= _maxItems; num++) {
      let _page = num;
      const _active = _page === page;
      _itens.push(
        <Pagination.Item
          className={`${_active ? '' : 'd-none d-md-inline-block'}`}
          onClick={(e: any) => {
            onPageChange?.(_page);
          }}
          key={_page}
          active={_page === page}
        >
          {_page}
        </Pagination.Item>,
      );
    }

    setItems(_itens);
  }

  useEffect(() => {
    console.log('custom pagination', { count, page, take });
    loadPaginationItems();
  }, [count, page, take]);

  return (
    <>
      {`Página ${page} de ${pages}`}
      <Pagination
        style={{
          width: '200px',
        }}
      >
        <PaginationLink
          className={`${pages === 1 ? 'disabled' : ''}`}
          onClick={(e: any) => {
            onPageChange?.(1);
          }}
          key={'first'}
        >
          First
          </PaginationLink>
        <PaginationLink
          className={`d-md-none ${pages === 1 || page === 1 ? 'disabled' : ''}`}
          onClick={(e: any) => {
            onPageChange?.(page - 1);
          }}
          key={'prev'}
        >
          Prev
          </PaginationLink>
        {items}

        <PaginationLink
          className={`d-md-none ${
            pages === 1 || page >= pages ? 'disabled' : ''
          }`}
          onClick={(e: any) => {
            onPageChange?.(page + 1);
          }}
          key={'next'}
        >
          Next
          </PaginationLink>
        <PaginationLink
          className={`${pages === 1 ? 'disabled' : ''}`}
          onClick={(e: any) => {
            onPageChange?.(pages);
          }}
          key={'last'}
        >
          Last
          </PaginationLink>
      </Pagination>
    </>
  );
};

export default CustomPagination;
