import React, { useState, useEffect } from 'react';
import 'react-day-picker/lib/style.css';
import 'firebase/firestore';
import 'firebase/auth';
import api from '../../../services/api';
import {
  Row,
  Col,
  Table,
  Form,
  Dropdown,
  DropdownButton,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useToast } from '../../../hooks/Toast';
import firebase from 'firebase';
import { v4 } from 'uuid';
import { Checkbox, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import RichTextEditor from 'react-rte';

type FormData = {
  order?: number;
  description?: { [lang: string]: string };
  cover?: string;
};

const firestore = firebase.firestore();

const Products: React.FC<{ event: any }> = (props: any) => {
  const [registerField, setRegisterField] = useState<FormData>({});
  const [checkAll, setCheckAll] = useState(false);
  const [indeterminate, setIndeterminate] = useState(true);
  const { t, i18n } = useTranslation();
  const [filters, setFilters] = useState<any>({});
  const [editorState, setEditorState] = React.useState(() =>
  RichTextEditor.createEmptyValue(),);
  const [pagination, setPagination] = useState<{ take: number; skip: number }>({
    take: 20,
    skip: 0,
  });
  const { register, setValue, handleSubmit, errors, reset, watch } = useForm<
    FormData
  >({
    defaultValues: {
      order: 1,
      description: {},
},
  });
  const { addToast } = useToast();
  const [products, setProducts] = useState<any[]>([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const onSubmit = async function ({...data }: any) {
    try {
      setLoadingSubmit(true);

      const register = {
        ...registerField,
        ...data,
        createdAt: moment.utc().valueOf()
        };
      register.eventId = props.event.id;
      register.cover =
        (await upload(data.cover, props.event.key)) || registerField.cover;

      console.log('onSubmit', { registerField, data, register });

      await api.post('/admin/products', register);

      setRegisterField({});
      reset();
      setEditorState(RichTextEditor.createValueFromString('', 'html'));

      addToast({
        type: 'success',
        title: t("successfull register"),
        description: t("your info was sent successfully"),
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: t("error on register"),
        description:
          error?.response?.data?.message ||
          error.message ||
          t("an error has ocurred on register, try again."),
      });
    }

    setLoadingSubmit(false);
  };

  async function upload(inputFile: any, eventKey: string) {
    if (typeof inputFile !== 'string' && inputFile.length) {
      const uploaded: any = await firebase
        .storage()
        .ref(`/products/${eventKey}/${v4()}`)
        .put(inputFile[0]);
      return (await uploaded.ref.getDownloadURL()) || '';
    }

    return '';
  }

  function edit(entity: any) {
    console.log('edit item', entity);

    setRegisterField(entity);

    for (const key in entity) {
      if (entity.hasOwnProperty(key)) {
        const element = entity[key];
        try {
          if (key != 'resources' && key != 'cover') {
            setValue(key, element);
          }
          //  else if (key == 'description') {
          //   Object.keys(entity).map((lang) =>
          //     setValue(`${key}[${lang}]`, entity[lang]),
          //   );
          // }
        } catch (error) {}
      }
    }

    setEditorState(
      RichTextEditor.createValueFromString(
        entity.description?.[i18n.language] || entity.description || '',
        'html',
      ),
    );
  }

  async function deleteEntity(entity: any) {
    if (window.confirm(t("are you sure that you want to delete this?"))) {
      const result = await api.delete('/admin/products/' + entity.id);
    }
  }
  // async function loadProducts(page?: number, _filters?: any) {
  //   if (props.event.id) {

  //     const _pagination = page
  //       ? { ...pagination, skip: (page - 1) * pagination.take }
  //       : pagination;

  //   const result = await api
  //       .post('/admin/products/list', {
  //         filter: { eventId: props.event.id, ...(_filters || filters) },
  //         pagination: _pagination,
  //       })
  //       setProducts(result.data.result)
  //   }
  // }
  const onCheckAllChange = (e: any) => {
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  useEffect(() => {
    reset();
    setRegisterField({});
    // loadProducts();
    if (props.event.id) {
        firestore
        .collection('products')
        .where('eventId', '==', props.event.id)
        .onSnapshot((snap) => {
          const product = snap.docs?.map<{
            cover: string;
            description: string;
            createdAt: number;
          }>((item) => ({
            ...(item.data() as any),
            id: item.id,
          }));
          setProducts(product);
        });
    }
  }, [props.params.id, props.event.id]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Row>
          <Form.Group as={Col} md="4">
              <Form.Label htmlFor="name">{t("products")}</Form.Label>
              <div className="site-checkbox-all-wrapper">
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  {t("show fields")}
                </Checkbox>
              </div>
            </Form.Group>
        </Form.Row>

        {checkAll && (
        <>
         <Form.Row>
          <Form.Group as={Col} md="2">
            <Form.Label htmlFor="cover">{t("image")}</Form.Label>
            <div className="custom-file">
              <Form.File label={t("upload file")} name="cover" ref={register} />
            </div>
            <a href={registerField.cover} target="_blank">
              <img src={registerField.cover} style={{ height: '50px' }} />
            </a>
          </Form.Group>
         </Form.Row>

        <Form.Row>
          <Form.Group as={Col} md="2">
          <Form.Label htmlFor="order">{t("order")}</Form.Label>
          <Form.Control
            defaultValue={registerField.order || 1}
            type="number"
            name="order"
            placeholder="Ordem"
            autoComplete="off"
            ref={register({ required: true })}
          />
          <>
            {errors[`order`] && (
              <span className="error">{t("order is obligatory")}</span>
            )}
          </>
         </Form.Group>

         {i18n.languages.map(
            (lang: string) =>
              i18n.language == lang && (
                <Form.Group as={Col} md="12">
                  <Form.Label htmlFor={`description[${lang}]`}>
                    {t("description")}
                  </Form.Label>

                  <RichTextEditor
                    className="custom-richtext"
                    value={editorState}
                    onChange={(value) => {
                      const _value = value.toString('html');

                      setRegisterField((prev) => ({
                        ...prev,
                        description: { ...prev.description, [lang]: _value },
                      }));
                      setEditorState(value);
                    }}
                  />
                  {/* <>
                  {errors?.description?.[i18n.language] && (
                    <span className="error">Descrição é obrigatória</span>
                  )}
                </> */}
                </Form.Group>
              ),
          )}

      </Form.Row>
        <Form.Group
        as={Col}
        sm={12}
        className="mt-3"
        style={{ textAlign: 'right' }}
        >
        <Button loading={loadingSubmit} htmlType="submit">
          {t("save")}
        </Button>
       </Form.Group>
      </>
    )}
      </Form>

      <Table size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>{t("image")}</th>
            <th>{t("description")}</th>
            <th>{t("order")}</th>
            <th>{t("actions")}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {products.map((item: any, i: number) => (
            <tr key={i}>
              <th scope="row">{i + 1}</th>
              <td>
                {item.cover && <img style={{ height: 100 }} src={item.cover} />}
              </td>
              <td>{item?.description[i18n.language] || item?.description}</td>
              <td>{item.order}</td>
              <td>
                <Row>
                  <Col xl={3} md={6}>
                    <DropdownButton title={t("update")}>
                      <Dropdown.Item onClick={() => edit(item)}>
                        {t("edit")}
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => deleteEntity(item)}>
                        {t("delete")}
                      </Dropdown.Item>
                    </DropdownButton>
                  </Col>
                </Row>
              </td>
              <td></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default Products;
