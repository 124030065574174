

import React, { createContext, useContext, useEffect, useState } from 'react';
import api from '../services/api';
import { useHistory } from 'react-router-dom';
import { useToast } from './Toast';
import GlobalStyle from '../styles/global';
import firebase from 'firebase/app';
import 'firebase/firestore';

const EventContext = createContext<EventData>({} as EventData);

export interface EventData {
  eventKey: string;
  event: any;
  channel: any;
  channels: any[];
  speakers: any[];
  chat: any;
  programation:any;
  setSpeakers(speakers: any[]): void;
  setChannel(channel: any): void;
  setProgramation(programation: any): void;
  loadEvent(eventKey: string): void;
  loadInfo(eventKey: string, channelKey?: string): void;
}

const EventProvider: React.FC = (props: any) => {
  const history = useHistory();

  const [eventKey, setEventKey] = useState("");
  const [channelKey, setChannelKey] = useState("");
  const [event, setEvent] = useState<any>(null);
  const [channel, setChannel] = useState<any>(null);
  const [channels, setChannels] = useState<any[]>([]);
  const [speakers, setSpeakers] = useState<any[]>([]);
  const [programation, setProgramation] = useState<any>();
  const [chat, setChat] = useState<any>(null);
  const { addToast } = useToast();

  const loadEvent = async (key: string) => {
    if (key != eventKey) {
      setEventKey(key);
      console.log(key);

      try {
        let result: any = null;

        try { api.get("/event/public-info", { params: { "key": key } }); } catch{ }

        try {
          const query = await firebase.firestore().collection('event').where('key', '==', key).get();
          if (query.docs.length) {
            result = { data: query.docs[0].data() };
          }
          else {
            result = await api.get("/event/public-info", { params: { "key": key } });
          }
        } catch (error) {
          addToast({
            type: 'success',
            title: 'Aguarde...',
            description: 'Estamos verificando conexão com o servidor, aguarde...',
          });
          await new Promise((resolve) => { setTimeout(resolve, 15000) });
        }

        if (!result) {
          try {
            result = await api.get("/event/public-info", { params: { "key": key } });
          } catch (error) {
            result = null;
          }
        }

        if (result?.data) {
          document.title = result.data.name;
          setEvent(result.data);
          console.log(result.data);
        }
        else {
          addToast({
            type: 'error',
            title: 'Link não encontrado',
            description: 'Tente acessar novamente mais targe',
          });
        }

        result = null;
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Link não encontrado',
          description: 'Tente acessar novamente mais targe',
        });
      }

    }
  }

  const loadInfo = async (key: string, cKey: string = "") => {
    if (eventKey != key || channelKey != cKey || !channel) {
      setChannel({});
      setEventKey(key);
      setChannelKey(cKey);

      try {
        let result: any = null;

        try {
          result = await api.get(`/event/info?eventKey=${key}&channelKey=${cKey}`);
        } catch (error) {

          if (error?.response?.status != 401) {
            addToast({
              type: 'success',
              title: 'Aguarde...',
              description: 'Estamos verificando conexão com o servidor, aguarde...',
            });
            await new Promise((resolve) => { setTimeout(resolve, 5000) });

            if (!result) {
              try {
                result = await api.get(`/event/info?eventKey=${key}&channelKey=${cKey}`);
              } catch (error) {
                result = null;
              }
            }
          }
          else {
            loadEvent(key);
          }
        }

        if (result?.data && result.data.event) {
          setEvent(result.data.event);
          document.title = result.data.event.name;

          setChannel(result.data.channel);
          setChannels(result.data.channels);
          setChat(result.data.chat);
          console.log(result.data.event);
          console.log(result.data.channel);
        }
        else {
          history.push(`/${key}/logout`);
        }
      } catch (error) {
        history.push(`/${key}/logout`);
      }
    }
  }

  return (
    <>
      <EventContext.Provider value={{ eventKey, event, channel, programation, loadEvent, loadInfo, setSpeakers, setProgramation, setChannel, channels, speakers, chat }}>
        {props.children}
      </EventContext.Provider>
      <GlobalStyle customization={event?.customization || {}} />
    </>
  );
};

function useEvent(): EventData {
  const context = useContext(EventContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { EventProvider, useEvent };
