import 'firebase/auth';
import 'firebase/firestore';

import * as firebase from 'firebase/app';

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useInterval } from 'beautiful-react-hooks';

import Util from '../utils/util';
import api from '../services/api';
import config from '../config';
import { uuid } from 'uuidv4';

export interface IUser {
  id: string;
  role: string;
  name: string;
  email: string;
  avatar_url: string;
  restrictChannels: [];
  isModerator: boolean;
  isAttendance: boolean;
  avatar?: string;
  uid?: string;
  agencyId?: string;
  moderator?: string;
  nationality?: string;
}

interface SignInCredentials {
  email: string;
  password: string;
  eventKey: string;
  pagestart?: string;
}

interface AuthContextData {
  user?: IUser;
  getUser(): IUser | null;
  signIn(credentials: SignInCredentials): Promise<IUser>;
  signOut(): void;
  updateUser(user: IUser): void;
  setUser(user: IUser): void;
  token_facelive: string;
}

const Auth = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  let history = useHistory();
  let location = useLocation();
  const tabIdRef = useRef('');
  const [tabId, setTabId] = useState('');

  const [skipSignOut, setSkipSignOut] = useState<boolean>(false);

  // useEffect(() => {
  //   const unsubscribe = firebase.auth().onAuthStateChanged(async (firebaseUser: firebase.User | null) => {
  //     if (firebaseUser) {
  //       if (!user || user.refreshToken != firebaseUser.refreshToken) {

  //         setUser(firebaseUser);
  //         await updateToken(firebaseUser);
  //         if (location.pathname.indexOf("/dashboard") == -1) {
  //           history.replace("dashboard");
  //         }
  //       }
  //     }
  //   });

  //   return () => {
  //     unsubscribe();
  //   }
  // }, [user]);

  const [user, setUser] = useState<any>(() => {
    const user = localStorage.getItem('@Storage:user');
    if (user) {
      return JSON.parse(user);
    }
    return null;
  });

  const [token, setToken] = useState<string>(() => {
    const token = localStorage.getItem('@Storage:token');

    if (token) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      return token;
    }
    return '';
  });
  const [token_facelive, setTokenFacelive] = useState<string>(() => {
    const $token_facelive = localStorage.getItem('@Storage:token_facelive');

    if ($token_facelive) {
      return $token_facelive;
    }
    return '';
  });
  const [customToken, setCustomToken] = useState<string>(() => {
    const $customToken = localStorage.getItem('@Storage:customToken');

    if ($customToken) {
      return $customToken;
    }
    return '';
  });

  useEffect(() => {
    if (customToken) {
      firebase
        .auth()
        .signInWithCustomToken(customToken)
        .then((user) => {})
        .catch((e) => {
          localStorage.removeItem('@Storage:customToken');

          console.log('User Firebase Auth error', e);
        });
    }

    const unsubscribe = firebase
      .auth()
      .onAuthStateChanged(async (firebaseUser: firebase.User | null) => {
        if (firebaseUser) {
          // if (!user || user.refreshToken != firebaseUser.refreshToken) {
          //   // setUser(firebaseUser);
          //   // await updateToken(firebaseUser);
          //   // if (location.pathname.indexOf('/dashboard') == -1) {
          //   //   history.replace('dashboard');
          //   // }
          // }
        }
      });

    return () => {
      unsubscribe();
    };
  }, [customToken]);

  const firestore = firebase.firestore();

  function updateToken(refreshToken: string) {
    api.defaults.headers.authorization = `Bearer ${refreshToken}`;
    localStorage.setItem('@Storage:token', refreshToken);
    // setToken(refreshToken);
    return token;
  }

  async function updateUser(user: any) {
    const userConverted: IUser = user?.displayName
      ? {
          avatar_url: user?.photoURL || '',
          name: user?.displayName || '',
          email: user?.email || '',
          id: user?.uid || '',
        }
      : user;

    //const profile = await api.get("/profile");
    localStorage.setItem('@Storage:user', JSON.stringify(userConverted));

    setUser(user);
    return userConverted;
  }

  async function updateTokenFaceLive($token_facelive: any) {
    localStorage.setItem(
      '@Storage:token_facelive',
      JSON.stringify($token_facelive),
    );
    setTokenFacelive($token_facelive);
    return $token_facelive;
  }

  const getUser = (): IUser | null => {
    if (!user) return null;

    return {
      ...user,
      isModerator: user?.role === 'moderator' || user?.role === 'attendance',
      isAttendance: user?.role === 'attendance',
    };
  };

  useEffect(() => {
    if (
      (window?.performance?.getEntriesByType('navigation')?.[0] as any)
        ?.type === 'back_forward'
    ) {
      sessionStorage.removeItem('4yourlive:tabid');
      console.log('tab duplicada');
    }

    tabIdRef.current =
      tabIdRef.current || sessionStorage.getItem('4yourlive:tabid') || '';

    if (!tabIdRef.current) {
      tabIdRef.current = uuid();
      sessionStorage.setItem('4yourlive:tabid', tabIdRef.current);
    }

    setTabId(tabIdRef.current);
  }, []);
  // const signIn = useCallback(async ({ email, password, eventKey, pagestart }) => {

  //   const result = await api.post("/sessions/login", {
  //     email,
  //     password,
  //     eventKey
  //   });

  //   localStorage.setItem('@Storage:usertoken', result.data.token);

  //   const signedUser = await firebase.auth().signInWithCustomToken(result.data.token);
  //   updateToken(await signedUser?.user?.getIdToken(true) || "");
  //   const user = updateUser(result.data.user);
  //   setSkipSignOut(true);

  //   if(pagestart){
  //     document.location.href = pagestart;
  //   }
  //   else
  //   {
  //     document.location.href = `/${eventKey}/`;
  //   }

  //   return user;
  // }, []);

  const signIn = useCallback(
    async ({ email, password, eventKey, pagestart }) => {
      let result: any = null;
      // try 3x

      let loginUri = '/sessions/login-admin';

      // if (window.location.href.indexOf('rstcom-sandbox') >= 0)
      //   loginUri = AUTH_URL;

      try {
        result = await api.post(loginUri, {
          email,
          password,
          eventKey,
        });
      } catch (error) {
        if (error?.response?.data?.message) {
          throw new Error(error?.response?.data?.message);
        }

        try {
          await Util.sleep(3);
          result = await api.post('/sessions/login-admin', {
            email,
            password,
            eventKey,
          });
        } catch (error2) {
          try {
            await Util.sleep(3);
            result = await api.post('/sessions/login-admin', {
              email,
              password,
              eventKey,
            });
          } catch (error3) {
            throw error3;
          }
        }
      }

      localStorage.setItem('@Storage:usertoken', result.data.token);
      localStorage.setItem('@Storage:customToken', result.data.firebase_token);

      // const signedUser = await firebase
      //   .auth()
      //   .signInWithCustomToken(result.data.token);
      // updateToken((await signedUser?.user?.getIdToken(true)) || '');
      await firebase
        .auth()
        .signInWithCustomToken(result.data.firebase_token)
        .then(() => {
          console.log('firebase auth success');
        })
        .catch((e) => {
          console.log('firebase auth error', e);
        });
      updateToken(result.data.token);
      setCustomToken(result.data.firebase_token);
      const $user = updateUser(result.data.user);
      // const token_facelive = updateTokenFaceLive(result.data.token_facelive);
      setSkipSignOut(true);

      if (pagestart) {
        // document.location.href = pagestart;
        history.push(pagestart);
      } else {
        // document.location.href = `/${eventKey}/`;
        history.push(`/${eventKey}/`);
      }

      return $user;
    },
    [],
  );

  const signOut = useCallback(async () => {
    const $token = localStorage.getItem('@Storage:token');

    if ($token) {
      try {
        api.post('/sessions/logout');
      } catch (error) {
        try {
          api.post('/sessions/logout');
        } catch (e) {
          console.log('logout error', e);
        }
      }

      setToken('');
      setUser(null);
      localStorage.removeItem('@Storage:token');
      localStorage.removeItem('@Storage:user');
      localStorage.removeItem('@Storage:usertoken');
    }
  }, []);

  // const updateUser = useCallback(
  //   (user: User) => {
  //     localStorage.setItem('@Storage:user', JSON.stringify(user));

  //     let currentUser = firebase.auth().currentUser;
  //     if (currentUser) {
  //       let updatedUser = { ...currentUser, ...user };
  //       firebase.auth().updateCurrentUser(updatedUser);
  //     }

  //     setData({
  //       token: data.token,
  //       user
  //     });
  //   },
  //   [setData, data.token],
  // );

  document.onkeydown = fkey;
  document.onkeypress = fkey;
  document.onkeyup = fkey;

  let wasPressed = false;

  function fkey(e: any) {
    e = e || window.event;
    if (wasPressed) return;

    if (e.keyCode == 116) {
      wasPressed = true;
    }
  }

  window.onbeforeunload = function (e: any) {
    const ev = e;
    if (
      window?.document?.activeElement?.localName == 'body' &&
      !wasPressed &&
      !skipSignOut &&
      user?.id
    ) {
      //signOut();
    }
  };

  async function keepAlive(): Promise<void> {
    try {
      const userToken = localStorage.getItem('@Storage:usertoken') as string;
      if (userToken) {
        console.log(`keep-alive ${new Date().toString()}`);
        const result = await api.post('/sessions/keep-alive');
        const usertoken = result.data;
        if (usertoken) {
          const signedUser = await firebase
            .auth()
            .signInWithCustomToken(usertoken.firebase_token);
          // const token = await signedUser?.user?.getIdToken(true);

          setCustomToken(usertoken.firebase_token);
          if (usertoken.token) {
            updateToken(usertoken.token);
          }
        }
      }
    } catch (error) {
      console.log('Cannot keep connection alive: ', error);
    }
  }

  useInterval(() => {
    // execute only when has a loged user
    if (user?.id) keepAlive();
  }, 1000 * 30);

  // useEffect(() => {
  //   console.log('ok');

  //   // repeat the function each 1000ms

  //   // const unsubscribe = setInterval(keepAlive, 6000 * 3);//60000
  //   // return () => {
  //   //   clearInterval(unsubscribe);
  //   // };
  // }, [user]);

  function useInterval2(callback: any, delay: any) {
    const savedCallback = useRef();

    // Remember the latest function.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        //savedCallback?.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  function Counter() {
    const [count, setCount] = useState(0);

    useInterval2(() => {
      // Your custom logic here
      setCount(count + 1);
    }, 1000);

    console.log(count);
  }
  //Counter();

  return (
    <Auth.Provider
      value={{
        user,
        getUser,
        signIn,
        signOut,
        updateUser,
        setUser,
        token_facelive,
      }}
    >
      {children}
    </Auth.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(Auth);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
