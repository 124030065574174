import React, { Component, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import Avatar from 'react-avatar';
//i18n
// import { withNamespaces } from "react-i18next";

// users
import avatar2 from '../../../assets/images/users/avatar-2.jpg';
import { useAuth } from '../../../hooks/Auth2';

const ProfileMenu: React.FC<any> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { user, signOut } = useAuth();
  const [state, setState] = useState({ menu: false });

  function toggle() {
    setState((prevState: any) => ({
      menu: !prevState.menu,
    }));
  }

  function handleLogout() {
    signOut();
    history.push('/');
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={state.menu}
        toggle={toggle}
        className="d-inline-block user-dropdown"
      >
        <DropdownToggle
          tag="button"
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
        >
          {user?.avatar_url && (
            <img
              className="rounded-circle header-profile-user mr-1"
              src={avatar2}
              alt="Header Avatar"
            />
          )}
          {!user?.avatar_url && (
            <Avatar
              className="mr-1"
              size="30px"
              name={user?.name || user?.email}
              round
            />
          )}
          <span className="d-none d-xl-inline-block ml-1 text-transform">
            {user?.name || user?.email}
          </span>
          <i className="mdi mdi-chevron-down d-none ml-1 d-xl-inline-block"></i>
        </DropdownToggle>
        <DropdownMenu right>
          {/* <DropdownItem href="#">
            <i className="ri-user-line align-middle mr-1"></i> {t('Profile')}
          </DropdownItem>
          <DropdownItem href="#">
            <i className="ri-wallet-2-line align-middle mr-1"></i>{' '}
            {t('My Wallet')}
          </DropdownItem>
          <DropdownItem className="d-block" href="#">
            <span className="badge badge-success float-right mt-1">11</span>
            <i className="ri-settings-2-line align-middle mr-1"></i>{' '}
            {t('Settings')}
          </DropdownItem>
          <DropdownItem href="#">
            <i className="ri-lock-unlock-line align-middle mr-1"></i>{' '}
            {t('Lock screen')}
          </DropdownItem>
          <DropdownItem divider /> */}
          <DropdownItem className="text-danger" href="#" onClick={handleLogout}>
            <i className="ri-shut-down-line align-middle mr-1 text-danger"></i>{' '}
            {t('Logout')}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileMenu;
