import React, { useEffect, useRef, useState } from 'react';
import {
  Col,
  Dropdown,
  DropdownButton,
  Form,
  Row,
  Table,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import firebase from 'firebase';

import api from '../../../../../services/api';
import { useToast } from '../../../../../hooks/Toast';
import { Button } from 'antd';
import { Spinner } from 'reactstrap';
import { useEmitter } from '../../../../../hooks/Emitter';
import { Typeahead } from 'react-bootstrap-typeahead';

type FormData = {
  index: number;
  name_languages: [];
  link_languages: [];
  order: string;
};

interface ICloudWordTab {
  channel: any;
}

const CloudWordTab: React.FC<ICloudWordTab> = ({ channel }) => {
  const { addToast } = useToast();
  const [editItem, setEditItem] = useState<any>({});
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [cloudWords, setCloudWords] = useState<any>();
  const { register, setValue, reset, handleSubmit, errors } = useForm<any>();

  function loadCloudWords() {
    api
      .get(
        `/admin/cloud-words?eventId=${channel.eventId}&channelId=${channel.id}`,
      )
      .then((r) => setCloudWords(r.data));
  }

  useEffect(() => {
    if (channel.id) loadCloudWords();
  }, [channel.id]);

  useEffect(() => {
    if (cloudWords) {
      setValue('title', cloudWords.title || '');
      setValue(
        'text',
        Object.keys(cloudWords.words).reduce((a, b) => `${a} ${b}`) || '',
      );
    }
  }, [cloudWords]);

  async function save(data: any) {
    try {
      setIsBusy(true);

      const { title, text } = data;
      const words: any = {};

      if (text) {
        text.split(' ').forEach((word: string) => {
          words[word] = cloudWords?.words?.[word] || 1;
        });
      }

      await saveWord({
        id: cloudWords.id || null,
        title,
        words,
        eventId: channel.eventId,
        eventKey: channel.eventKey,
        channelId: channel.id,
        channelKey: channel.key,
      });
      reset();
      setValue('id', '');

      loadCloudWords();
    } catch (error) {
    } finally {
      setIsBusy(false);
    }
  }

  async function saveWord(word: any) {
    await api
      .post('/admin/cloud-words', word)
      .then((r) => {
        addToast({
          type: 'success',
          title: 'Palavra alterada com sucesso',
          description: 'Sua informação foi enviada com sucesso!',
        });
      })
      .catch((e) => {
        addToast({
          type: 'error',
          title: 'Erro no envio',
          description:
            e?.response?.data?.message ||
            e.message ||
            'Ocorreu um erro ao fazer o envio, tente novamente.',
        });
      });
  }

  function edit(item: any, index: number) {
    // setEditItem(item);
    Object.keys(item).map((v) => {
      setValue(v, item[v]);
    });
    // setValue('id', index);
  }

  function deleteEntity(item: any, index: number) {
    if (window.confirm('Você tem certeza que deseja deletar isto?')) {
      const _channel = { ...channel };

      _channel.resources = _channel.resources.filter(
        (v: any, i: number) => i !== item.index,
      );

      api
        .delete(`/admin/cloud-words/${item.id}`)
        .then((r) => {
          addToast({
            type: 'success',
            title: 'Palavra removida com sucesso!',
            description: 'Sua informação foi enviada com sucesso!',
          });
          loadCloudWords();
        })
        .catch((e) => {
          addToast({
            type: 'error',
            title: 'Erro no envio',
            description:
              e?.response?.data?.message ||
              e.message ||
              'Ocorreu um erro ao fazer o envio, tente novamente.',
          });
        });
    }
  }

  console.log('render CloudWordsTab', { channel });

  return (
    <div>
      <form onSubmit={handleSubmit(save)}>
        <div className="form-row">
          <Form.Group as={Col} md="12">
            <Form.Label htmlFor="title">Titulo</Form.Label>
            <Form.Control ref={register()} name="title" placeholder="" />
          </Form.Group>
          <Form.Group as={Col} md="12">
            <Form.Label htmlFor="text">Palavras</Form.Label>
            <Form.Control
              ref={register({ required: true })}
              name="text"
              placeholder=""
            />
          </Form.Group>
        </div>
        <button className="btn btn-primary" type="submit">
          {!isBusy ? 'Salvar' : <Spinner className="ml-3 mr-3" size={'sm'} />}
        </button>
      </form>
    </div>
  );
};

export default CloudWordTab;
