import React, { useState, useCallback, useEffect, useMemo } from 'react';
import 'react-day-picker/lib/style.css';
import 'firebase/firestore';
import 'firebase/auth';
import { useAuth } from '../../../hooks/FirebaseAuth';
import * as Enumerable from 'linq-es2015';
import api from '../../../services/api';
import Loader from '../../../components/Loader';
import RichTextEditor from 'react-rte';
import * as Yup from 'yup';
import DashboardMasterPage from '../../../masterpages/DashboardMasterPage';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {
  Row,
  Col,
  Card,
  Tabs,
  Container,
  Tab,
  Table,
  Form,
  InputGroup,
  Modal,
  Dropdown,
  DropdownButton,
} from 'react-bootstrap';
//@ts-ignore
import CopyToClipboard from 'react-copy-to-clipboard';
import Datetime from 'react-datetime';
import { SketchPicker } from 'react-color';
import CustomButton from '../../../components/Button';
import { useForm } from 'react-hook-form';
import produce from 'immer';
import { Link, useHistory } from 'react-router-dom';
import { useToast } from '../../../hooks/Toast';
import firebase from 'firebase';
import moment from 'moment';
import { v4 } from 'uuid';
import { FiEdit } from 'react-icons/fi';
import { asEnumerable } from 'linq-es2015';
import { useTranslation } from 'react-i18next';
import Util from '../../../utils/util';
import ColorPicker from 'rc-color-picker';
import { Checkbox } from 'antd';
import { DatePicker, Space, Button } from 'antd';
import { channel } from '@redux-saga/core';
const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;
interface Entity {
  id: string;
  content: string;
  name: string;
  isActive: boolean;
}

const Popup: React.FC<{ event: any; channel: any }> = (props) => {
  const [entity, setEntity] = useState<Entity>({} as Entity);
  const [loadDefa, setLoadDefa] = useState<boolean>(false);
  const [channelId, setChannelId] = useState<string>(props.channel.id);

  const [isCopyed, setIsCopyed] = useState<boolean>(false);
  const [loadingOnSubmit, setLoadingOnSubmit] = useState<boolean>(false);
  const { addToast } = useToast();
  const [popups, setPopups] = useState<Entity[]>([]);
  const [name, setName] = useState<string>('');
  const [urlImage, setUrlImage] = useState<string>('');
  const [contentIframe, setContentIframe] = useState<string>('');
  const [linkRedirect, setLinkRedirect] = useState<string>('');
  const [loadingActive, setLoadingActive] = useState<boolean>(false);
  const [loadingInative, setLoadingInative] = useState<boolean>(false);

  const inativePopups = () => {
    if (loadingInative) return;
    setLoadingInative(true);
    api
      .post(`/admin/channel/popup/inative/${channelId}`)
      .catch((error) => {
        addToast({
          type: 'error',
          title: 'Error',
          description:
            error?.response?.data?.message ||
            error.message ||
            'Ocorreu um erro ao fazer cadastro, tente novamente.',
        });
      })
      .finally(() => {
        setLoadingInative(false);
        load();
      });
  };
  const activepPopups = () => {
    if (loadingActive) return;
    setLoadingActive(true);
    api
      .post(`/admin/channel/popup/active/${channelId}`, popups)
      .catch((error) => {
        addToast({
          type: 'error',
          title: 'Error',
          description:
            error?.response?.data?.message ||
            error.message ||
            'Ocorreu um erro ao fazer cadastro, tente novamente.',
        });
      })
      .finally(() => {
        setLoadingActive(false);
        load();
      });
  };

  const deleteEntity = (id: string) => {
    api
      .post(`/admin/channel/popup/${channelId}/${id}`)
      .catch((error) => {
        addToast({
          type: 'error',
          title: 'Error',
          description:
            error?.response?.data?.message ||
            error.message ||
            'Ocorreu um erro ao fazer cadastro, tente novamente.',
        });
      })
      .finally(() => load());
  };

  const load = () => {
    api
      .get(`/admin/channel/popup/${channelId || props?.channel?.id}`)
      .then((res) => {
        setPopups(res.data);
      })
      .catch((error) => {
        console.log(error)
        // addToast({
        //   type: 'error',
        //   title: 'Error',
        //   description:
        //     error?.response?.data?.message ||
        //     error.message ||
        //     'Ocorreu um erro ao fazer cadastro, tente novamente.',
        // });
      })
      .finally(() => {
        setLoadDefa(true);
      });
  };

  const uploadImage = async (inputFile: any) => {
    if (typeof inputFile !== 'string' && inputFile?.length) {
      const uploaded: any = await firebase
        .storage()
        .ref(`/companies/${props.event.key}/customization/${v4()}`)
        .put(inputFile[0]);
      const url = (await uploaded.ref.getDownloadURL()) || '';
      setUrlImage(url);
    }
  };

  const [editorState, setEditorState] = React.useState(() =>
    RichTextEditor.createEmptyValue(),
  );

  useEffect(() => {
    load();
  }, [channelId]);

  useEffect(() => {
    setChannelId(props.channel.id);
  }, [props?.channel?.id]);

  const onSubmit = () => {
    if (loadingOnSubmit) return;
    setLoadingOnSubmit(true);

    api
      .post('/admin/channel/popup', {
        channel: { ...props.channel, id: channelId },
        popup_settings: {
          content: contentIframe,
          name: name,
          url: linkRedirect,
        },
      })
      .then((e) => {
        load();
      })
      .catch((error) => {
        console.log(error);
        addToast({
          type: 'error',
          title: 'Error',
          description:
            error?.response?.data?.message ||
            error.message ||
            'Ocorreu um erro ao fazer cadastro, tente novamente.',
        });
      })
      .finally(() => {
        setLoadingOnSubmit(false);
      });
  };

  // if (!isLoaded) {
  //     return (
  //         <div className="text-center" style={{ width: '100%' }}>
  //             <Loader />
  //         </div>
  //     )
  // }
  return (
    <React.Fragment>
      <Form
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <Form.Row>
          <Form.Group as={Col} md="6">
            <Form.Label htmlFor={'cover'}>Suba uma imagem</Form.Label>
            <Form.File
              type="file"
              name={`cover`}
              custom
              label={
                urlImage
                  ? 'Foi feito um upload de uma imagem'
                  : 'Selecione uma imagem para fazer o upload'
              }
              onChange={(e: any) => {
                e.preventDefault();
                const a: any = e.target.files;
                if (String(a[0].name).length !== 0) {
                  uploadImage(a);
                }
              }}
              autoComplete="off"
            />
            <div style={{ padding: 10 }} />

            {urlImage && (
              <>
                <Form.Label htmlFor="email">
                  Clique no link para copiar a url para área de transferência
                </Form.Label>
                <CopyToClipboard
                  text={urlImage}
                  onCopy={() => setIsCopyed(true)}
                >
                  <div
                    style={{
                      border: 'solid',
                      borderWidth: 1,
                      borderColor: '#e5e5e5',
                      padding: 7,
                      cursor: 'pointer',
                    }}
                  >
                    <span>{urlImage}</span>
                  </div>
                </CopyToClipboard>
              </>
            )}

            <div style={{ padding: 5 }} />

            {isCopyed && urlImage.length !== 0 && (
              <span style={{ fontSize: 12 }}>
                Link copiado para a área de transferência
              </span>
            )}

            <div style={{ padding: 5 }} />
          </Form.Group>
          <Form.Group className="text-center column" as={Col} md="6">
            {urlImage && (
              <img
                src={urlImage}
                className="img-rounded"
                alt="Cinque Terre"
                width="304"
              />
            )}
            <div style={{ padding: 5 }} />
            {urlImage && (
              <span
                onClick={() => {
                  setUrlImage('');
                }}
                className="cursor"
              >
                remover
              </span>
            )}
          </Form.Group>

          {/* <Form.Group as={Col} md="12">
                        <RichTextEditor
                            className="custom-richtext"
                            value={editorState}
                            onChange={(value) => {
                                const _value = value.toString('html');
                                console.log('value', _value)
                                setContentIframe(_value)
                                setEditorState(value);
                            }}
                        />
                    </Form.Group> */}

          <Form.Group as={Col} md="6">
            <Form.Label htmlFor={'cover'}>
              Dê um nome para este PopUp
            </Form.Label>
            <Form.Control
              type="text"
              name={`name`}
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Insira o nome do Pop-up"
              autoComplete="off"
            />
          </Form.Group>

          <Form.Group as={Col} md="6">
            <Form.Label htmlFor={'PopUp'}>
              Conteúdo do PopUp (Se for imagem, cole aqui a URL)
            </Form.Label>
            <Form.Control
              type="text"
              name={`PopUp`}
              value={contentIframe}
              onChange={(e) => setContentIframe(e.target.value)}
              placeholder="Insira a url do PopUp"
              autoComplete="off"
            />
          </Form.Group>

          <Form.Group as={Col} md="6">
            <Form.Label htmlFor={'PopUpLink'}>
              Link de Redirecionamento
            </Form.Label>
            <Form.Control
              type="text"
              name={`PopUpLink`}
              value={linkRedirect}
              onChange={(e) => setLinkRedirect(e.target.value)}
              placeholder="Insira a url de redirecionamento"
              autoComplete="off"
            />
          </Form.Group>

          <Form.Group as={Col} md="12">
            <Button loading={loadingOnSubmit} htmlType="submit">
              Salvar
            </Button>
          </Form.Group>
        </Form.Row>

        <Form.Group as={Col} md="12">
          <label htmlFor="tipouser">Popup Ativo</label>
          <div id="tipouser" className={`input-group col-${Col} mb-3`}>
            {/* <div className="input-group-prepend">
                           
                            </div> */}
            <select
              value={
                popups.length !== 0
                  ? popups[popups.findIndex((e) => e.isActive === true)]?.name
                  : ''
              }
              onChange={(es: any) => {
                const index: number = popups.findIndex(
                  (res) => res.name === es.target.value,
                );
                const newpopups = produce(popups, (draftState: Entity[]) => {
                  draftState.map((r: Entity, i: number) => {
                    if (i === index) {
                      r.isActive = true;
                    } else {
                      r.isActive = false;
                    }
                  });
                });
                setPopups(newpopups);
              }}
              className="custom-select"
            >
              <option
                value={
                  popups.length !== 0
                    ? popups[popups?.findIndex((e) => e.isActive === true) || 0]
                        ?.name
                    : ''
                }
              >
                {popups.length !== 0
                  ? popups[popups.findIndex((e) => e.isActive === true)]?.name
                  : 'Selecione....'}
              </option>
              {popups.length !== 0 &&
                popups?.map((res: Entity, index: number) => {
                  if (index !== popups.findIndex((e) => e.isActive === true)) {
                    return (
                      <option key={index} value={res.name}>
                        {res.name}
                      </option>
                    );
                  }
                })}
            </select>
          </div>
          <div id="tipouser" className={`input-group col-${Col} mb-3`}>
            <Button onClick={activepPopups} loading={loadingActive}>
              Salvar popup como ativo
            </Button>
          </div>
          <div id="tipouser" className={`input-group col-${Col} mb-3`}>
            <Button loading={loadingInative} onClick={inativePopups}>
              Desativar todos os popups
            </Button>
          </div>
        </Form.Group>
      </Form>

      <Table size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Nome</th>
            <th>Ativo</th>
            <th>Atualizar</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {!loadDefa && (
            <tr>
              <td colSpan={12} className="text-center">
                <Loader />
              </td>
            </tr>
          )}
          {loadDefa &&
            popups.length !== 0 &&
            popups?.map((item: Entity, i: number) => (
              <tr key={i}>
                <th scope="row">{i + 1}</th>
                <td>{item.name}</td>
                <td>{item.isActive ? 'sim' : 'não'}</td>
                <td>
                  <Row>
                    <Col xl={3} md={6}>
                      <DropdownButton title="Editar">
                        <Dropdown.Item onClick={() => deleteEntity(item?.id)}>
                          Deletar
                        </Dropdown.Item>
                      </DropdownButton>
                    </Col>
                  </Row>
                </td>
                <td></td>
              </tr>
            ))}
        </tbody>
      </Table>
    </React.Fragment>
  );
};
export default Popup;
