import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import Files from '../pages/Files';
import Dashboard from '../pages/Dashboard';
import Profile from '../pages/Profile';
import Home from '../pages/Home';
import Default from '../pages/Default';
import LogOut from '../pages/LogOut';
import Events from '../pages/Events';
import EventDetails from '../pages/Events/Details';
import Companies from '../pages/Companies';
import CompanyDetails from '../pages/Companies/Details';
import Chats from '../pages/Chats';
import Timelines from '../pages/Timelines';
import Languages from '../pages/Languages';
import Language from '../pages/Languages/Details';
import Users from '../pages/Users';
import UserDetails from '../pages/Users/Details';
import AgencyDetails from '../pages/Agencies/Details';
import Agencies from '../pages/Agencies';
import MailMarketings from '../pages/MailMarketing';
import MailMarketingDetails from '../pages/MailMarketing/Details';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    <Route path="/signin" component={SignIn} />
    <Route path="/signup" component={SignUp} />
    <Route path="/forgot-password" component={ForgotPassword} />
    <Route path="/reset-password" component={ResetPassword} />
    <Route path="/dashboard/:channel" component={Dashboard} isPrivate />
    <Route path="/dashboard" component={Dashboard} isPrivate />
    <Route path="/chats" component={Chats} isPrivate />
    <Route path="/timelines" component={Timelines} isPrivate />
    <Route path={['/user/:id', '/user']} component={UserDetails} isPrivate />
    <Route path="/users" component={Users} isPrivate />
    <Route
      path={['/company/:id', '/company']}
      component={CompanyDetails}
      isPrivate
    />
    <Route path="/companies" component={Companies} isPrivate />
    <Route
      path={['/agency/:id', '/agency']}
      component={AgencyDetails}
      isPrivate
    />
    <Route path="/agencies" component={Agencies} isPrivate />
    <Route path="/languages" component={Languages} isPrivate />
    <Route
      path={['/language/:id', '/language']}
      component={Language}
      isPrivate
    />
    <Route path="/event/:id" component={EventDetails} isPrivate />
    <Route path="/event" component={EventDetails} isPrivate />
    <Route path="/events" component={Events} isPrivate />
    <Route path="/files" component={Files} isPrivate />
    <Route path="/logout" isPrivate component={LogOut} />
    <Route path="/profile" component={Profile} isPrivate />
    <Route path="/emails" component={MailMarketings} isPrivate />
    <Route path={["/email/:id","/email"]} component={MailMarketingDetails} isPrivate />
  </Switch>
);

export default Routes;
