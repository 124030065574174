import React, { useState, useEffect, useRef } from 'react';
import 'react-day-picker/lib/style.css';
import 'firebase/firestore';
import 'firebase/auth';

import {
  Row,
  Col,
  Table,
  Form,
  Dropdown,
  DropdownButton,
  Pagination,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import firebase from 'firebase';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';

import { useToast } from '../../../hooks/Toast';
import Button from '../../../components/Button';
import CustomPagination from '../../../components/CustomPagination';
import api from '../../../services/api';
import Loader from '../../../components/Loader';
import { useAuth } from '../../../hooks/Auth';
import Util from '../../../utils/util';
import moment from 'moment';

interface ILogistic {
  name: string;
  crm: string;
  email: string;
  sponsorshipType: string;
  status: string;
  goingOrigin?: string;
  goingDestiny: string;
  goingDate: string;
  goingCompany: string;
  goingDeparture: string;
  goingArrival: string;
  goingLoc: string;
  goingEtkt: string;
  returnOrigin?: string;
  returnDestiny: string;
  returnDate: string;
  returnCompany: string;
  returnDeparture: string;
  returnArrival: string;
  returnLoc: string;
  returnEtkt: string;
  accomodationCheckin: string;
  accomodationCheckout: string;
  transfer: string;
  hotelAccomodationName: string;
  hotelAccomodationAddress: string;
  cityAccomodationUf: string;
  cepAccomodation: string;
  phoneAccomodation: string;
  hotelEventName: string;
  hotelEventAddress: string;
  cityEventUf: string;
  cepEvent: string;
  phoneEvent: string;
  goingFlightNumber: number;
  goingSeatNumber: number;
  returnFlightNumber: number;
  returnSeatNumber: number;
}

type FieldsItemType = {
  name: string;
};

const firestore = firebase.firestore();

const Logistics: React.FC<{ event: any }> = (props: any) => {
  const refFile = useRef<HTMLInputElement>(null);
  const { addToast } = useToast();
  const [visibleImport, setVisibleImport] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>();
  const [import_email, setImportEmail] = useState('');
  const [logistics, setLogistics] = useState<ILogistic[]>([]);
  const [filters, setFilters] = useState<any>({});
  const [active, setActive] = useState<number>(1);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState('');
  const [isBusy, setIsBusy] = useState(false);
  const [pagination, setPagination] = useState<{ take: number; skip: number }>({
    take: 20,
    skip: 0,
  });
  const { t } = useTranslation();

  const showModal = () => {
    setVisibleImport(true);
  };

  const handleCancel = () => {
    setVisibleImport(false);
  };



  const changePage = (page: number) => {
    setPagination({ ...pagination, skip: (page - 1) * pagination.take });
    setActive(page);
  };

  async function deleteEntity(entity: any) {
    if (window.confirm(t("are you sure that you want to delete this?"))) {
      await api.delete('/admin/logistic/' + entity.id);
      loadLogistics();
    }
  }

  const onSelectedFile = (file: React.ChangeEvent<HTMLInputElement>) => {
    if (file.currentTarget.files !== null)
      setSelectedFile(file.currentTarget.files[0]);
  };

  function handleEnterKeyPressed(e: React.KeyboardEvent<HTMLInputElement>) {
    console.log('key', { key: e.key, code: e.keyCode });
    if (e.key.toLowerCase() === 'enter') {
      handleFilterClick();
    }
  }

  function handleFilterChange(e: React.ChangeEvent<HTMLInputElement>) {
    const _value = e.currentTarget.value;
    setFilter(_value);
  }

  function handleFilterClick() {
    setActive(1);
    setFilters((prev: any) => {
      if (!filter) {
        const { name, email, ..._filter } = prev;

        loadLogistics();

        return _filter;
      }

      const _filters = {
        ...prev,
        name: filter,
        email: filter,
      };

      loadLogistics(1, _filters);

      return filter;
    });
  }

  function loadLogisticFilter(email: any) {
    if (props.event.id) {
      firebase
        .firestore()
        .collection('logistic')
        .where('eventId', '==', props.event.id)
        .where('email', '==', email)
        .onSnapshot((snap => {
          let logistic = snap.docs?.map((item) => ({
            ...(item.data() as any),
            id: item.id,
          }));
          logistic = logistic.sort((a: any, b: any) => {
            if (a.createdAt > b.createdAt) return -1;
            else if (a.createdAt < b.createdAt) return 1;
            else return 0;
          });
          setLogistics(logistic)
        }))
    }
  }

  async function upload(inputFile: any): Promise<string> {
    if (inputFile) {
      const uploaded: any = await firebase
        .storage()
        .ref(`/companies/${props.event.key}/importation/${v4()}`)
        .put(inputFile);
      return (await uploaded.ref.getDownloadURL()) || '';
    }

    return '';
  }

  const importFile = async () => {
    try {
      setLoading(true);

      if (!selectedFile) {
        addToast({
          type: 'error',
          title: 'Selecione um arquivo',
          description: 'O arquivo é obrigatório.',
        });
        return;
      }

      if (!import_email) {
        addToast({
          type: 'error',
          title: 'Infome um e-mail para notificação',
          description: 'O e-mail é obrigatório.',
        });
        return;
      }

      const link = await upload(selectedFile);

      const result = await api.post('/admin/logistic/import', {
        eventId: props.event.id,
        eventKey: props.event.key,
        email: import_email,
        link,
      });

      addToast({
        type: 'success',
        title: 'Importação realizada com sucesso',
        description: 'Sua informação foi enviada com sucesso!',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro no cadastro',
        description:
          error?.response?.data?.message ||
          error.message ||
          'Ocorreu um erro ao fazer cadastro, tente novamente.',
      });
    } finally {
      setLoading(false);
    }
    setSelectedFile(undefined);
    if (refFile?.current) refFile.current.value = '';
    setImportEmail('');
    setVisibleImport(false);
  };

  useEffect(() => {
    loadLogistics();

  }, [props.event.id, , pagination]);

  function loadLogistics(page?: number, _filters?: any) {
    if (props.event.id) {
      setIsBusy(true);

      const _pagination = page
        ? { ...pagination, skip: (page - 1) * pagination.take }
        : pagination;
      api
        .post('/admin/logistic/list', {
          filter: { eventId: props.event.id, ...(_filters || filters) },
          pagination: _pagination,
        })
        .then((r) => {
          setLogistics(r.data.result);
          setCount(r.data.count);
        })
        .finally(() => {
          setIsBusy(false);
        });
    }
  }


  return (
    <>
      <div className="row">
        <div className="col-12  col-md-auto d-flex text-right">
          <Button type="button" onClick={showModal}>
            {t("import sheet")}
          </Button>
          <Button type="button" onClick={() => {
            window.location.assign("https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2Ftakedagastroacademy%2Ffiles%2Fplanilha-logistica.xlsx?alt=media&token=8f84b30e-b07a-4d0a-811c-64b14b2f8ff0");
          }
          }>
            {t("download sheet")}
          </Button>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-12 col-md">
          <div className="form-inline">
            <label>{t("filter")}: </label>

            <input
              type="text"
              name="filter"
              placeholder={t("search for participant name or email")}
              className="form-control flex-grow-1 ml-2 mr-2"
              value={filter}
              onChange={(e) => handleFilterChange(e)}
              onKeyUp={handleEnterKeyPressed}
            />
            <button
              className="btn btn-primary m-0"
              onClick={handleFilterClick}
            >
              Filtrar
            </button>
          </div>
        </div>
      </div>
      <Modal
        visible={visibleImport}
        title={t("import logistic sheet")}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {t("cancel")}
          </Button>,
          <Button
            key="submit"
            type="button"
            loading={loading}
            onClick={importFile}
          >
            {t("import")}
          </Button>,
        ]}
      >
        <Form.Group as={Col} md={12}>
          <Form.Label htmlFor="import">{t("import list")}</Form.Label>
          <div className="custom-file">
            <Form.File
              ref={refFile}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                console.log('file on change', e);
                onSelectedFile(e);
              }}
              accept=".xlsx"
              label={t("select file")}
              name="import"
            />
          </div>
        </Form.Group>
        <Form.Group as={Col} md={12}>
          <Form.Label htmlFor="import_email">E-mail</Form.Label>
          <div className="custom-file">
            <Form.Control
              value={import_email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setImportEmail(e.target.value);
              }}
              placeholder={t("email for notification")}
              name="import_email"
            />
          </div>
        </Form.Group>
      </Modal>
      <Table size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>{t("name")}</th>
            <th>Email</th>
            <th>{t("date")}</th>
            <th>{t("update")}</th>
          </tr>
        </thead>
        <tbody>
          {logistics.map((item: any, i: number) => (
            <tr key={i}>
              <th scope="row">{item.id}</th>
              <td>
                {item.name}
              </td>
              <td>
                {item.email}
              </td>
              <td>
                {moment(item.createdAt).utc().format('DD/MM/YYYY hh:mm:ss')}
              </td>
              <td>
                <Row>
                  <Col xl={3} md={6}>
                    <DropdownButton title={t("update")}>
                      <Dropdown.Item onClick={() => deleteEntity(item)}>
                        {t("delete")}
                      </Dropdown.Item>
                    </DropdownButton>
                  </Col>
                </Row>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {count > 0 && (
        <CustomPagination
          page={active}
          count={count}
          take={pagination.take}
          onPageChange={(page) => changePage(page)}
          size="sm"
        />
      )}
    </>
  )
}
export default Logistics;

