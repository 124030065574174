import React, { createContext, useContext, useCallback, useState } from 'react';
import { v4 } from 'uuid';
import ToastContainer from '../components/ToastContainer';

export interface ToastMessage {
  id: string;
  type?: 'success' | 'error' | 'info';
  title: string;
  description?: string;
  auto_close?: boolean;
}

interface ToastData {
  addToast(message: Omit<ToastMessage, 'id'>): void;
  addToastWithId(message: ToastMessage): void;
  removeToast(id: string): void;
}

const Toast = createContext<ToastData>({} as ToastData);

const ToastProvider: React.FC = ({ children }) => {
  const [messages, setMessages] = useState<ToastMessage[]>([]);

  const addToast = useCallback(
    ({ type, title, description, auto_close }: Omit<ToastMessage, 'id'>) => {
      const id = v4();
      const toast = {
        id,
        type,
        title,
        description,
        auto_close,
      };

      setMessages((state) => [...state, toast]);
    },
    [],
  );

  const addToastWithId = useCallback((toast: ToastMessage) => {
    setMessages((state) => [...state, toast]);
  }, []);

  const removeToast = useCallback((id: string) => {
    console.log('removeToast', id);
    setMessages((state) => state.filter((message) => message.id !== id));
  }, []);

  return (
    <Toast.Provider value={{ addToast, removeToast, addToastWithId }}>
      {children}
      <ToastContainer messages={messages} />
    </Toast.Provider>
  ); 
};

function useToast(): ToastData {
  const context = useContext(Toast);

  if (!context) {
    throw new Error('UseToast must be used within a ToastProvider');
  }
  return context;
}

export { ToastProvider, useToast };
