import React, { useState, useEffect, useRef } from 'react';
import 'react-day-picker/lib/style.css';
import 'firebase/firestore';
import 'firebase/auth';
import api from '../../../services/api';
import {
  Row,
  Col,
  Table,
  Form,
  Dropdown,
  DropdownButton,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useToast } from '../../../hooks/Toast';
import firebase from 'firebase';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEvent } from '../../../hooks/EventContext';

type FormData = {
  // configs: {
  id: string;
  provider: string;
  client_id: string;
  client_secret: string;
  title: string;
  event_id: string;
  event_key: string;
  // }[];
};

const SSO: React.FC<{ event: any }> = (props: any) => {
  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    errors,
    reset,
    watch,
    control,
  } = useForm<FormData>({
    defaultValues: {
      // configs: [
      //   {
      //     // provider: 'azure_ad',
      //     // client_id: '1234',
      //     // title: 'teste',
      //     // client_secret: '4321',
      //   },
      // ],
    },
  });
  // const { fields, append, remove } = useFieldArray({
  //   control,
  //   name: 'configs',
  // });
  const { addToast } = useToast();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [deleting, setDeleting] = useState<number | null>(null);
  const [ssoConfigs, setSSOConfigs] = useState<any[]>([]);
  const { t } = useTranslation();
  const docsRef = useRef<any[]>([]);
  const [event, setEvent] = useState<any>(null);

  const onSubmit = async function (config: any) {
    try {
      setLoadingSubmit(true);

      // await Promise.all(
      //   configs.map((config: any) =>
      //     api.request({
      //       url: '/admin/sso',
      //       method: config.id ? 'PUT' : 'POST',
      //       data: { ...config, event_id: props.params.id },
      //     }),
      //   ),
      // );

      await api.request({
        url: '/admin/sso',
        method: config.id ? 'PUT' : 'POST',
        data: { ...config, event_key: event.key },
      });
      // setRegisterField({});
      reset();

      addToast({
        type: 'success',
        title: t('successfull register'),
        description: t('your info was sent successfully'),
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: t('error on register'),
        description:
          error?.response?.data?.message ||
          error.message ||
          t('an error has ocurred on register, try again.'),
      });
    }

    setLoadingSubmit(false);
  };

  useEffect(() => {
    if (props.params.id) {
      const unsubscribe = firebase
        .firestore()
        .collection('sso')
        .where('event_id', '==', props.params.id)
        // .orderBy("order", "asc")
        .onSnapshot((snapshot) => {
          console.log('sso snapshot', snapshot.docChanges());
          let changed = false;

          const docs: any[] = snapshot.docs // ?.filter((d) => d.type === 'added') // .docChanges()
            .map((m) => {
              const item = {
                ...m.data(),
                id: m.id,
              };

              // const index = docsRef?.current?.indexOf(
              //   (d: any) => d.id === item.id,
              // );

              // if (index === -1) {
              //   changed = true;
              //   docsRef?.current?.push(item);
              // }

              // if (index === -1 && docsRef?.current?.length > 1) append({});

              return item;
            });

          setSSOConfigs(
            docs?.sort((a, b) => {
              if (a.order > b.order) return 1;
              else if (a.order < b.order) return -1;
              return 1;
            }),
          );

          // if (docs.length > 0) {
          //   reset();
          //   docsRef.current = docs;
          // }

          // snapshot
          //   .docChanges()
          //   ?.filter((d) => d.type === 'removed')
          //   .forEach((m) => {
          //     const index = docsRef?.current?.findIndex(
          //       (d) => d.id === m.doc.id,
          //     );

          //     changed = true;
          //     docsRef?.current?.splice(index, 1);
          //     remove(index);
          //   });

          // // docs?.slice(1).map((item: any) => append({}));
          // if (changed) setValue('configs', docsRef.current);
        });

      api.get(`admin/event/details/${props.params.id}`).then((result: any) => {
        setEvent(result.data);
      });

      return () => {
        unsubscribe();
      };
    }
  }, [props.params.id, loadingSubmit]);

  async function edit(item: any) {
    Object.keys(item).map((key: any) => setValue(key, item[key]));
  }

  async function deleteEntity(entity: any) {
    if (window.confirm(t('are you sure that you want to delete this?'))) {
      const result = await api.delete('/admin/sso/' + entity.id);
      setLoadingSubmit(true);
    }
  }

  // async function handleDelete(index: number) {
  //   const values: any = getValues();
  //   const id: string = values[`id`];

  //   console.log('handleDelete', { values, id, index });

  //   if (id) {
  //     setDeleting(index);
  //     await api.delete('/admin/sso/' + id);
  //     setDeleting(null);
  //   }
  //   // else remove(index);
  // }

  console.log('sso render', { event });

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {/* {fields.map((field, index) => (
          <Row key={field.id}> */}
        {/* <Col xs={12}>
              {index > 0 && <hr />}
              <h4>Provedor {index + 1}</h4>
            </Col> */}

        {/* {(index > 0 || !!(watch(`configs`) || [])?.[index]?.id) && (
              <Col xs={8} className="mb-3 mt-3 text-center">
                <Button
                  loading={deleting === index}
                  htmlType="button"
                  onClick={() => handleDelete(index)}
                >
                  {t('Remover Provedor')}
                </Button>
              </Col>
            )} */}

        <Form.Control type="hidden" name={`id`} ref={register({})} />

        {/* <input name={`id`} ref={register({})} type="hidden" defaultValue="" /> */}
        {/* <input
          name={`event_key`}
          ref={register({})}
          type="hidden"
          value={event?.key}
        /> */}
        <input
          name={`event_id`}
          ref={register({})}
          type="hidden"
          value={props.params.id}
        />

        <Form.Group as={Col} md="8">
          <Form.Label htmlFor="title">{t('provider')}</Form.Label>
          <Form.Control
            name={`provider`}
            as="select"
            ref={register({ required: true })}
          >
            <option value="">
              {t('select room type')}Selecione o Provedor
            </option>
            <option value="azure_ad">{t('Azure AD')}</option>
            <option value="google">{t('Google')}</option>
          </Form.Control>
          {/* <>
                {errors[`provider`] && (
                  <span className="error">
                    {t('API access key is obligatory')}
                  </span>
                )}
              </> */}
        </Form.Group>

        <Form.Group as={Col} md="8">
          <Form.Label htmlFor="title">{t('sso_title')}</Form.Label>
          <Form.Control
            type="text"
            name={`title`}
            ref={register({ required: true })}
          />
          {/* <>
                {errors[`title`] && (
                  <span className="error">
                    {t('API access key is obligatory')}
                  </span>
                )}
              </> */}
        </Form.Group>

        <Form.Group as={Col} md="8">
          <Form.Label htmlFor="title">{t('client_id')}</Form.Label>
          <Form.Control
            type="text"
            name={`client_id`}
            ref={register({ required: true })}
          />
          {/* <>
                {errors[`client_id`] && (
                  <span className="error">
                    {t('API access key is obligatory')}
                  </span>
                )}
              </> */}
        </Form.Group>
        <Form.Group as={Col} md="8">
          <Form.Label htmlFor="title">{t('client_secret')}</Form.Label>
          <Form.Control
            type="text"
            name={`client_secret`}
            ref={register({ required: true })}
          />
          {/* <>
                {errors[`client_secret`] && (
                  <span className="error">
                    {t('API access key is obligatory')}
                  </span>
                )}
              </> */}
        </Form.Group>
        {/* </Row>
        ))} */}

        {/* <Col className="mt-3 text-center" xs={8}>
          <Button
            loading={loadingSubmit}
            htmlType="submit"
            onClick={() => append({})}
          >
            {t('Adicionar novo Provedor')}
          </Button>
        </Col> */}

        <Form.Group as={Col} md="8">
          <Form.Label htmlFor="order">{t('order')}</Form.Label>
          <Form.Control
            type="text"
            name={`order`}
            ref={register({ required: true })}
            defaultValue={1}
          />
          {/* <>
                {errors[`provider`] && (
                  <span className="error">
                    {t('API access key is obligatory')}
                  </span>
                )}
              </> */}
        </Form.Group>

        <Form.Group
          as={Col}
          xs={8}
          className="mt-3"
          style={{ textAlign: 'right' }}
        >
          <Button loading={loadingSubmit} htmlType="submit">
            {t('save')}
          </Button>
        </Form.Group>
      </Form>

      <Table size="sm" style={{ tableLayout: 'fixed' }}>
        <thead>
          <tr>
            <th style={{ width: '15%' }}>#</th>
            <th style={{ width: '30%' }}>{t('sso_title')}</th>
            <th style={{ width: '30%' }}>{t('client_id')}</th>
            <th style={{ width: '20%' }}>{t('client_secret')}</th>
            <th style={{ width: '15%' }}>{t('provider')}</th>
            <th style={{ width: '10%' }}>{t('order')}</th>
            <th style={{ width: '15%' }}>{t('update')}</th>
            {/* <th style={{ width: '20%' }} /> */}
          </tr>
        </thead>
        <tbody>
          {ssoConfigs.map((item: any, i: number) => (
            <tr key={i}>
              <th scope="row">{item.id}</th>
              <td>{item.title}</td>
              <td>{item.client_id}</td>
              <td>{item.client_secret}</td>
              <td>{item.provider}</td>
              <td>{item.order}</td>
              <td>
                <Row>
                  <Col xl={3} md={6}>
                    <DropdownButton title={t('update')}>
                      <Dropdown.Item onClick={() => edit(item)}>
                        {t('edit')}
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => deleteEntity(item)}>
                        {t('delete')}
                      </Dropdown.Item>
                    </DropdownButton>
                  </Col>
                </Row>
              </td>
              <td />
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default SSO;
