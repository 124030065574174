import React, { useEffect, useRef, useState } from 'react';
import {
  Col,
  Dropdown,
  DropdownButton,
  Form,
  Row,
  Table,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import firebase from 'firebase';

import api from '../../../../../services/api';
import { useToast } from '../../../../../hooks/Toast';
import { Button } from 'antd';
import { Spinner } from 'reactstrap';
import { useEmitter } from '../../../../../hooks/Emitter';
import { Typeahead } from 'react-bootstrap-typeahead';

type FormData = {
  index: number;
  name_languages: [];
  link_languages: [];
  order: string;
};

interface IExternalLinksTab {
  channel: any;
  speakers: any[];
}

const BiographyTab: React.FC<IExternalLinksTab> = ({ channel, speakers }) => {
  const { emitter, events } = useEmitter();
  const { addToast } = useToast();
  const view_key = 'ask';
  const [editItem, setEditItem] = useState<any>({});
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [checkedListSpeakers, setCheckedListSpeakers] = useState<any[]>([]);
  const { register, setValue, reset, handleSubmit, errors } = useForm<any>();

  useEffect(() => {
    if (speakers && !isBusy && channel.id)
      setCheckedListSpeakers(
        speakers.filter(
          (v) =>
            v.views?.findIndex(
              (v: any) => v.key === view_key && v.channelId === channel.id,
            ) >= 0,
        ),
      );
  }, [speakers, channel?.id, isBusy]);

  async function save(data: any) {
    try {
      setIsBusy(true);

      const _speakerWithViewObj: any = {};
      const _selectedSpeakerViewObj: any = {};

      const _speakersWithView = speakers.filter(
        (v) =>
          v.views?.findIndex(
            (v: any) => v.key === view_key && v.channelId === channel.id,
          ) >= 0,
      );

      _speakersWithView.map((v) => (_speakerWithViewObj[v.id] = v));
      checkedListSpeakers.map((v) => (_selectedSpeakerViewObj[v.id] = v));
      const _diff = checkedListSpeakers.filter(
        (speaker) => !_speakerWithViewObj?.[speaker.id],
      );

      const _speakersUpdated = [..._speakersWithView, ..._diff].map(
        (speaker) => {
          const _views = speaker?.views || [];

          if (_selectedSpeakerViewObj[speaker.id])
            speaker.views = Array.from(
              new Set([..._views, { key: view_key, channelId: channel.id }]),
            );
          else
            speaker.views = _views.filter(
              (view: any) =>
                !(view.key === view_key && view.channelId === channel.id),
            );

          return speaker;
        },
      );

      console.log('speakers updated', _speakersUpdated);

      for (const speaker of _speakersUpdated) {
        await saveSpeaker(speaker);
      }

      addToast({
        type: 'success',
        title: 'Pergunte ao palestrante alterado',
        description: 'Sua informação foi enviada com sucesso!',
      });
    } catch (error) {
    } finally {
      setIsBusy(false);
    }
  }

  async function saveSpeaker(speaker: any) {
    await api
      .post('/admin/speaker', speaker)
      .then((r) => {
        let title = '';
      })
      .catch((e) => {
        addToast({
          type: 'error',
          title: 'Erro no envio',
          description:
            e?.response?.data?.message ||
            e.message ||
            'Ocorreu um erro ao fazer o envio, tente novamente.',
        });
      });
  }

  const onChangeSpeakers = (checkedListSpeakers: any) => {
    console.log('onChangeSpeakers', checkedListSpeakers);
    setCheckedListSpeakers(checkedListSpeakers);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(save)}>
        <div className="form-row">
          <Form.Group as={Col} md="12">
            <Form.Label htmlFor="name">Palestrantes</Form.Label>
            <Typeahead
              id="basic-typeahead-single"
              labelKey="name"
              onChange={onChangeSpeakers}
              options={speakers}
              multiple
              emptyLabel="Nenhum palestrante encontrado."
              placeholder="Selecione os palestrantes"
              selected={checkedListSpeakers}
            />
          </Form.Group>
        </div>
        <button className="btn btn-primary" type="submit">
          {!isBusy ? (
            'Salvar Palestrantes'
          ) : (
            <Spinner className="ml-3 mr-3" size={'sm'} />
          )}
        </button>
      </form>
    </div>
  );
};

export default BiographyTab;
