import 'react-day-picker/lib/style.css';
import 'firebase/firestore';
import 'firebase/auth';

import {
  Col,
  Dropdown,
  DropdownButton,
  Row,
  Tab,
  Table,
  Tabs,
} from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import * as Enumerable from 'linq-es2015';
import Popup from '../PopUp';
import firebase from 'firebase';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ChannelForm from './components/ChannelForm';
import api from '../../../services/api';
import Schedule from '../Schedule';
import { useToast } from '../../../hooks/Toast';
import ExternalLinksTab from './components/ExternalLinksTab';
import AskTab from './components/AskTab';
import BiographyTab from './components/BiographyTab';
import CloudWordTab from './components/CloudWordTab';
import Audio from '../Audio';
import { useHistory } from 'react-router-dom';
import ChatsManager from '../../../components/ChatsManager';

const Channels: React.FC<{ event: any }> = (props: any) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [registerField, setRegisterField] = useState<any>({
    customization: {},
  });
  const { register, setValue, handleSubmit, errors, reset } = useForm<any>({});
  const { addToast } = useToast();
  const [channels, setChannels] = useState<any[]>([]);
  const [isFirstChannel, setIsFirstChannel] = useState(false);
  const [speakers, setSpeakers] = useState<any[]>([]);
  const [checkedListSpeakers, setCheckedListSpeakers] = useState<any[]>([]);
  const [resources, setResources] = useState<any>({});
  const [checkedList, setCheckedList] = useState<any[]>([]);
  const [urlImageCover, setUrlImageCover] = useState<string>('');
  const [saveVideoCover, setSaveVideoCover] = useState<string>('');
  function edit(entity: any) {
    setRegisterField(entity);
    setUrlImageCover(entity?.videoUrl || '');
  }

  async function deleteEntity(entity: any) {
    if (window.confirm(t("are you sure that you want to delete this?"))) {
      const result = await api.delete(`/admin/channel/${entity.id}`);
    }
  }

  useEffect(() => {
    reset();
    setRegisterField({ customization: {} });
    setSaveVideoCover('');

    if (props.event.id) {
      const unsubscribe = firebase
        .firestore()
        .collection('channel')
        // .orderBy('customization.channel_group')
        .where('eventId', '==', props.event.id)
        .onSnapshot((snapshot) => {
          const docs = snapshot.docs.map((m) => m.data());

          if (docs.length === 0) setIsFirstChannel(true);

          setChannels(
            Enumerable.asEnumerable(docs)
              .OrderBy((d) => d.customization?.channel_group)
              .ThenBy((d) => d.name)
              .ToArray(),
          );
        });

      return () => {
        unsubscribe();
      };
    }
  }, [props.params.id, props.event.id]);

  useEffect(() => {
    if (!resources.list) {
      const data = firebase
        .firestore()
        .collection('settings')
        .doc('resources')
        .get()
        .then((doc) => {
          setResources(doc.data());
        });
    }
  }, [props.params.id]);

  useEffect(() => {
    if (props.params.id) {
      const unsubscribe = firebase
        .firestore()
        .collection('speaker')
        .where('eventId', '==', props.params.id)
        .onSnapshot((snapshot) => {
          const docs = snapshot.docs.map((m) => m.data());
          setSpeakers(docs);
        });

      return () => {
        unsubscribe();
      };
    }
  }, [props.params.id]);

  const channelsObj: any = {};
  channels.map((c: any) => {
    return (channelsObj[c.id] = c);
  });

  function handleSaveChannelSuccess() {
    setRegisterField({
      customization: {},
    });
    setSaveVideoCover('');
  }

  function orderChannels(channels?: any[]) {
    if (!channels) return [];
  }

  return (
    <>
      <Tabs
        defaultActiveKey="channel"
        onSelect={(e) => history.push(`${history.location.hash}#${e}`)}
        className="mb-3"
      >
        <Tab eventKey="channel" title={t("room")}>
          <h6>{t("room")}</h6>
          <hr />
          <ChannelForm
            channels={channels}
            saveVideoCover={saveVideoCover}
            speakers={speakers}
            checkedListSpeakers={checkedListSpeakers}
            urlImageCover={urlImageCover}
            resources={resources}
            isFirstChannel={isFirstChannel}
            event={props.event}
            registerField={channelsObj[registerField.id] || registerField}
            onSuccess={handleSaveChannelSuccess}
          />
          <Table size="sm" style={{ tableLayout: 'fixed' }}>
            <thead>
              <tr>
                <th style={{ width: '15%' }}>#</th>
                <th style={{ width: '30%' }}>{t("name")}</th>
                <th style={{ width: '20%' }}>Url</th>
                <th style={{ width: '15%' }}>{t("group")}</th>
                <th style={{ width: '10%' }}>{t("order")}</th>
                <th style={{ width: '15%' }}>{t("update")}</th>
                {/* <th style={{ width: '20%' }} /> */}
              </tr>
            </thead>
            <tbody>
              {channels.map((item: any, i: number) => (
                <tr key={i}>
                  <th scope="row">{item.id}</th>
                  <td>{(item.name_languages || {})[i18n.language]}</td>
                  <td>{item.key}</td>
                  <td>{item.customization?.channel_group}</td>
                  <td>{item.order}</td>
                  <td>
                    <Row>
                      <Col xl={3} md={6}>
                        <DropdownButton title={t("update")}>
                          <Dropdown.Item onClick={() => edit(item)}>
                            {t("edit")}
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => deleteEntity(item)}>
                            {t("delete")}
                          </Dropdown.Item>
                        </DropdownButton>
                      </Col>
                    </Row>
                  </td>
                  <td />
                </tr>
              ))}
            </tbody>
          </Table>
        </Tab>
        {registerField?.id &&
          registerField.resources?.find((r: any) => r.key === 'schedule') && (
            <Tab eventKey="schedule" title={t("schedule")}>
              <h6>{t("add to schedule")}</h6>
              <hr />
              <Schedule {...props} channel={registerField} />
            </Tab>
          )}
        {registerField?.id &&
          registerField.resources?.find(
            (r: any) => r.key === 'externallinks',
          ) && (
            <Tab eventKey="externallinks" title={t("external links")}>
              <h6>{t("add external links")}</h6>
              <hr />
              <ExternalLinksTab
                {...props}
                channel={channelsObj[registerField.id]}
              />
            </Tab>
          )}
        {registerField?.id &&
          registerField.resources?.find((r: any) => r.key === 'chat') && (
            <Tab eventKey="chat" title="Chats">
              <h6>Chats</h6>
              <hr />
              <ChatsManager event={props.event} channel={registerField} />
              {/* <ExternalLinksTab
                {...props}
                channel={channelsObj[registerField.id]}
              /> */}
            </Tab>
          )}
        {registerField?.id &&
          registerField.resources?.find((r: any) => r.key === 'ask') && (
            <Tab eventKey="ask" title={t("ask to speaker")}>
              <h6>{t("ask to speaker")}</h6>
              <hr />
              <AskTab speakers={speakers} channel={registerField} />
              {/* <ExternalLinksTab
                {...props}
                channel={channelsObj[registerField.id]}
              /> */}
            </Tab>
          )}
        {registerField?.id &&
          registerField.resources?.find((r: any) => r.key === 'biography') && (
            <Tab eventKey="biogray" title={t("biography")}>
              <h6>{t("biography")}</h6>
              <hr />
              <BiographyTab speakers={speakers} channel={registerField} />
              {/* <ExternalLinksTab
                {...props}
                channel={channelsObj[registerField.id]}
              /> */}
            </Tab>
          )}
        {registerField?.id &&
          registerField.resources?.find((r: any) => r.key === 'cloudword') && (
            <Tab eventKey="cloudword" title={t("cloud words")}>
              <h6>{t("cloud words")}</h6>
              <hr />
              <CloudWordTab channel={registerField} />
              {/* <ExternalLinksTab
                {...props}
                channel={channelsObj[registerField.id]}
              /> */}
            </Tab>
          )}

        {registerField?.id &&
          registerField.resources?.find(
            (r: any) => r.key === 'popupsettings',
          ) && (
            <Tab eventKey="popup" title={t("pop up alert")}>
              <h6>{t("pop up alert")}</h6>
              <hr />
              <Popup {...props} channel={registerField} />
            </Tab>
          )}

        {registerField?.id && (
          <Tab eventKey="voicesettings" title="Audio Settings">
            <h6>Audio Settings</h6>
            <hr />
            <Audio
              {...props}
              customization={registerField.customization}
              videoUrl={registerField.videoUrl}
              setSaveVideoCover={setSaveVideoCover}
              urlImageCover={urlImageCover}
              setUrlImageCover={setUrlImageCover}
              channel={channelsObj[registerField.id]}
            />
          </Tab>
        )}
      </Tabs>
    </>
  );
};

export default Channels;
