import React, { useState, useCallback, useEffect, useMemo } from 'react';
import 'react-day-picker/lib/style.css';
import 'firebase/firestore';
import 'firebase/auth';
import firebase from 'firebase';
import { useForm } from 'react-hook-form';
import { Container } from 'react-bootstrap';

import DashboardMasterPage from '../../masterpages/DashboardMasterPage';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import TimelinesManager from '../../components/TimelinesManager';
import api from '../../services/api';

const Timelines: React.FC = (props) => {
  return (
    <DashboardMasterPage {...props}>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Timelines"
              breadcrumbItems={[
                { title: 'Admin', link: '#' },
                { title: 'Timelines', link: '#' },
              ]}
            />
            <TimelinesManager />
          </Container>
        </div>
      </React.Fragment>
    </DashboardMasterPage>
  );
};

export default Timelines;
