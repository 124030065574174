import React, { useContext, useEffect } from 'react';
import { Container, AnimationContainer, SidebarContainer } from './styles';
import { FiHome, FiCamera, FiLogOut } from 'react-icons/fi';
import Header from '../../webparts/Header';
import Sidebar from '../../webparts/Sidebar';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/Auth2';
import VerticalLayout from '../../layouts/VerticalLayout';
import HorizontalLayout from '../../layouts/HorizontalLayout';

const DashboardMasterPage: React.FC = (props: any) => {
  const { signOut, user } = useAuth();

  /**
   * Returns the layout
   */
  const getLayout = () => {
    let layoutCls: any = VerticalLayout;

    switch (props.layout?.layoutType) {
      case 'horizontal':
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  const Layout = getLayout();

  return <Layout>{props.children}</Layout>;
};

export default DashboardMasterPage;
