import React, { useState, useEffect } from 'react';
import 'react-day-picker/lib/style.css';
import 'firebase/firestore';
import 'firebase/auth';
import api from '../../../services/api';
import {
  Row,
  Col,
  Table,
  Form,
  Dropdown,
  DropdownButton,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useToast } from '../../../hooks/Toast';
import firebase from 'firebase';
import { v4 } from 'uuid';
import { Checkbox, Button } from 'antd';
import './index.css';
import { useTranslation } from 'react-i18next';

const CheckboxGroup = Checkbox.Group;

type FormData = {
  order?: number;
  name?: string;
  facebook?: string;
  linkedin?: string;
  twitter?: string;
  instagram?: string;
  crm?: string;
  profession?: { [lang: string]: string };
  description?: { [lang: string]: string };
  cover?: string;
  link?: string;
};

const Sponsors: React.FC<{ event: any }> = (props: any) => {
  const [registerField, setRegisterField] = useState<FormData>({});
  const { register, setValue, handleSubmit, errors, reset, watch } = useForm<
    FormData
  >({
    defaultValues: {
      order: 1,
      name: '',
      facebook: '',
      linkedin: '',
      twitter: '',
      instagram: '',
      link: '',
    },
  });
  const { addToast } = useToast();
  const [sponsors, setSponsors] = useState<any[]>([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { t } = useTranslation();

  const onSubmit = async function ({ description, ...data }: any) {
    try {
      setLoadingSubmit(true);

      const register = { ...registerField, ...data };
      register.eventId = props.event.id;
      register.cover =
        (await upload(data.cover, props.event.key)) || registerField.cover;

      console.log('onSubmit', { registerField, data, register });

      const result = await api.post('/admin/sponsor', register);

      setRegisterField({});
      reset();

      addToast({
        type: 'success',
        title: t("successfull register"),
        description: t("your info was sent successfully"),
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: t("error on register"),
        description:
          error?.response?.data?.message ||
          error.message ||
          t("an error has ocurred on register, try again."),
      });
    }

    setLoadingSubmit(false);
  };

  async function upload(inputFile: any, eventKey: string) {
    if (typeof inputFile !== 'string' && inputFile.length) {
      const uploaded: any = await firebase
        .storage()
        .ref(`/sponsors/${eventKey}/${v4()}`)
        .put(inputFile[0]);
      return (await uploaded.ref.getDownloadURL()) || '';
    }

    return '';
  }

  function edit(entity: any) {
    console.log('edit item', entity);

    setRegisterField(entity);

    for (const key in entity) {
      if (entity.hasOwnProperty(key)) {
        const element = entity[key];
        try {
          if (key != 'resources' && key != 'cover') {
            setValue(key, element);
          }
          //  else if (key == 'description') {
          //   Object.keys(entity).map((lang) =>
          //     setValue(`${key}[${lang}]`, entity[lang]),
          //   );
          // }
        } catch (error) { }
      }
    }
  }

  async function deleteEntity(entity: any) {
    if (window.confirm(t("are you sure that you want to delete this?"))) {
      const result = await api.delete('/admin/sponsor/' + entity.id);
    }
  }

  useEffect(() => {
    reset();
    setRegisterField({});

    if (props.event.id) {
      const unsubscribe = firebase
        .firestore()
        .collection('sponsor')
        .where('eventId', '==', props.event.id)
        .onSnapshot((snapshot) => {
          const docs = snapshot.docs.map((m) => m.data());
          const spe = docs.sort(function (a: any, b: any) {
            return a.order - b.order;
          });
          setSponsors(spe);
        });

      return () => {
        unsubscribe();
      };
    }
  }, [props.params.id, props.event.id]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Row>
          <Form.Group as={Col} md="4">
            <Form.Label htmlFor="cover">{t("image")}</Form.Label>
            <div className="custom-file">
              <Form.File label={t("upload file")} name="cover" ref={register} />
            </div>
            <a href={registerField.cover} target="_blank">
              <img src={registerField.cover} style={{ height: '50px' }} />
            </a>
          </Form.Group>

          <div className="col-md-8"></div>

          <Form.Group as={Col} md="2">
            <Form.Label htmlFor="order">{t("order")}</Form.Label>
            <Form.Control
              defaultValue={registerField.order || 1}
              type="number"
              name="order"
              placeholder="Ordem"
              autoComplete="off"
              ref={register({ required: true })}
            />
            <>
              {errors[`order`] && (
                <span className="error">{t("order is obligatory")}</span>
              )}
            </>
          </Form.Group>

          <Form.Group as={Col} md="10">
            <Form.Label htmlFor="name">{t("name")}</Form.Label>
            <Form.Control
              type="text"
              name="name"
              autoComplete="off"
              ref={register({ required: true })}
            />
            <>
              {errors[`name`] && (
                <span className="error">{t("name is obligatory")}</span>
              )}
            </>
          </Form.Group>

          <Form.Group as={Col} md="4">
            <Form.Label htmlFor="facebook">Facebook</Form.Label>
            <Form.Control
              type="text"
              name="facebook"
              autoComplete="off"
              ref={register({})}
            />
          </Form.Group>

          <Form.Group as={Col} md="4">
            <Form.Label htmlFor="twitter">Twitter</Form.Label>
            <Form.Control
              type="text"
              name="twitter"
              autoComplete="off"
              ref={register({})}
            />
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label htmlFor="instagram">Instagram</Form.Label>
            <Form.Control
              type="text"
              name="instagram"
              autoComplete="off"
              ref={register({})}
            />
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label htmlFor="linkedin">LinkedIn</Form.Label>
            <Form.Control
              type="text"
              name="linkedin"
              autoComplete="off"
              ref={register({})}
            />
          </Form.Group>
        </Form.Row>
        <Form.Group
          as={Col}
          sm={12}
          className="mt-3"
          style={{ textAlign: 'right' }}
        >
          <Button loading={loadingSubmit} htmlType="submit">
            {t("save")}
          </Button>
        </Form.Group>
      </Form>

      <Table size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>{t("image")}</th>
            <th>{t("name")}</th>
            <th>{t("order")}</th>
            <th>{t("update")}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sponsors.map((item: any, i: number) => (
            <tr key={i}>
              <th scope="row">{i + 1}</th>
              <td>
                {item.cover && <img style={{ height: 100 }} src={item.cover} />}
              </td>
              <td>{item.name}</td>
              <td>{item.order}</td>
              <td>
                <Row>
                  <Col xl={3} md={6}>
                    <DropdownButton title={t("update")}>
                      <Dropdown.Item onClick={() => edit(item)}>
                        {t("edit")}
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => deleteEntity(item)}>
                        {t("delete")}
                      </Dropdown.Item>
                    </DropdownButton>
                  </Col>
                </Row>
              </td>
              <td></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default Sponsors;
