import 'react-day-picker/lib/style.css';
import 'firebase/firestore';
import 'firebase/auth';

import { Button, Checkbox } from 'antd';
import { Card, Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import ImageParticipant from '../ImageParticipant';
import Channels from '../Channels';
import ColorPicker from 'rc-color-picker';
import DashboardMasterPage from '../../../masterpages/DashboardMasterPage';
import LP from '../LP';
import Participants from '../Participants';
import Reports from '../Reports';
import MoreInfo from '../MoreInfo';
import Sponsors from '../Sponsors';
import Speakers from '../Speakers';
import Util from '../../../utils/util';
import Agenda from '../Agenda';
import api from '../../../services/api';
import { asEnumerable } from 'linq-es2015';
import firebase from 'firebase';
import { useAuth } from '../../../hooks/Auth2';
import { useForm } from 'react-hook-form';
import { useToast } from '../../../hooks/Toast';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { ILanguage } from '../../../interfaces/Languages';
import Partners from '../Partners';
import Feed from '../Feed';
import Contents from '../Contents';
import Products from '../Products';
import Tips from '../Tips';
import Logistics from '../Logistics';
import Sympla from '../Sympla';
import ParticipantsRsvp from '../ParticipantsRsvp';
import SSO from '../SSO';
import { Container } from './styles';
import { AppUtils } from '../../../utils/app';

const plainOptions = ['pt', 'en', 'es'];
const CheckboxGroup = Checkbox.Group;

const EventDetails: React.FC = (props: any) => {
  const keyRef = useRef<HTMLInputElement>();
  const { t, i18n } = useTranslation();
  const { user } = useAuth();
  const [entity, setEntity] = useState<any>({});
  const [registerField, setRegisterField] = useState<any>({});
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    errors,
    reset,
    setError,
  } = useForm<any>(entity);
  const history = useHistory();
  const location = useLocation();
  const { addToast } = useToast();
  const [agencies, setAgencies] = useState<any[]>([]);
  const [companies, setCompanies] = useState<any[]>([]);
  const [showElement, setShowElement] = useState<any>({});
  const [checkedList, setCheckedList] = useState(
    entity?.settings?.languages || [],
  );
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [languages, setLanguages] = useState<ILanguage[]>([]);

  const [resources, setResources] = useState<any>({});
  const [checkedListResources, setCheckedListResources] = useState<any[]>([]);
  const [indeterminateResources, setIndeterminateResources] = useState(true);
  const [checkAllResources, setCheckAllResources] = useState(false);
  const [defaultLang, setDefaultLang] = useState('pt');

  const onChangeResources = (checkedListResources: any) => {
    setCheckedListResources(checkedListResources);
    setIndeterminateResources(
      !!checkedListResources.length &&
        checkedListResources.length < resources.list?.length,
    );
    setCheckAllResources(
      checkedListResources.length === resources.list?.length,
    );
  };

  const onCheckAllChangeResources = (e: any) => {
    setCheckedListResources(e.target.checked ? resources.list : []);
    setIndeterminateResources(false);
    setCheckAllResources(e.target.checked);
  };

  // const languages = [
  //   {
  //     language: 'pt',
  //     default: true,
  //     label: 'Português',
  //     flag:
  //       'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/images%2Fflag-pt.png?alt=media&token=46b1969a-f7f5-45a0-8c64-fe16d876d312',
  //   },
  //   {
  //     language: 'en',
  //     default: false,
  //     label: 'English',
  //     flag:
  //       'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/images%2Fflag-en.png?alt=media&token=267af906-9e9d-4d35-bcd7-6e82a8fa6845',
  //   },
  //   {
  //     language: 'es',
  //     default: false,
  //     label: 'Spanish',
  //     flag:
  //       'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/images%2Fflag-es.png?alt=media&token=746b91f6-a5c2-4a4d-b49d-47c58c504bad',
  //   },
  //   {
  //     language: 'wd',
  //     default: false,
  //     label: 'World',
  //     flag:
  //       'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/images%2Fflag-wd.png?alt=media&token=bb7d5c71-2407-4d4c-bedd-cd7acad657df',
  //   },
  // ];
  const arrStatus = [
    {
      name: 'Ativo',
      active: true,
    },
    {
      name: 'Inativo',
      active: false,
    },
  ];
  const onChange = (checkedList: any) => {
    setCheckedList(checkedList);
    setIndeterminate(
      !!checkedList.length && checkedList.length < plainOptions.length,
    );
    setCheckAll(checkedList.length === plainOptions.length);
  };

  const onCheckAllChange = (e: any) => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  async function upload(inputFile: any, eventKey: string) {
    if (inputFile && inputFile.length) {
      const uploaded: any = await firebase
        .storage()
        .ref(`/companies/${eventKey}/customization/${v4()}`)
        .put(inputFile[0]);
      return (await uploaded.ref.getDownloadURL()) || '';
    }

    return '';
  }

  function getBackground(background: string): string {
    if (background) {
      return `url('${background}')`;
    }

    return '';
  }

  function changeColor(prop: string, color: string) {
    if (!entity.customization) {
      entity.customization = {};
    }
    entity.customization = { ...entity.customization, [prop]: color };
    setEntity({ ...entity });
  }

  async function validateEventKey() {
    try {
      if (!watch('key')) return;

      const _response = await api.get(`/admin/event/exists/${watch('key')}`);

      if (_response.data.id && _response.data.id !== entity.id) {
        setError('key', 'unique_error', 'Essa key já esta em uso.');
        // register['key']?.focus();
        // register['key']?.target?.focus();
        return _response.data;
      } else {
        setError('key', 'unique_error', undefined);
        return null;
      }
    } catch (error) {}
  }

  const onSubmit = handleSubmit(async (data: any) => {
    // data.clientName = companies[companies?.findIndex(e=> e.id === entity.companyId)]?.name
    try {
      setLoadingSubmit(true);

      const _checkKey = await validateEventKey();

      if (_checkKey?.id) {
        keyRef.current?.focus();
        return;
      }

      let register = data;

      register.logo = await upload(register.logo, register.key);
      register.favicon = await upload(register.favicon, register.key);
      register.bannerlp = await upload(register.bannerlp, register.key);
      register.banner = await upload(register.banner, register.key);
      register.bannerlobby = await upload(register.bannerlobby, register.key);
      register.backgroundImg = await upload(
        register.backgroundImg,
        register.key,
      );
      register.background = await upload(register.background, register.key);
      register.backgroundInternal = await upload(
        register.backgroundInternal,
        register.key,
      );
      register.backgroundMobile = await upload(
        register.backgroundMobile,
        register.key,
      );
      register.footerBackground = await upload(
        register.footerBackground,
        register.key,
      );
      register.partners = await upload(register.partners, register.key);
      register.customChannelHeaderImg = await upload(
        register.customChannelHeaderImg,
        register.key,
      );
      register.infoImage = await upload(register.infoImage, register.key);
      register.sideBarBackground = await upload(
        register.sideBarBackground,
        register.key,
      );

      register.customization = {
        ...entity.customization,
        ...{
          background:
            getBackground(register.background) ||
            entity.customization?.background ||
            '',
          partners: register.partners || entity.customization?.partners || '',
          customChannelHeaderImg:
            register.customChannelHeaderImg ||
            entity.customization?.customChannelHeaderImg ||
            '',
          backgroundMobile:
            getBackground(register.backgroundMobile) ||
            entity.customization?.backgroundMobile ||
            '',
          backgroundInternal:
            getBackground(register.backgroundInternal) ||
            entity.customization?.backgroundInternal ||
            '',
          sideBarBackground:
            getBackground(register.sideBarBackground) ||
            entity.customization?.sideBarBackground ||
            'transparent',
          navbarBackground:
            register.navbarBackground ||
            entity.customization?.navbarBackground ||
            '',
          logo: register.logo || entity.customization?.logo || '',
          banner: register.banner || entity.customization?.banner || '',
          bannerlp: register.bannerlp || entity.customization?.bannerlp || '',
          favicon: register.favicon || entity.customization?.favicon || '',
          bannerlobby:
            register.bannerlobby || entity.customization?.bannerlobby || '',
          backgroundImg:
            register.backgroundImg || entity.customization?.backgroundImg || '',
          infoImage:
            register.infoImage || entity.customization?.infoImage || '',
          footerBackground:
            register.footerBackground ||
            entity.customization?.footerBackground ||
            '',
        },
      };

      register = Util.merge(entity, register);
      register.custom = register.custom || {};
      // clean register
      for (let name in register.customization) {
        if (register[name]) {
          register.custom[name] = register[name];
        }
      }

      // for (const key in register) {
      //   if (Object.prototype.hasOwnProperty.call(register, key)) {
      //     if (key.indexOf(`_languages`) > -1) {
      //       register[key] = entity[key] || {};
      //       register[key][i18n.language] = data[key][i18n.language];
      //     }
      //   }
      // }

      if (register.password != '') {
        register.settings.password = register.password;
      } else {
        delete register.settings.password;
      }
      const preSave = { ...entity, ...register };
      preSave.settings.languages = asEnumerable(languages)
        .Where((a) => asEnumerable(checkedList).Any((c) => c == a.language))
        .ToArray()
        .map((lang) => ({ ...lang, default: lang.language === defaultLang }));
      preSave.resources = asEnumerable(resources.list)
        .Where((l: any) => checkedListResources.indexOf(l.name) > -1)
        .ToArray();
      preSave.name = preSave.name_languages['pt'];
      preSave.active = JSON.parse(preSave.active); // converte string to boolean
      // preSave.clientName = companies[companies?.findIndex(e=> e.id === entity.companyId)]?.name
      // preSave.lp.clientName = companies[companies?.findIndex(e=> e.id === entity.companyId)]?.name
      const result = await api.post('/admin/event', preSave);

      if (!props.params.id && result.data?.id) {
        history.replace(`/event/${result.data?.id}`);
      } else {
        setEntity(result.data);
      }

      addToast({
        type: 'success',
        title: t('successfull register'),
        description: t('your info was sent successfully'),
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: t('error on register'),
        description:
          error?.response?.data?.message ||
          error.message ||
          t('an error has ocurred on register, try again.'),
      });
    } finally {
      setLoadingSubmit(false);
    }
  });

  useEffect(() => {
    console.log('**** useEffect props.params.id ****', props.params.id);
    reset();

    if (props.params.id) {
      api.get(`admin/event/details/${props.params.id}`).then((result: any) => {
        result.data.customization = result.data.customization || {};
        setEntity(result.data);
        setCheckedList(
          result.data?.settings?.languages?.map((a: any) => a.language) || [
            'pt',
          ],
        );
        setCheckedListResources(
          result.data?.resources?.map((a: any) => a.name) || [],
        );
      });
    } else {
      api.get(`admin/event/default`).then((result: any) => {
        result.data.customization = result.data.customization || {};
        setEntity(result.data);
        setCheckedList(['pt']);
      });
    }

    firebase
      .firestore()
      .collection('company')
      .get()
      .then((snapshot) => {
        setCompanies(
          snapshot?.docs
            ?.map((a) => a.data())
            ?.filter((c) => !user?.agencyId || c.agencyId === user.agencyId) ||
            [],
        );
      })
      .catch((e) => console.log('não foi possivel carregar as empresas', e));

    api.get('/admin/agency/all').then((r) => setAgencies(r.data));

    firebase
      .firestore()
      .collection('language')
      .get()
      .then((snapshot) => {
        setLanguages(
          snapshot?.docs?.map<ILanguage>(
            (a) => ({ id: a.id, ...a.data() } as ILanguage),
          ) || [],
        );
      });

    return () => {};
  }, [props.params.id]);

  useEffect(() => {
    if (entity?.id && companies.length && agencies.length) {
      const _keys = Object.keys(entity);

      console.log('useEffect', { entity, _keys, settings: entity?.settings });

      _keys.map((key) => setValue(key, entity[key]));

      if (entity.settings?.languages) {
        const [defaultLang] = entity.settings.languages.filter(
          (s: any) => s.default,
        );

        if (defaultLang?.language) setDefaultLang(defaultLang.language);
      }
    }
  }, [entity.id, companies, agencies]);

  useEffect(() => {
    if (!resources.list) {
      const data = firebase
        .firestore()
        .collection('settings')
        .doc('resources')
        .get()
        .then((doc) => {
          setResources(doc.data());
        });
    }
  }, [props.params.id]);

  function sendToProduction() {
    api
      .post(`/admin/event/production/${entity.id}`)
      .then((result: any) => {
        addToast({
          type: 'success',
          title: t('successfull register'),
          description: t('your info was sent successfully'),
        });
      })
      .catch((error) => {
        addToast({
          type: 'error',
          title: t('error on register'),
          description:
            error?.response?.data?.message ||
            error.message ||
            t('an error has ocurred on register, try again.'),
        });
      });
  }

  const _idiomas = watch();

  console.log('render', { settings: watch('settings') });

  return (
    <DashboardMasterPage {...props}>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={t('new event')}
              breadcrumbItems={[
                { title: 'Admin', link: '#' },
                { title: 'Dashboard', link: '#' },
              ]}
            />
            <Row>
              <Col sm={12} className="btn-page">
                <Card className="card-body">
                  <Card.Body>
                    {user?.moderator === 'yes' ? (
                      <Tabs
                        defaultActiveKey={
                          user?.moderator === 'yes' ? 'participants' : 'event'
                        }
                        className="mb-3"
                        activeKey={
                          location.hash && entity.id
                            ? location.hash.substr(1).split('#')[0]
                            : 'participants'
                        }
                        onSelect={(s) => {
                          history.push(`#${s}`);
                        }}
                      >
                        <Tab
                          disabled={!entity.id}
                          eventKey="participants"
                          title="RSVP"
                        >
                          <ParticipantsRsvp
                            {...props}
                            event={entity}
                          ></ParticipantsRsvp>
                        </Tab>
                      </Tabs>
                    ) : (
                      <>
                        <Tabs
                          className="mb-3"
                          activeKey={
                            location.hash && entity.id
                              ? location.hash.substr(1).split('#')[0]
                              : 'event'
                          }
                          onSelect={(s) => {
                            history.push(`#${s}`);
                          }}
                        >
                          <Tab eventKey="event" title={t('event data')}>
                            <div className="event-data-header">
                              <div>
                                <h5>{t('event data')}</h5>
                              </div>
                              {!AppUtils.isProduction() && (
                                <div className="publish-production">
                                  <Button
                                    type="primary"
                                    htmlType="button"
                                    onClick={sendToProduction}
                                  >
                                    Publicar em produção
                                  </Button>
                                </div>
                              )}
                            </div>
                            <hr className="mt-2" />
                            <Form
                              onSubmit={onSubmit}
                              onError={(e) => console.log('Form error', e)}
                            >
                              <Form.Row>
                                <Form.Group as={Col} md="4">
                                  <Form.Label htmlFor="name">
                                    {t('idioms')}
                                  </Form.Label>
                                  <div className="site-checkbox-all-wrapper">
                                    <Checkbox
                                      indeterminate={indeterminate}
                                      onChange={onCheckAllChange}
                                      checked={checkAll}
                                    >
                                      {t('check all')}
                                    </Checkbox>
                                  </div>
                                  <br />
                                  <CheckboxGroup
                                    options={languages.map((l) => l.language)}
                                    value={checkedList}
                                    onChange={onChange}
                                  />
                                </Form.Group>

                                <Form.Group as={Col} md="4">
                                  <Form.Label htmlFor="defaultLang">
                                    {t('pattern idiom')}
                                  </Form.Label>
                                  <Form.Control
                                    name="defaultLang"
                                    as="select"
                                    value={defaultLang}
                                    onChange={(e) => {
                                      setDefaultLang(e.target.value);
                                    }}
                                  >
                                    <option value="">
                                      {t('please select an idiom')}
                                    </option>
                                    {checkedList.map((a: any, i: number) => (
                                      <option key={i} value={a}>
                                        {a.toUpperCase()}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Form.Group>

                                <Form.Group as={Col} md="4">
                                  <Form.Label htmlFor="active">
                                    Status
                                  </Form.Label>
                                  <Form.Control
                                    name="active"
                                    as="select"
                                    // value={entity?.active}
                                    onChange={(e) => {
                                      setEntity({
                                        ...entity,
                                        active: e.target.value,
                                      });
                                    }}
                                    ref={register({ required: true })}
                                  >
                                    <option value="">
                                      {t('event status')}Status do Evento
                                    </option>
                                    {arrStatus.map((a: any, i: number) => (
                                      <option key={i} value={a.active}>
                                        {a.name}
                                      </option>
                                    ))}
                                  </Form.Control>
                                  <>
                                    {errors[`active`] && (
                                      <span className="error">
                                        {t('status is obligatory')}
                                      </span>
                                    )}
                                  </>
                                </Form.Group>
                                <Form.Group as={Col} md="6">
                                  <Form.Label htmlFor="company">
                                    {t('client')}
                                  </Form.Label>
                                  <Form.Control
                                    name="companyId"
                                    as="select"
                                    // value={entity.companyId}
                                    // onChange={(e) => {
                                    //   console.log('change', e.target.value);
                                    //   setEntity({
                                    //     ...entity,
                                    //     companyId: e.target.value,
                                    //   });
                                    // }}
                                    ref={register({ required: true })}
                                  >
                                    <option value="">
                                      {t('please select a client')}
                                    </option>
                                    {companies.map((a: any, i: number) => (
                                      <option key={i} value={a.id}>
                                        {a.name}
                                      </option>
                                    ))}
                                  </Form.Control>
                                  <>
                                    {errors[`company`] && (
                                      <span className="error">
                                        {t('company is obligatory')}
                                      </span>
                                    )}
                                  </>
                                </Form.Group>
                                <Form.Group as={Col} md="6">
                                  <Form.Label htmlFor="agencyId">
                                    {t('agency')}
                                  </Form.Label>
                                  <Form.Control
                                    name="agencyId"
                                    as="select"
                                    // disabled={!!user?.agencyId}
                                    // value={user?.agencyId || entity.agencyId}
                                    // onChange={(e) => {
                                    //   setEntity({
                                    //     ...entity,
                                    //     agencyId: e.target.value,
                                    //   });
                                    // }}
                                    ref={register()}
                                  >
                                    <option value="">
                                      {t('please select an agency')}
                                    </option>
                                    {agencies.map((a: any, i: number) => (
                                      <option key={i} value={a.id}>
                                        {a.name}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Form.Group>

                                <Form.Group as={Col} md="6">
                                  <Form.Label htmlFor="pagestart">
                                    {t('initial page')}
                                  </Form.Label>
                                  <Form.Control
                                    name="pagestart"
                                    as="select"
                                    // value={entity.pagestart}
                                    // onChange={(e) => {
                                    //   console.log(
                                    //     'change pagestart',
                                    //     e.target.value,
                                    //   );

                                    //   if (e.target.value)
                                    //     setEntity({
                                    //       ...entity,
                                    //       pagestart: e.target.value,
                                    //     });
                                    // }}
                                    ref={register({ required: true })}
                                  >
                                    <option value="">
                                      {t('please select an initial page')}
                                    </option>
                                    <option value="lobby">Lobby</option>
                                    <option value="dashboard/plenaria">
                                      Plenária
                                    </option>
                                    <option value="plenaria-virtual">
                                      Plenária Virtual
                                    </option>
                                    <option value="dashboard/feeds">
                                      Noticias
                                    </option>
                                  </Form.Control>
                                  <>
                                    {errors[`pagestart`] && (
                                      <span className="error">
                                        {t('initial page is obligatory')}
                                      </span>
                                    )}
                                  </>
                                </Form.Group>

                                <Form.Group as={Col} md="6">
                                  <Form.Label htmlFor="eventdatelp">
                                    {t('lp data')}
                                  </Form.Label>
                                  <InputMask
                                    mask="99-99-9999 99:99"
                                    name="eventdatelp"
                                    value={entity.eventdatelp}
                                    onChange={(e) => {
                                      console.log(
                                        'eventdatelp',
                                        e.target.value,
                                      );
                                      setEntity({
                                        ...entity,
                                        eventdatelp: e.target.value,
                                      });
                                    }}
                                  >
                                    {(inputProps: any) => (
                                      <Form.Control
                                        // ref={register({ required: false })}
                                        type="text"
                                        autoComplete="off"
                                        value={inputProps.value}
                                        name={inputProps.name}
                                        {...inputProps}
                                      />
                                    )}
                                  </InputMask>
                                  {/* <Form.Control
                                type="number"
                                name="eventdatelp"
                                defaultValue={entity?.eventdatelp}
                                ref={register({})}
                              /> */}
                                  <>
                                    {errors[`eventdatelp`] && (
                                      <span className="error">
                                        {t('lp data is obligatory')}
                                      </span>
                                    )}
                                  </>
                                </Form.Group>

                                <Form.Group as={Col} md="6">
                                  <Form.Label htmlFor="eventdate">
                                    {t('event date')}
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="eventdate"
                                    defaultValue={entity?.eventdate}
                                    ref={register({ required: true })}
                                  />
                                  <>
                                    {errors[`eventdate`] && (
                                      <span className="error">
                                        {t('event data is obligatory')}
                                      </span>
                                    )}
                                  </>
                                </Form.Group>

                                <Form.Group as={Col} md="6">
                                  <Form.Label htmlFor="eventhour">
                                    {t('event hour')}
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="eventhour"
                                    defaultValue={entity?.eventhour}
                                    ref={register({ required: true })}
                                  />
                                  <>
                                    {errors[`eventhour`] && (
                                      <span className="error">
                                        {t('event hour is obligatory')}
                                      </span>
                                    )}
                                  </>
                                </Form.Group>

                                {i18n.languages.map(
                                  (lang: string) =>
                                    i18n.language == lang && (
                                      <Form.Group key={lang} as={Col} md="6">
                                        <Form.Label
                                          htmlFor={`name_languages[${lang}]`}
                                        >
                                          {t('event name')}
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          name={`name_languages[${lang}]`}
                                          autoComplete="off"
                                          defaultValue={
                                            (entity?.name_languages || {})[lang]
                                          }
                                          ref={register({ required: true })}
                                        />
                                        <>
                                          {errors[`name_languages`] && (
                                            <span className="error">
                                              {t('event name is obligatory')}
                                            </span>
                                          )}
                                        </>
                                      </Form.Group>
                                    ),
                                )}

                                <Form.Group as={Col} md="6">
                                  <Form.Label htmlFor="key">
                                    {t('event url')}
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="key"
                                    defaultValue={entity?.key}
                                    onBlur={validateEventKey}
                                    ref={(e: any) => {
                                      keyRef.current = e;
                                      register(e, {
                                        required: {
                                          value: true,
                                          message: t('event url is obligatory'),
                                        },
                                      });
                                    }}
                                  />
                                  <a
                                    target="_blank"
                                    href={`https://${
                                      entity?.custom_domain
                                        ? entity.custom_domain
                                        : '4yourlive.com'
                                    }/${entity?.key}`}
                                  >
                                    https://
                                    {entity?.custom_domain
                                      ? entity.custom_domain
                                      : '4yourlive.com'}
                                    /{entity?.key}
                                  </a>
                                  <>
                                    {!!errors?.key?.message && (
                                      <span className="error">
                                        {errors?.key?.message}
                                      </span>
                                    )}
                                  </>
                                </Form.Group>

                                {i18n.languages.map(
                                  (lang: string) =>
                                    i18n.language == lang && (
                                      <Form.Group key={lang} as={Col} md="12">
                                        <Form.Label
                                          htmlFor={`description_languages[${lang}]`}
                                        >
                                          {t('event description')}
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          name={`description_languages[${lang}]`}
                                          autoComplete="off"
                                          defaultValue={
                                            (entity?.description_languages ||
                                              {})[lang]
                                          }
                                          ref={register({ required: false })}
                                        />
                                        <>
                                          {errors[`description_languages`] && (
                                            <span className="error">
                                              {t(
                                                'event description is obligatory',
                                              )}
                                            </span>
                                          )}
                                        </>
                                      </Form.Group>
                                    ),
                                )}

                                {/* <Form.Group as={Col} md="4">
                              <Form.Label htmlFor="name">Opções</Form.Label>
                              <div className="site-checkbox-all-wrapper">
                                <Checkbox
                                  name="newAccount"
                                  ref={register}
                                  onChange={(e) => {
                                    setEntity({
                                      ...entity,
                                      newAccount: e.target.value,
                                    });
                                  }}
                                  defaultChecked={entity?.newAccount || false}
                                >
                                  Nova Conta na tela de login
                                </Checkbox>
                              </div>
                            </Form.Group> */}
                                <Form.Group as={Col} md="4">
                                  <Form.Label htmlFor="settings[newSignin][placeholder]">
                                    Placeholder Login
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="settings[newSignin][placeholder]"
                                    placeholder="ex. email, cpf, matricula"
                                    defaultValue={
                                      entity?.settings?.newSignin?.placeholder
                                    }
                                    ref={register({ required: false })}
                                  />
                                </Form.Group>
                                <Form.Group as={Col} md="4">
                                  <Form.Label htmlFor="password">
                                    {t('event fixed password')}
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="password"
                                    defaultValue={entity?.settings?.password}
                                    ref={register({ required: false })}
                                  />
                                </Form.Group>

                                <Form.Group as={Col} md="12">
                                  <Form.Label htmlFor="name">
                                    {t('resources')}
                                  </Form.Label>
                                  <div className="site-checkbox-all-wrapper">
                                    <Checkbox
                                      indeterminate={indeterminateResources}
                                      onChange={onCheckAllChangeResources}
                                      checked={checkAllResources}
                                    >
                                      {t('check all')}
                                    </Checkbox>
                                  </div>
                                  <br />
                                  <CheckboxGroup
                                    options={resources.list?.map(
                                      (a: any) => a.name,
                                    )}
                                    value={checkedListResources}
                                    onChange={onChangeResources}
                                  />
                                </Form.Group>

                                {i18n.languages.map(
                                  (lang: string) =>
                                    i18n.language == lang && (
                                      <>
                                        <Form.Group
                                          key={`${lang}-1`}
                                          as={Col}
                                          md="4"
                                        >
                                          <Form.Label
                                            htmlFor={`settings[helpdesk_languages][title][${lang}]`}
                                          >
                                            {t('helpdesk title')}
                                          </Form.Label>
                                          <Form.Control
                                            type="text"
                                            name={`settings[helpdesk_languages][title][${lang}]`}
                                            autoComplete="off"
                                            defaultValue={
                                              (entity?.settings
                                                ?.helpdesk_languages?.title ||
                                                {})[lang]
                                            }
                                            ref={register({ required: false })}
                                          />
                                        </Form.Group>

                                        <Form.Group
                                          key={`${lang}-2`}
                                          as={Col}
                                          md="4"
                                        >
                                          <Form.Label
                                            htmlFor={`settings[helpdesk_languages][description][${lang}]`}
                                          >
                                            {t('helpdesk description')}
                                          </Form.Label>
                                          <Form.Control
                                            type="text"
                                            name={`settings[helpdesk_languages][description][${lang}]`}
                                            autoComplete="off"
                                            defaultValue={
                                              (entity?.settings
                                                ?.helpdesk_languages
                                                ?.description || {})[lang]
                                            }
                                            ref={register({ required: false })}
                                          />
                                        </Form.Group>

                                        <Form.Group
                                          key={`${lang}-3`}
                                          as={Col}
                                          md="4"
                                        >
                                          <Form.Label
                                            htmlFor={`settings[helpdesk_languages][data][${lang}]`}
                                          >
                                            {t('telephone/email helpdesk')}
                                          </Form.Label>
                                          <Form.Control
                                            type="text"
                                            name={`settings[helpdesk_languages][data][${lang}]`}
                                            autoComplete="off"
                                            defaultValue={
                                              (entity?.settings
                                                ?.helpdesk_languages?.data ||
                                                {})[lang]
                                            }
                                            ref={register({ required: false })}
                                          />
                                        </Form.Group>

                                        <Form.Group
                                          key={`${lang}-4`}
                                          as={Col}
                                          md="12"
                                        >
                                          <Form.Label
                                            htmlFor={`settings[helpdesk_languages][link][${lang}]`}
                                          >
                                            {t('helpdesk link')}
                                          </Form.Label>
                                          <Form.Control
                                            type="text"
                                            name={`settings[helpdesk_languages][link][${lang}]`}
                                            autoComplete="off"
                                            defaultValue={
                                              (entity?.settings
                                                ?.helpdesk_languages?.link ||
                                                {})[lang] ||
                                              'http://api.whatsapp.com/send?1=pt_BR&phone=55'
                                            }
                                            ref={register({ required: false })}
                                          />
                                        </Form.Group>

                                        <Form.Group
                                          key={`${lang}-4`}
                                          as={Col}
                                          md="12"
                                        >
                                          <Form.Label
                                            htmlFor={`settings[helpdesk_languages][search]`}
                                          >
                                            {t('satisfaction search link')}
                                          </Form.Label>
                                          <Form.Control
                                            type="text"
                                            name={`settings[helpdesk_languages][search]`}
                                            autoComplete="off"
                                            defaultValue={
                                              entity?.settings
                                                ?.helpdesk_languages?.search ||
                                              ''
                                            }
                                            ref={register({ required: false })}
                                          />
                                        </Form.Group>
                                      </>
                                    ),
                                )}
                              </Form.Row>
                              <section className="mt-3">
                                <h5>{t('access')}</h5>
                                <hr />

                                <Form.Row>
                                  <Form.Group as={Col} md="12">
                                    <Form.Label htmlFor={`custom_domain`}>
                                      {t('personalized domain')}
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name={`custom_domain`}
                                      autoComplete="off"
                                      // defaultValue={
                                      //   entity?.settings?.social_media?.site
                                      // }
                                      ref={register({ required: false })}
                                    />
                                  </Form.Group>
                                  <Form.Group as={Col} md="12">
                                    <Form.Label htmlFor="settings[access][single]">
                                      {t('only access')}
                                      Acesso único
                                    </Form.Label>

                                    <div className="site-checkbox-all-wrapper">
                                      <label className="mr-3">
                                        <input
                                          className="mr-1"
                                          type="checkbox"
                                          name="settings[access][single]"
                                          defaultChecked={
                                            entity?.settings?.access?.single ===
                                            true
                                          }
                                          // defaultChecked={true}
                                          ref={register({})}
                                        />
                                        {t(
                                          'limit user access to only one device',
                                        )}
                                      </label>
                                    </div>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <Form.Label htmlFor="settings[redirect][enabled]">
                                      {t('redirection')}
                                    </Form.Label>

                                    <div className="site-checkbox-all-wrapper">
                                      <label className="mr-3">
                                        <input
                                          className="mr-1"
                                          type="checkbox"
                                          name="settings[redirect][enabled]"
                                          defaultChecked={
                                            entity?.settings?.redirect
                                              ?.enabled === true
                                          }
                                          // defaultChecked={true}
                                          ref={register({})}
                                        />
                                        {t('redirect user after login')}
                                      </label>
                                    </div>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <Form.Label
                                      htmlFor={`settings[redirect][url]`}
                                    >
                                      {t('redirection url')}
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name={`settings[redirect][url]`}
                                      autoComplete="off"
                                      defaultValue={
                                        entity?.settings?.redirect?.url
                                      }
                                      // defaultValue={
                                      //   entity?.settings?.social_media?.site
                                      // }
                                      ref={register({ required: false })}
                                    />
                                  </Form.Group>
                                  <br />
                                  <Form.Group as={Col} md="6">
                                    <Form.Label htmlFor="name">
                                      {t('login screen')}
                                    </Form.Label>

                                    <div className="site-checkbox-all-wrapper">
                                      <label className="mr-3">
                                        <input
                                          className="mr-1"
                                          type="checkbox"
                                          name="newAccount"
                                          defaultChecked={
                                            entity?.newAccount === true
                                          }
                                          // defaultChecked={true}
                                          ref={register({})}
                                        />
                                        {t('show register button')}
                                      </label>
                                    </div>
                                    <div className="site-checkbox-all-wrapper">
                                      <label className="mr-3">
                                        <input
                                          className="mr-1"
                                          type="checkbox"
                                          name="forgotPassword"
                                          defaultChecked={
                                            entity?.forgotPassword === true
                                          }
                                          // defaultChecked={true}
                                          ref={register({})}
                                        />
                                        {t('show forgot password button')}
                                      </label>
                                    </div>
                                  </Form.Group>

                                  <Form.Group as={Col} md="6">
                                    <Form.Label
                                      htmlFor={`settings[login][signup_url]`}
                                    >
                                      {t('register url')}
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name={`settings[login][signup_url]`}
                                      autoComplete="off"
                                      defaultValue={
                                        entity?.settings?.login?.signup_url ||
                                        ''
                                      }
                                      ref={register({ required: false })}
                                    />
                                  </Form.Group>
                                  <br />
                                  <Form.Group as={Col} md="12">
                                    <Form.Label htmlFor="name">
                                      {t('register screen')}
                                    </Form.Label>

                                    <div className="site-checkbox-all-wrapper">
                                      <label className="mr-3">
                                        <input
                                          className="mr-1"
                                          type="checkbox"
                                          name="settings[access][show_signup_button]"
                                          defaultChecked={
                                            entity?.settings?.access
                                              ?.show_signup_button === true
                                          }
                                          // defaultChecked={true}
                                          ref={register({})}
                                        />
                                        {t('show register button')}
                                      </label>
                                      <br></br>
                                      <label className="">
                                        <input
                                          className="mr-1"
                                          type="checkbox"
                                          name="settings[access][show_subscribed_button]"
                                          defaultChecked={
                                            entity?.settings?.access
                                              ?.show_subscribed_button === true
                                          }
                                          // defaultChecked={true}
                                          ref={register({})}
                                        />
                                        {t('show button already registered')}
                                        (LandingPage)
                                      </label>
                                      <br></br>
                                      <label className="">
                                        <input
                                          className="mr-1"
                                          type="checkbox"
                                          name="settings[access][show_login_subscribed_button]"
                                          defaultChecked={
                                            entity?.settings?.access
                                              ?.show_login_subscribed_button ===
                                            true
                                          }
                                          // defaultChecked={true}
                                          ref={register({})}
                                        />
                                        {t('show button already registered')}{' '}
                                        (Página de Login)
                                      </label>
                                      <br></br>
                                    </div>
                                  </Form.Group>
                                  <Form.Group as={Col} md="12">
                                    <Form.Label htmlFor="name">
                                      {t('lp configurations')}
                                    </Form.Label>
                                    <br></br>
                                    <label className="mr-3">
                                      <input
                                        className="mr-1"
                                        type="checkbox"
                                        name="settings[config][dark_mode]"
                                        defaultChecked={
                                          entity?.settings?.config
                                            ?.dark_mode === true
                                        }
                                        // defaultChecked={true}
                                        ref={register({})}
                                      />
                                      {t('select dark mode')} (darkMode)
                                    </label>
                                    <br></br>
                                    <label className="mr-3">
                                      <input
                                        className="mr-1"
                                        type="checkbox"
                                        name="settings[config][palestrantes]"
                                        defaultChecked={
                                          entity?.settings?.config
                                            ?.palestrantes === true
                                        }
                                        // defaultChecked={true}
                                        ref={register({})}
                                      />
                                      {t('speakers section')}
                                    </label>
                                    <br></br>
                                    <label className="mr-3">
                                      <input
                                        className="mr-1"
                                        type="checkbox"
                                        name="settings[config][agenda]"
                                        defaultChecked={
                                          entity?.settings?.config?.agenda ===
                                          true
                                        }
                                        // defaultChecked={true}
                                        ref={register({})}
                                      />
                                      {t('agency section')}
                                    </label>
                                    <br></br>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <Form.Label htmlFor="settings[access][lp]">
                                      {t('event has lp')}:
                                    </Form.Label>

                                    <div className="d-flex flex-column site-checkbox-all-wrapper">
                                      <label className="">
                                        <input
                                          className="mr-1"
                                          type="radio"
                                          name="settings[access][lp]"
                                          defaultChecked={Boolean(
                                            Number(
                                              entity?.settings?.access?.lp,
                                            ),
                                          )}
                                          value={1}
                                          // defaultChecked={true}
                                          ref={register({})}
                                        />
                                        {t('yes')}
                                      </label>
                                      <label className="">
                                        <input
                                          className="mr-1"
                                          type="radio"
                                          name="settings[access][lp]"
                                          defaultChecked={
                                            !Boolean(
                                              Number(
                                                entity?.settings?.access?.lp,
                                              ),
                                            )
                                          }
                                          // defaultChecked={true}
                                          value={0}
                                          ref={register({})}
                                        />
                                        {t('no')}
                                      </label>
                                    </div>
                                  </Form.Group>

                                  <Form.Group as={Col} md="6">
                                    <Form.Label htmlFor="settings[login][enabled]">
                                      {t('active event only for')}:
                                    </Form.Label>

                                    <div className="d-flex flex-column site-checkbox-all-wrapper">
                                      <label className="">
                                        <input
                                          className="mr-1"
                                          type="checkbox"
                                          name="settings[login][enabled][attendance]"
                                          defaultChecked={
                                            entity?.settings?.login?.enabled
                                              ?.attendance === true
                                          }
                                          // defaultChecked={true}
                                          ref={register({})}
                                        />
                                        {t('attendance')}
                                      </label>
                                      <label className="">
                                        <input
                                          className="mr-1"
                                          type="checkbox"
                                          name="settings[login][enabled][moderator]"
                                          defaultChecked={
                                            entity?.settings?.login?.enabled
                                              ?.moderator === true
                                          }
                                          // defaultChecked={true}
                                          ref={register({})}
                                        />
                                        {t('moderator')}
                                      </label>
                                      <label className="">
                                        <input
                                          className="mr-1"
                                          type="checkbox"
                                          name="settings[login][enabled][participante]"
                                          defaultChecked={
                                            entity?.settings?.login?.enabled
                                              ?.participante === true
                                          }
                                          // defaultChecked={true}
                                          ref={register({})}
                                        />
                                        {t('participant')}
                                      </label>
                                    </div>
                                  </Form.Group>

                                  <Form.Group as={Col} md="6">
                                    <Form.Label
                                      htmlFor={`settings[login][blocked_message]`}
                                    >
                                      {t('login block')} ({t('message')})
                                    </Form.Label>
                                    <Form.Control
                                      as="textarea"
                                      rows={3}
                                      name={`settings[login][blocked_message]`}
                                      autoComplete="off"
                                      defaultValue={
                                        entity?.settings?.login?.blocked_message
                                      }
                                      ref={register({ required: false })}
                                    />
                                  </Form.Group>

                                  <Form.Group as={Col} md="6">
                                    <Form.Label
                                      htmlFor={`settings[login][unauthorized]`}
                                    >
                                      {t('invalid password')} ({t('message')})
                                    </Form.Label>
                                    <Form.Control
                                      as="textarea"
                                      rows={3}
                                      name={`settings[login][unauthorized]`}
                                      autoComplete="off"
                                      defaultValue={
                                        entity?.settings?.login?.unauthorized
                                      }
                                      ref={register({ required: false })}
                                    />
                                  </Form.Group>
                                </Form.Row>
                              </section>
                              <section className="mt-3">
                                <h5>{t('social network')}</h5>
                                <hr />

                                <Form.Row>
                                  <Form.Group as={Col} md="6">
                                    <Form.Label
                                      htmlFor={`settings[social_media][site]`}
                                    >
                                      Site
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name={`settings[social_media][site]`}
                                      autoComplete="off"
                                      defaultValue={
                                        entity?.settings?.social_media?.site
                                      }
                                      ref={register({ required: false })}
                                    />
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <Form.Label
                                      htmlFor={`settings[social_media][linkedin]`}
                                    >
                                      Linkedin
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name={`settings[social_media][linkedin]`}
                                      autoComplete="off"
                                      defaultValue={
                                        entity?.settings?.social_media?.linkedin
                                      }
                                      ref={register({ required: false })}
                                    />
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <Form.Label
                                      htmlFor={`settings[social_media][youtube]`}
                                    >
                                      Youtube
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name={`settings[social_media][youtube]`}
                                      autoComplete="off"
                                      defaultValue={
                                        entity?.settings?.social_media?.youtube
                                      }
                                      ref={register({ required: false })}
                                    />
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <Form.Label
                                      htmlFor={`settings[social_media][twitter]`}
                                    >
                                      Twitter
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name={`settings[social_media][twitter]`}
                                      autoComplete="off"
                                      defaultValue={
                                        entity?.settings?.social_media?.twitter
                                      }
                                      ref={register({ required: false })}
                                    />
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <Form.Label
                                      htmlFor={`settings[social_media][facebook]`}
                                    >
                                      Facebook
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name={`settings[social_media][facebook]`}
                                      autoComplete="off"
                                      defaultValue={
                                        entity?.settings?.social_media?.facebook
                                      }
                                      ref={register({ required: false })}
                                    />
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <Form.Label
                                      htmlFor={`settings[social_media][instagram]`}
                                    >
                                      Instagram
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      name={`settings[social_media][instagram]`}
                                      autoComplete="off"
                                      defaultValue={
                                        entity?.settings?.social_media
                                          ?.instagram
                                      }
                                      ref={register({ required: false })}
                                    />
                                  </Form.Group>
                                </Form.Row>
                              </section>
                              <Form.Group
                                as={Col}
                                sm={12}
                                className="mt-3"
                                style={{ textAlign: 'right' }}
                              >
                                <Button
                                  loading={loadingSubmit}
                                  htmlType="submit"
                                >
                                  {t('save')}
                                </Button>
                              </Form.Group>
                            </Form>
                          </Tab>
                          <Tab
                            disabled={!entity.id}
                            eventKey="more-info"
                            title={t('report datas')}
                          >
                            <MoreInfo event={entity} />
                          </Tab>
                          <Tab
                            disabled={!entity.id}
                            eventKey="customization"
                            title={t('customization')}
                          >
                            <h6>{t('customize the event')}</h6>
                            <hr />
                            <Form onSubmit={onSubmit}>
                              <Form.Row>
                                <Row>
                                  <Form.Group as={Col} md="3">
                                    <Form.Label htmlFor="backgroundHeaderGradientColor1">
                                      {t('gradient color')} 1
                                    </Form.Label>
                                    <div>
                                      <ColorPicker
                                        color={
                                          entity?.customization
                                            ?.backgroundHeaderGradientColor1
                                        }
                                        onChange={(e: any) => {
                                          entity.customization.backgroundHeaderGradientColor1 =
                                            e.color;
                                        }}
                                        placement="bottomLeft"
                                      />
                                    </div>
                                  </Form.Group>

                                  <Form.Group as={Col} md="3">
                                    <Form.Label htmlFor="backgroundHeaderGradientColor2">
                                      {t('gradient color')} 2
                                    </Form.Label>
                                    <div>
                                      <ColorPicker
                                        color={
                                          entity?.customization
                                            ?.backgroundHeaderGradientColor2
                                        }
                                        onChange={(e: any) => {
                                          entity.customization.backgroundHeaderGradientColor2 =
                                            e.color;
                                        }}
                                        placement="bottomLeft"
                                      />
                                    </div>
                                  </Form.Group>

                                  {/* <Form.Group as={Col} md="3">
                                <Form.Label htmlFor="backgroundColor">
                                  Cor de fundo
                                </Form.Label>
                                <div>
                                  <ColorPicker
                                    color={
                                      entity?.customization?.backgroundColor
                                    }
                                    onChange={(e: any) => {
                                      entity.customization.backgroundColor =
                                        e.color;
                                    }}
                                    placement="bottomLeft"
                                  />
                                </div>
                              </Form.Group> */}

                                  <Form.Group as={Col} md="3">
                                    <Form.Label htmlFor="fontColor">
                                      {t('font color')}
                                    </Form.Label>
                                    <div>
                                      <ColorPicker
                                        color={entity?.customization?.fontColor}
                                        onChange={(e: any) => {
                                          entity.customization.fontColor =
                                            e.color;
                                        }}
                                        placement="bottomLeft"
                                      />
                                    </div>
                                  </Form.Group>

                                  <Form.Group as={Col} md="3">
                                    <Form.Label htmlFor="inputBackgroundColor">
                                      {t('inbox background color')}
                                    </Form.Label>
                                    <div>
                                      <ColorPicker
                                        color={
                                          entity?.customization
                                            ?.inputBackgroundColor
                                        }
                                        onChange={(e: any) => {
                                          entity.customization.inputBackgroundColor =
                                            e.color;
                                        }}
                                        placement="bottomLeft"
                                      />
                                    </div>
                                  </Form.Group>

                                  <Form.Group as={Col} md="3">
                                    <Form.Label htmlFor="inputTextColor">
                                      {t('text color in input box')}
                                    </Form.Label>
                                    <div>
                                      <ColorPicker
                                        color={
                                          entity?.customization?.inputTextColor
                                        }
                                        onChange={(e: any) => {
                                          entity.customization.inputTextColor =
                                            e.color;
                                        }}
                                        placement="bottomLeft"
                                      />
                                    </div>
                                  </Form.Group>

                                  <Form.Group as={Col} md="3">
                                    <Form.Label htmlFor="buttonBackgroundColor">
                                      {t('button color')}
                                    </Form.Label>
                                    <div>
                                      <ColorPicker
                                        color={
                                          entity?.customization
                                            ?.buttonBackgroundColor
                                        }
                                        onChange={(e: any) => {
                                          entity.customization.buttonBackgroundColor =
                                            e.color;
                                        }}
                                        placement="bottomLeft"
                                      />
                                    </div>
                                  </Form.Group>

                                  <Form.Group as={Col} md="3">
                                    <Form.Label htmlFor="buttonBackgroundColor">
                                      {t('button text color')}
                                    </Form.Label>
                                    <div>
                                      <ColorPicker
                                        color={
                                          entity?.customization?.buttonTextColor
                                        }
                                        onChange={(e: any) => {
                                          entity.customization.buttonTextColor =
                                            e.color;
                                        }}
                                        placement="bottomLeft"
                                      />
                                    </div>
                                  </Form.Group>

                                  <Form.Group as={Col} md="3">
                                    <Form.Label htmlFor="dateBgColor">
                                      {t('date color in looby')}
                                    </Form.Label>
                                    <div>
                                      <ColorPicker
                                        color={
                                          entity?.customization?.dateBgColor
                                        }
                                        onChange={(e: any) => {
                                          entity.customization.dateBgColor =
                                            e.color;
                                        }}
                                        placement="bottomLeft"
                                        ref={register({ required: false })}
                                      />
                                    </div>
                                  </Form.Group>

                                  <Form.Group as={Col} md="3">
                                    <Form.Label htmlFor="hourBgColor">
                                      {t('color of the hour in the lobby')}
                                    </Form.Label>
                                    <div>
                                      <ColorPicker
                                        color={
                                          entity?.customization?.hourBgColor
                                        }
                                        onChange={(e: any) => {
                                          entity.customization.hourBgColor =
                                            e.color;
                                        }}
                                        placement="bottomLeft"
                                        ref={register({ required: false })}
                                      />
                                    </div>
                                  </Form.Group>

                                  <Form.Group as={Col} md="3">
                                    <Form.Label htmlFor="lobbyBgColor">
                                      {t('login background color')}
                                    </Form.Label>
                                    <div>
                                      <ColorPicker
                                        color={
                                          entity?.customization?.lobbyBgColor
                                        }
                                        onChange={(e: any) => {
                                          entity.customization.lobbyBgColor =
                                            e.color;
                                        }}
                                        placement="bottomLeft"
                                        ref={register({ required: false })}
                                      />
                                    </div>
                                  </Form.Group>

                                  <Form.Group as={Col} md="3">
                                    <Form.Label htmlFor="headerBgColor1">
                                      {t('bar color')} 1
                                    </Form.Label>
                                    <div>
                                      <ColorPicker
                                        color={
                                          entity?.customization?.headerBgColor1
                                        }
                                        onChange={(e: any) => {
                                          entity.customization.headerBgColor1 =
                                            e.color;
                                        }}
                                        placement="bottomLeft"
                                        ref={register({ required: false })}
                                      />
                                    </div>
                                  </Form.Group>

                                  <Form.Group as={Col} md="3">
                                    <Form.Label htmlFor="headerBgColor2">
                                      {t('bar color')} 2
                                    </Form.Label>
                                    <div>
                                      <ColorPicker
                                        color={
                                          entity?.customization?.headerBgColor2
                                        }
                                        onChange={(e: any) => {
                                          entity.customization.headerBgColor2 =
                                            e.color;
                                        }}
                                        placement="bottomLeft"
                                        ref={register({ required: false })}
                                      />
                                    </div>
                                  </Form.Group>
                                </Row>

                                <Row>
                                  <Form.Group as={Col} md="4">
                                    <Form.Label htmlFor="logo">
                                      {t('logo')}
                                    </Form.Label>
                                    <div className="custom-file">
                                      <Form.File
                                        label={t('upload file')}
                                        name="logo"
                                        ref={register}
                                      />
                                    </div>
                                    <div className="mt-1">
                                      <a
                                        href={entity?.customization?.logo}
                                        target="_blank"
                                      >
                                        <img
                                          src={entity?.customization?.logo}
                                          style={{ height: '50px' }}
                                        />
                                      </a>
                                      {!!entity?.customization?.logo && (
                                        <Button
                                          className="mt-1 w-100 btn-primary"
                                          htmlType="button"
                                          onClick={() => {
                                            setEntity((prev: any) => {
                                              const _prev = { ...prev };

                                              if (_prev.customization.logo)
                                                delete _prev.customization.logo;

                                              return _prev;
                                            });
                                            setValue('logo', undefined);
                                          }}
                                        >
                                          {t('remove logo')}
                                        </Button>
                                      )}
                                    </div>
                                  </Form.Group>

                                  <Form.Group as={Col} md="4">
                                    <Form.Label htmlFor="banner">
                                      {t('login cover')}
                                    </Form.Label>
                                    <div className="custom-file">
                                      <Form.File
                                        label={t('upload file')}
                                        name="banner"
                                        ref={register}
                                      />
                                    </div>
                                    <div className="mt-1">
                                      <a
                                        href={entity?.customization?.banner}
                                        target="_blank"
                                      >
                                        <img
                                          src={entity?.customization?.banner}
                                          style={{ height: '50px' }}
                                        />
                                      </a>
                                      {!!entity?.customization?.banner && (
                                        <Button
                                          className="mt-1 w-100 btn-primary"
                                          htmlType="button"
                                          onClick={() => {
                                            setEntity((prev: any) => {
                                              const _prev = { ...prev };

                                              if (_prev.customization.banner)
                                                delete _prev.customization
                                                  .banner;

                                              return _prev;
                                            });
                                            setValue('banner', undefined);
                                          }}
                                        >
                                          {t('remove login cover')}
                                        </Button>
                                      )}
                                    </div>
                                  </Form.Group>

                                  <Form.Group as={Col} md="4">
                                    <Form.Label htmlFor="bannerlobby">
                                      {t('lobby banner')}
                                    </Form.Label>
                                    <div className="custom-file">
                                      <Form.File
                                        label={t('upload file')}
                                        name="bannerlobby"
                                        ref={register}
                                      />
                                    </div>
                                    <div className="mt-1">
                                      <a
                                        href={
                                          entity?.customization?.bannerlobby
                                        }
                                        target="_blank"
                                      >
                                        <img
                                          src={
                                            entity?.customization?.bannerlobby
                                          }
                                          style={{ height: '50px' }}
                                        />
                                      </a>
                                      {!!entity?.customization?.bannerlobby && (
                                        <Button
                                          className="mt-1 w-100 btn-primary"
                                          htmlType="button"
                                          onClick={() => {
                                            setEntity((prev: any) => {
                                              const _prev = { ...prev };

                                              if (
                                                _prev.customization.bannerlobby
                                              )
                                                delete _prev.customization
                                                  .bannerlobby;

                                              return _prev;
                                            });
                                            setValue('bannerlobby', undefined);
                                          }}
                                        >
                                          {t('remove lobby banner')}
                                        </Button>
                                      )}
                                    </div>
                                  </Form.Group>

                                  <Form.Group as={Col} md="4">
                                    <Form.Label htmlFor="logo">
                                      Banner LP
                                    </Form.Label>
                                    <div className="custom-file">
                                      <Form.File
                                        label={t('upload file')}
                                        name="bannerlp"
                                        ref={register}
                                      />
                                    </div>
                                    <div className="mt-1">
                                      <a
                                        href={entity?.customization?.bannerlp}
                                        target="_blank"
                                      >
                                        <img
                                          src={entity?.customization?.bannerlp}
                                          style={{ height: '50px' }}
                                        />
                                      </a>
                                      {!!entity?.customization?.bannerlp && (
                                        <Button
                                          className="mt-1 w-100 btn-primary"
                                          htmlType="button"
                                          onClick={() => {
                                            setEntity((prev: any) => {
                                              const _prev = { ...prev };

                                              if (_prev.customization.bannerlp)
                                                delete _prev.customization
                                                  .bannerlp;

                                              return _prev;
                                            });
                                            setValue('bannerlp', undefined);
                                          }}
                                        >
                                          {t('remove lp banner')}
                                        </Button>
                                      )}
                                    </div>
                                  </Form.Group>
                                  <Form.Group as={Col} md="4">
                                    <Form.Label htmlFor="backgroundImg">
                                      Background
                                    </Form.Label>
                                    <div className="custom-file">
                                      <Form.File
                                        label={t('upload file')}
                                        name="backgroundImg"
                                        ref={register}
                                      />
                                    </div>
                                    <div className="mt-1">
                                      <a
                                        href={
                                          entity?.customization?.backgroundImg
                                        }
                                        target="_blank"
                                      >
                                        <img
                                          src={
                                            entity?.customization?.backgroundImg
                                          }
                                          style={{ height: '50px' }}
                                        />
                                      </a>
                                      {!!entity?.customization
                                        ?.backgroundImg && (
                                        <Button
                                          className="mt-1 w-100 btn-primary"
                                          htmlType="button"
                                          onClick={() => {
                                            setEntity((prev: any) => {
                                              const _prev = { ...prev };

                                              if (
                                                _prev.customization
                                                  .backgroundImg
                                              )
                                                delete _prev.customization
                                                  .backgroundImg;

                                              return _prev;
                                            });
                                            setValue(
                                              'backgroundImg',
                                              undefined,
                                            );
                                          }}
                                        >
                                          {t('remove background')}
                                        </Button>
                                      )}
                                    </div>
                                  </Form.Group>
                                  <Form.Group as={Col} md="4">
                                    <Form.Label htmlFor="logo">
                                      Favicon
                                    </Form.Label>
                                    <div className="custom-file">
                                      <Form.File
                                        label={t('upload file')}
                                        name="favicon"
                                        ref={register}
                                      />
                                    </div>
                                    <div className="mt-1">
                                      <a
                                        href={entity?.customization?.favicon}
                                        target="_blank"
                                      >
                                        <img
                                          src={entity?.customization?.favicon}
                                          style={{ height: '50px' }}
                                        />
                                      </a>
                                      {!!entity?.customization?.favicon && (
                                        <Button
                                          className="mt-1 w-100 btn-primary"
                                          htmlType="button"
                                          onClick={() => {
                                            setEntity((prev: any) => {
                                              const _prev = { ...prev };

                                              if (_prev.customization.favicon)
                                                delete _prev.customization
                                                  .favicon;

                                              return _prev;
                                            });
                                            setValue('favicon', undefined);
                                          }}
                                        >
                                          {t('remove favicon')}
                                        </Button>
                                      )}
                                    </div>
                                  </Form.Group>
                                </Row>
                              </Form.Row>
                              <Form.Group
                                as={Col}
                                sm={12}
                                className="mt-3"
                                style={{ textAlign: 'right' }}
                              >
                                <Button
                                  loading={loadingSubmit}
                                  htmlType="submit"
                                >
                                  {t('save')}
                                </Button>
                              </Form.Group>
                            </Form>
                          </Tab>
                          {/* <Tab
                        disabled={!entity.id}
                        eventKey="registerFields"
                        title="Campos de cadastro"
                      >
                        <h6>Edite os campos do cadastro</h6>
                        <hr />
                        <RegisterFields
                          {...props}
                          entity={entity}
                          setEntity={setEntity}
                        ></RegisterFields>
                      </Tab> */}

                          <Tab
                            disabled={!entity.id}
                            eventKey="speakers"
                            title={t('speakers')}
                          >
                            <h6>{t('add speakers')}</h6>
                            <hr />
                            <Speakers {...props} event={entity}></Speakers>
                          </Tab>
                          <Tab
                            disabled={!entity.id}
                            eventKey="channels"
                            title={t('rooms')}
                          >
                            <h6>{t('add rooms')}</h6>
                            <hr />
                            <Channels {...props} event={entity}></Channels>
                          </Tab>
                          <Tab
                            disabled={!entity.id}
                            eventKey="participants"
                            title={t('participants')}
                          >
                            <h6>{t('add participants')}</h6>
                            <hr />
                            <Participants
                              {...props}
                              event={entity}
                            ></Participants>
                          </Tab>
                          <Tab
                            disabled={!entity.id}
                            eventKey="reports"
                            title={t('reports')}
                          >
                            <h6>{t('reports')}</h6>
                            <hr />
                            <Reports {...props} event={entity}></Reports>
                          </Tab>

                          <Tab
                            disabled={!entity.id}
                            eventKey="imageParticipant"
                            title="Images de Participantes"
                          >
                            <h6>{t('participants images')}</h6>
                            <hr />
                            <ImageParticipant
                              {...props}
                              event={entity}
                            ></ImageParticipant>
                          </Tab>

                          <Tab
                            disabled={!entity.id}
                            eventKey="agenda"
                            title={t('schedule')}
                          >
                            <h6>{t('schedule')}</h6>
                            <hr />
                            <Agenda {...props} event={entity}></Agenda>
                          </Tab>

                          <Tab disabled={!entity.id} eventKey="LP" title="LP">
                            <LP {...props} event={entity}></LP>
                          </Tab>

                          <Tab
                            disabled={!entity.id}
                            eventKey="sponsors"
                            title={t('sponsors')}
                          >
                            <Sponsors {...props} event={entity}></Sponsors>
                          </Tab>
                          <Tab
                            disabled={!entity.id}
                            eventKey="partners"
                            title={t('partners')}
                          >
                            <Partners {...props} event={entity}></Partners>
                          </Tab>
                          <Tab
                            disabled={!entity.id}
                            eventKey="feed"
                            title={t('feed')}
                          >
                            <Feed {...props} event={entity}></Feed>
                          </Tab>
                          <Tab
                            disabled={!entity.id}
                            eventKey="contents"
                            title={t('contents')}
                          >
                            <Contents {...props} event={entity}></Contents>
                          </Tab>
                          <Tab
                            disabled={!entity.id}
                            eventKey="products"
                            title={t('products')}
                          >
                            <Products {...props} event={entity}></Products>
                          </Tab>
                          <Tab
                            disabled={!entity.id}
                            eventKey="tips"
                            title={t('tips')}
                          >
                            <Tips {...props} event={entity}></Tips>
                          </Tab>
                          <Tab
                            disabled={!entity.id}
                            eventKey="logistics"
                            title={t('logistics')}
                          >
                            <Logistics {...props} event={entity}></Logistics>
                          </Tab>
                          <Tab
                            disabled={!entity.id}
                            eventKey="sympla"
                            title="Sympla"
                          >
                            <Sympla {...props} event={entity}></Sympla>
                          </Tab>
                          <Tab disabled={!entity.id} eventKey="sso" title="SSO">
                            <SSO {...props} event={entity}></SSO>
                          </Tab>
                        </Tabs>
                      </>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </DashboardMasterPage>
  );
};

export default EventDetails;
