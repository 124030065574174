import React, { useEffect, useRef, useState } from 'react';
import ColorPicker from 'rc-color-picker';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Checkbox, Button } from 'antd';
import firebase from 'firebase';
import { v4 } from 'uuid';
import { asEnumerable } from 'linq-es2015';

import Util from '../../../../../utils/util';
import { convertNameToSlug } from '../../../../../utils/slug';
import { useAuth } from '../../../../../hooks/Auth2';
import { useToast } from '../../../../../hooks/Toast';
import api from '../../../../../services/api';
import { useEmitter } from '../../../../../hooks/Emitter';
import { max } from 'moment';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Typeahead } from 'react-bootstrap-typeahead';

interface IChannelFormProps {
  speakers: any[];
  channels: any[];
  checkedListSpeakers: any[];
  saveVideoCover: string;
  resources: any;
  isFirstChannel: boolean;
  urlImageCover: string;
  registerField: any;
  event: any;
  onSuccess?(): void;
}

const CheckboxGroup = Checkbox.Group;

const ChannelForm: React.FC<IChannelFormProps> = ({
  speakers,
  channels,
  isFirstChannel,
  saveVideoCover,
  urlImageCover,
  checkedListSpeakers: editCheckedListSpeakers,
  resources,
  registerField: editRegister,
  onSuccess,
  ...props
}) => {
  const { event } = props;
  const { emitter, events } = useEmitter();
  const { t, i18n } = useTranslation();
  const { user } = useAuth();
  const history = useHistory();
  const [registerField, setRegisterField] = useState<any>(editRegister || {});
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    errors,
    reset,
    unregister,
  } = useForm<any>({
    defaultValues: {},
  });

  const [indeterminateSpeakers, setIndeterminateSpeakers] = useState(true);
  const [checkAllSpeakers, setCheckAllSpeakers] = useState(false);
  const [preview_video_cover, setPreviewVideoCover] = useState('');
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [indeterminate, setIndeterminate] = useState(true);
  const refForm = useRef<any>();
  const [checkAll, setCheckAll] = useState(false);
  const [checkedListSpeakers, setCheckedListSpeakers] = useState<any[]>(
    editCheckedListSpeakers || [],
  );
  const [checkedList, setCheckedList] = useState<any[]>([]);
  const { addToast } = useToast();

  const saveUrlVideoCover = () => {
    refForm.current.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true }),
    );
  };
  useEffect(() => {
    if (saveVideoCover === 'isSubmit') {
      return saveUrlVideoCover();
    }
  }, [saveVideoCover]);

  useEffect(() => {
    if (!editRegister) return;

    console.log('register', { editRegister });

    setRegisterField(editRegister);

    if (Object.keys(editRegister).indexOf('type_room') === -1)
      setValue('type_room', 'eventRoom');

    for (const key in editRegister) {
      if (editRegister.hasOwnProperty(key)) {
        const element = editRegister[key];
        try {
          if (key != 'resources' && key != 'picture') {
            console.log('setValue', { key, element });
            setValue(key, element);
          }
        } catch (error) {}
      }
    }

    setCheckedList(editRegister.resources?.map((a: any) => a.name) || []);

    setCheckedListSpeakers(editRegister?.speakers || []);
    // setCheckedListSpeakers(
    //   editRegister?.speakers?.map((a: any) => a.name) || [],
    // );
  }, [editRegister]);

  useEffect(() => {
    if (registerField.id) {
      const listener = emitter.on(events.EXTERNAL_LINK_CHANGED, function (
        event,
      ) {
        console.log('External Link Changed', { event, registerField });

        const { resources } = registerField;
        // remove old external links
        let _resources = resources.filter((r: any) => r.key !== 'externallink');

        // add new external links
        _resources = [
          ..._resources,
          ...event.resources.filter((r: any) => r.key === 'externallink'),
        ];

        // update event object
        const _event = {
          ...registerField,
          resources: _resources,
        };

        //save event updated
        setRegisterField(_event);
      });

      return () => {
        listener?.off(events.EXTERNAL_LINK_CHANGED);
      };
    }
  }, [registerField]);

  useEffect(() => {
    setCheckedListSpeakers(editCheckedListSpeakers);
  }, [editCheckedListSpeakers]);

  async function upload(inputFile: any, eventKey: string) {
    if (typeof inputFile !== 'string' && inputFile?.length) {
      const uploaded: any = await firebase
        .storage()
        .ref(`/companies/${eventKey}/customization/${v4()}`)
        .put(inputFile[0]);
      return (await uploaded.ref.getDownloadURL()) || '';
    }

    return '';
  }

  const onSubmit = async function (data: any) {
    try {
      setLoadingSubmit(true);

      const register = Util.merge(registerField, data);
      register.eventId = props.event.id;
      const _resources = asEnumerable(resources.list)
        .Where((l: any) => checkedList.indexOf(l.name) > -1)
        .ToArray();
      let _external_links = [];

      if (register.resources) {
        _external_links = registerField.resources.filter(
          (r: any) => r.key === 'externallink',
        );
      }

      register.resources = [..._resources, ..._external_links];

      if (Object.keys(data.name_languages)?.length) {
        register.name = data.name_languages[getDefaultLang()?.language];
      }

      // if (
      //   !registerField.customization.channel_group &&
      //   register.customization.channel_group
      // ) {
      //   delete register.customization.channel_group;
      // }

      register.customization = register.customization || {};
      register.picture =
        (await upload(data.picture, props.event.key)) || registerField.picture;

      register.customization.background =
        (await upload(data.background, props.event.key)) ||
        registerField.customization.background;

      register.customization.infoImage =
        (await upload(data.infoImage, props.event.key)) ||
        register.customization.infoImage;

      register.customization.infoImage2 =
        (await upload(data.infoImage2, props.event.key)) ||
        register.customization.infoImage2;

      register.customization.infoImageLink =
        data.customization?.infoImageLink ||
        register.customization?.infoImageLink ||
        ` `;

      register.customization.videoCover =
        (await upload(data.video_cover, props.event.key)) ||
        register.customization.videoCover;

      // register.speakers = asEnumerable(speakers)
      //   .Where((l: any) => checkedListSpeakers.indexOf(l.name) > -1)
      //   .ToArray();
      register.speakers = checkedListSpeakers;

      if (!registerField.id) {
        if (
          channels.filter((v) => v.key === register.key && v.id !== register.id)
            ?.length
        ) {
          const _channels_with_same_key = channels.filter(
            (v) => v.key.startsWith(register.key) && v.id !== register.id,
          );
          if (_channels_with_same_key?.length) {
            register.key = getNextKeyNumber(
              register.key,
              _channels_with_same_key,
            );
            // register.key = `${register.key}-${
            //   _channels_with_same_key?.length + 1
            // }`;
          }
        }
      }

      if (register?.type_room === 'eventShowRoom') {
        register.customization = register.customization || {};
        register.customization.channel_model =
          register.customization.channel_model || {};
        register.customization.channel_model.key = 'externallink';
      }

      register.videoUrl = urlImageCover;
      const result = await api.post('/admin/channel', register);

      setRegisterField({ customization: {} });
      reset();
      setPreviewVideoCover('');
      setCheckedList([]);

      addToast({
        type: 'success',
        title: data.id ? 'Cadastro realizado' : 'Edição realizada',
        description: 'Sua informação foi enviada com sucesso!',
      });
      onSuccess?.();
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro no cadastro',
        description:
          error?.response?.data?.message ||
          error.message ||
          'Ocorreu um erro ao fazer cadastro, tente novamente.',
      });
    } finally {
      setLoadingSubmit(false);
    }
  };

  function getNextKeyNumber(key: string, channels: any[]) {
    const _getKeyNumbers = channels.map((c) => {
      const _chars: string[] = c.key.split('-');

      const _lastNumber = Number(_chars?.[_chars?.length - 1]);
      if (!isNaN(_lastNumber)) return _lastNumber;
      else return 1;
    });

    return key + '-' + (Math.max(..._getKeyNumbers) + 1);
  }

  const onChangeSpeakers = (checkedListSpeakers: any) => {
    console.log('onChangeSpeakers', checkedListSpeakers);
    setCheckedListSpeakers(checkedListSpeakers);
    setIndeterminateSpeakers(
      !!checkedListSpeakers?.length &&
        checkedListSpeakers?.length < speakers?.length,
    );
    // setCheckAllSpeakers(checkedListSpeakers?.length === speakers?.length);
  };

  const onCheckAllChangeSpeakers = (e: any) => {
    setCheckedListSpeakers(
      e.target.checked ? speakers.map((a: any) => a.name) : [],
    );
    setIndeterminateSpeakers(false);
    setCheckAllSpeakers(e.target.checked);
  };

  const onChange = (checkedList: any) => {
    setCheckedList(checkedList);
    setIndeterminate(
      !!checkedList?.length && checkedList?.length < resources.list?.length,
    );
    setCheckAll(checkedList?.length === resources.list?.length);
  };

  const onCheckAllChange = (e: any) => {
    setCheckedList(e.target.checked ? resources.list : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  function handleVideoCoverChange(e: React.ChangeEvent<HTMLInputElement>) {
    const _files = e.currentTarget.files;

    if (!_files?.length) return;

    const reader = new FileReader();
    reader.readAsDataURL(_files[0]);
    reader.onload = () => setPreviewVideoCover(reader.result as string);
    reader.onerror = (error) => alert(error);
  }

  function handleApplyVideoCover() {
    i18n.languages.map(
      (lang: string) =>
        i18n.language == lang &&
        setValue(`streaming[${lang}]`, registerField.customization?.videoCover),
    );
  }

  function getDefaultLang() {
    const _defaultLangs = props.event?.settings?.languages?.filter(
      (v: any) => v.default,
    );

    if (_defaultLangs?.length) return _defaultLangs[0];
  }

  console.log('channelFormRender', {
    registerField,
    editRegister,
    checkedListSpeakers,
    customization: watch('customization'),
  });

  return (
    <Form ref={refForm} onSubmit={handleSubmit(onSubmit)}>
      <Form.Row>
        <Form.Group as={Col} md="12">
          <Form.Label htmlFor="type_room">{t('room type')}</Form.Label>
          <Form.Control
            name="type_room"
            as="select"
            defaultValue={registerField.type_room ?? 'eventRoom'}
            onChange={(e) => {
              setRegisterField({
                ...registerField,
                companyId: e.target.value,
              });
            }}
            ref={register({ required: true })}
          >
            <option value="">{t('select room type')}</option>
            <option value="eventRoom">{t('event room')}</option>
            <option value="eventFacelive">{t('facelive room')}</option>
            <option value="eventShowRoom">{t('showroom room')}</option>
          </Form.Control>
          <>
            {errors[`company`] && (
              <span className="error">
                {t('room type is obligatory')}Tipo da sala é obrigatória
              </span>
            )}
          </>
        </Form.Group>
        {!!watch('type_room') && (
          <>
            {watch('type_room') === 'eventShowRoom' && (
              <>
                <Form.Group as={Col} md="6">
                  <Form.Label htmlFor="customization[channel_model][url]">
                    {t('showroom url type')}URL do ShowRoom
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="customization[channel_model][url]"
                    autoComplete="off"
                    readOnly
                    value={`https://${
                      event?.custom_domain
                        ? event.custom_domain
                        : '4yourlive.com'
                    }/${props.event.key}/showroom/index.html?v=${
                      watch('customization[channel_model][version]') || 1
                    }&uid=`}
                    ref={register({ required: true })}
                  />
                  <>
                    {errors[`key`] && (
                      <span className="error">
                        {t('url is obligatory')}URL é obrigatório
                      </span>
                    )}
                  </>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <Form.Label htmlFor="customization[channel_model][version]">
                    {t('showroom version')}Versão do Showroom
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="customization[channel_model][version]"
                    autoComplete="off"
                    defaultValue={
                      registerField.customization?.channel_model?.version || '1'
                    }
                    ref={register({ required: true })}
                  />
                  <>
                    {errors[`customization[channel_model][version]`] && (
                      <span className="error">
                        {t('version is obligatory')}
                      </span>
                    )}
                  </>
                </Form.Group>
                <Form.Group as={Col} md="12">
                  <Form.Label htmlFor="name">{t('resources')}</Form.Label>
                  <div className="site-checkbox-all-wrapper">
                    <Checkbox
                      indeterminate={indeterminate}
                      onChange={onCheckAllChange}
                      checked={checkAll}
                    >
                      Check all
                    </Checkbox>
                  </div>
                  <br />
                  <CheckboxGroup
                    options={resources.list?.map((a: any) => a.name)}
                    value={checkedList}
                    onChange={onChange}
                  />
                </Form.Group>
              </>
            )}
            {watch('type_room') === 'eventRoom' && (
              <>
                {' '}
                <Form.Group as={Col} sm="6" md="6">
                  <Form.Label htmlFor="picture">{t('image')}</Form.Label>
                  <div className="custom-file">
                    <Form.File
                      label="Subir arquivo"
                      name="picture"
                      ref={register}
                    />
                  </div>
                  <div className="mt-1 w-25">
                    <a href={registerField.picture} target="_blank">
                      <img
                        src={registerField.picture}
                        style={{ height: '50px' }}
                      />
                    </a>
                    {!!registerField?.picture && (
                      <Button
                        className="mt-1 w-100 btn-primary"
                        htmlType="button"
                        onClick={() => {
                          setRegisterField((prev: any) => {
                            const _prev = { ...prev };

                            if (_prev.picture) delete _prev.picture;

                            return _prev;
                          });
                          setValue('picture', undefined);
                        }}
                      >
                        {t('remove image')}
                      </Button>
                    )}
                  </div>
                </Form.Group>
                <Form.Group as={Col} sm="6" md="6">
                  <Form.Label htmlFor="background">Background</Form.Label>
                  <div className="custom-file">
                    <Form.File
                      label="Subir arquivo"
                      name="background"
                      ref={register}
                    />
                  </div>
                  <div className="mt-1 w-25">
                    <a
                      href={registerField.customization.background}
                      target="_blank"
                    >
                      <img
                        src={registerField.customization.background}
                        style={{ height: '50px' }}
                      />
                    </a>
                    {!!registerField?.customization?.background && (
                      <Button
                        className="mt-1 w-100 btn-primary"
                        htmlType="button"
                        onClick={() => {
                          setRegisterField((prev: any) => {
                            const _prev = { ...prev };

                            if (_prev.customization.background)
                              delete _prev.customization.background;

                            return _prev;
                          });
                          setValue('background', undefined);
                        }}
                      >
                        {t('remove background')}
                      </Button>
                    )}
                  </div>
                </Form.Group>
                <Form.Group as={Col} sm="6" md="6">
                  <Form.Label htmlFor="infoImage">
                    {t('side banner')}
                  </Form.Label>
                  <div className="custom-file">
                    <Form.File
                      label={t('upload file')}
                      name="infoImage"
                      ref={register}
                    />
                  </div>
                  <div className="mt-1 w-25">
                    <a
                      href={registerField.customization?.infoImage}
                      target="_blank"
                    >
                      <img
                        src={registerField.customization?.infoImage}
                        style={{ height: '50px' }}
                      />
                    </a>
                    {!!registerField?.customization?.infoImage && (
                      <Button
                        className="mt-1 w-100 btn-primary"
                        htmlType="button"
                        onClick={() => {
                          setRegisterField((prev: any) => {
                            const _prev = { ...prev };

                            if (_prev.customization.infoImage)
                              delete _prev.customization.infoImage;

                            return _prev;
                          });
                          setValue('infoImage', undefined);
                        }}
                      >
                        {t('remove side banner')}
                      </Button>
                    )}
                  </div>
                </Form.Group>
                <Form.Group as={Col} sm="6" md="6">
                  <Form.Label htmlFor="infoImage">
                    {t('side banner')} 2
                  </Form.Label>
                  <div className="custom-file">
                    <Form.File
                      label={t('upload file')}
                      name="infoImage2"
                      ref={register}
                    />
                  </div>
                  <div className="mt-1 w-25">
                    <a
                      href={registerField.customization?.infoImage2}
                      target="_blank"
                    >
                      <img
                        src={registerField.customization?.infoImage2}
                        style={{ height: '50px' }}
                      />
                    </a>
                    {!!registerField?.customization?.infoImage2 && (
                      <Button
                        className="mt-1 w-100 btn-primary"
                        htmlType="button"
                        onClick={() => {
                          setRegisterField((prev: any) => {
                            const _prev = { ...prev };

                            if (_prev.customization.infoImage2)
                              delete _prev.customization.infoImage2;

                            return _prev;
                          });
                          setValue('infoImage2', undefined);
                        }}
                      >
                        {t('remove side banner')} 2
                      </Button>
                    )}
                  </div>
                </Form.Group>
              </>
            )}
            <div className="col-12">
              <div className="row">
                <Form.Group as={Col} xs="2">
                  <div>
                    <Form.Label htmlFor="active">{t('active')}</Form.Label>
                  </div>
                  <div>
                    <Form.Check
                      defaultChecked={registerField.active}
                      label=""
                      type="checkbox"
                      name="active"
                      ref={register}
                    />
                  </div>
                </Form.Group>

                <Form.Group as={Col} xs="4">
                  <div>
                    <Form.Label htmlFor="default">Default</Form.Label>
                  </div>
                  <div>
                    <Form.Check
                      defaultChecked={registerField.default}
                      label=""
                      type="checkbox"
                      name="default"
                      ref={register}
                    />
                  </div>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <Form.Label htmlFor="customization[infoImageLink]">
                    {t('side link banner')}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="customization[infoImageLink]"
                    autoComplete="off"
                    defaultValue={
                      registerField?.customization?.infoImageLink || ''
                    }
                    // defaultValue={
                    //   isFirstChannel
                    //     ? convertNameToSlug('Plenária')
                    //     : registerField.key || ''
                    // }
                    ref={register()}
                  />
                </Form.Group>
              </div>
            </div>
            {i18n.languages.map(
              (lang: string) =>
                i18n.language == lang && (
                  <Form.Group key={lang} as={Col} md="6">
                    <Form.Label htmlFor={`name_languages[${lang}]`}>
                      {t('channel name')}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name={`name_languages[${lang}]`}
                      autoComplete="off"
                      defaultValue={
                        isFirstChannel
                          ? 'Plenária'
                          : (registerField?.name_languages || {})[lang]
                      }
                      onChange={(e) => {
                        console.log('channel name', {
                          name: e.currentTarget.value,
                          id: registerField.id,
                        });

                        if (
                          getDefaultLang()?.language === lang &&
                          registerField.key !== 'plenaria' &&
                          !registerField.id
                        )
                          setValue(
                            'key',
                            convertNameToSlug(e.currentTarget.value),
                          );
                      }}
                      ref={register({ required: true })}
                    />
                    <>
                      {errors[`name_languages`] && (
                        <span className="error">{t('name is obligatory')}</span>
                      )}
                    </>
                  </Form.Group>
                ),
            )}
            <Form.Group as={Col} md="6">
              <Form.Label htmlFor="key">
                {t('channel url (no spaces or accentuation)')}
              </Form.Label>
              <Form.Control
                type="text"
                name="key"
                readOnly
                autoComplete="off"
                defaultValue={
                  isFirstChannel
                    ? convertNameToSlug('Plenária')
                    : registerField.key || ''
                }
                ref={register({ required: true })}
              />
              <>
                {errors[`key`] && (
                  <span className="error">{t('url is obligatory')}</span>
                )}
              </>
            </Form.Group>
            {watch('type_room') === 'eventRoom' && (
              <>
                {i18n.languages.map(
                  (lang: string) =>
                    i18n.language == lang && (
                      <Form.Group key={lang} as={Col} md="12">
                        <Form.Label htmlFor={`description_languages[${lang}]`}>
                          {t('description')}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name={`description_languages[${lang}]`}
                          autoComplete="off"
                          defaultValue={
                            (registerField?.description_languages || {})[lang]
                          }
                          ref={register({ required: true })}
                        />
                        <>
                          {errors[`description_languages`] && (
                            <span className="error">
                              {t('description is obligatory')}
                            </span>
                          )}
                        </>
                      </Form.Group>
                    ),
                )}

                <Form.Group as={Col} md="2">
                  <Form.Label htmlFor="order">{t('group')}</Form.Label>
                  <Form.Control
                    defaultValue={
                      registerField.customization?.['channel_group']
                    }
                    // type="number"
                    // name="customization[channel_group]"
                    autoComplete="off"
                    onChange={(e) => {
                      registerField.customization.channel_group =
                        e.target.value;
                    }}
                    // ref={register()}
                  />
                </Form.Group>

                <Form.Group as={Col} md="2">
                  <Form.Label htmlFor="order">{t('order')}</Form.Label>
                  <Form.Control
                    defaultValue={registerField.order || 1}
                    type="number"
                    name="order"
                    autoComplete="off"
                    ref={register({ required: true })}
                  />
                  <>
                    {errors[`order`] && (
                      <span className="error">{t('order is obligatory')}</span>
                    )}
                  </>
                </Form.Group>

                <Form.Group as={Col} md="2">
                  <Form.Label htmlFor="channel_date">{t('date')}</Form.Label>
                  <Form.Control
                    defaultValue={registerField.channel_date}
                    type="text"
                    name="channel_date"
                    autoComplete="off"
                    ref={register({ required: false })}
                  />
                  <>
                    {errors[`register[date]`] && (
                      <span className="error">{t('date is obligatory')}</span>
                    )}
                  </>
                </Form.Group>

                <Form.Group as={Col} md="2">
                  <Form.Label htmlFor="hour">{t('hour')}</Form.Label>
                  <Form.Control
                    defaultValue={registerField.hour}
                    type="text"
                    name="hour"
                    autoComplete="off"
                    ref={register({ required: false })}
                  />
                </Form.Group>
                {/*
                {i18n.languages.map(
                  (lang: string) =>
                    i18n.language == lang && (
                      <Form.Group key={lang} as={Col} md="12">
                        <Form.Label htmlFor={`streaming[${lang}]`}>
                          URL do Vídeo
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name={`streaming[${lang}]`}
                          autoComplete="off"
                          defaultValue={(registerField?.streaming || {})[lang]}
                          ref={register({ required: false })}
                        />
                        <>
                          {errors[`streaming`] && (
                            <span className="error">
                              URL do Vídeo é obrigatório
                            </span>
                          )}
                        </>
                      </Form.Group>
                    ),
                )} */}

                <div className="col-12">
                  <div className="row">
                    {/* <Form.Group className="mb-2" as={Col} md="12">
                      <Form.Label htmlFor="video_cover">
                        Capa do Vídeo
                      </Form.Label>
                      <div className="custom-file">
                        <Form.File
                          label="Subir arquivo"
                          name="video_cover"
                          ref={register}
                          onChange={handleVideoCoverChange}
                        />
                      </div>
                    </Form.Group> */}

                    {/* {(!!preview_video_cover ||
                      !!registerField.customization?.videoCover) && (
                        <div className="col-12 mb-3">
                          <a
                            href={registerField.customization?.videoCover}
                            className="d-block"
                            target="_blank"
                          >
                            <img
                              src={
                                preview_video_cover ||
                                registerField.customization?.videoCover
                              }
                              style={{ height: '100px' }}
                            />
                          </a>

                          {registerField.customization?.videoCover && (
                            <button
                              className="btn btn-primary mt-2"
                              type="button"
                              onClick={handleApplyVideoCover}
                            >
                              Aplicar Capa no Video
                            </button>
                          )}
                        </div>
                      )} */}
                  </div>
                </div>

                <Form.Group as={Col} md="3">
                  <Form.Label htmlFor="customization[dateBgColor]">
                    {t('event date color')}
                  </Form.Label>
                  <div>
                    <ColorPicker
                      color={
                        registerField.customization?.dateBgColor || '#ffffff'
                      }
                      onChange={(e: any) => {
                        registerField.customization.dateBgColor = e.color;
                      }}
                      placement="bottomLeft"
                    />
                  </div>
                </Form.Group>

                <Form.Group as={Col} md="3">
                  <Form.Label htmlFor="customization[hourBgColor]">
                    {t('event hour color')}
                  </Form.Label>
                  <div>
                    <ColorPicker
                      color={
                        registerField.customization?.hourBgColor || '#ffffff'
                      }
                      onChange={(e: any) => {
                        registerField.customization.hourBgColor = e.color;
                      }}
                      placement="bottomLeft"
                    />
                  </div>
                </Form.Group>

                <Form.Group as={Col} md="12">
                  <Form.Label htmlFor="name">{t('resources')}</Form.Label>
                  <div className="site-checkbox-all-wrapper">
                    <Checkbox
                      indeterminate={indeterminate}
                      onChange={onCheckAllChange}
                      checked={checkAll}
                    >
                      Check all
                    </Checkbox>
                  </div>
                  <br />
                  <CheckboxGroup
                    options={resources.list?.map((a: any) => a.name)}
                    value={checkedList}
                    onChange={onChange}
                  />
                </Form.Group>

                <Form.Group as={Col} md="12">
                  <Form.Label htmlFor="name">{t('speakers')}</Form.Label>
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="name"
                    onChange={onChangeSpeakers}
                    options={speakers}
                    multiple
                    emptyLabel={t('no speaker was found')}
                    placeholder={t('select the speakers')}
                    selected={checkedListSpeakers}
                  />
                </Form.Group>
              </>
            )}

            <Form.Group
              as={Col}
              sm={12}
              className="mt-3"
              style={{ textAlign: 'right' }}
            >
              <Button loading={loadingSubmit} htmlType="submit">
                {t('save')}
              </Button>
            </Form.Group>
          </>
        )}
      </Form.Row>
    </Form>
  );
};

export default ChannelForm;
