import React, { useState, useCallback, useEffect, useMemo } from 'react';
import 'react-day-picker/lib/style.css';
import 'firebase/firestore';
import 'firebase/auth';
import { useAuth } from '../../hooks/Auth2';
import api from '../../services/api';
import DashboardMasterPage from '../../masterpages/DashboardMasterPage';
import {
  Row,
  Col,
  Card,
  Tabs,
  Tab,
  Container,
  Nav,
  Table,
  DropdownButton,
  Dropdown,
  Pagination,
  TabPane,
} from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import EventsTab from './EventsTab';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { useTranslation } from 'react-i18next';
import Rsvp from './Rsvp';

const Events: React.FC = (props: any) => {
  const { user } = useAuth();
  console.log(user);
  function enableDisable(entity: any) {}

  const [lgShow, setLgShow] = useState(false);
  const { t } = useTranslation();

  if (user?.moderator === 'yes') {
    return <Rsvp />;
  }

  return (
    <DashboardMasterPage {...props}>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={t("events")}
              breadcrumbItems={[
                { title: 'Admin', link: '#' },
                { title: 'Dashboard', link: '#' },
              ]}
            />
            <Row>
              <Col sm={12} className="btn-page">
                <Card className="user-profile-list card-body">
                  <Card.Body>
                    <Tabs defaultActiveKey="active" className="mb-3">
                      <Tab eventKey="active" title={t("active events")}>
                        <TabPane>
                          <EventsTab type="active" />
                        </TabPane>
                      </Tab>

                      <Tab eventKey="inactive" title={t("unactive events")}>
                        <TabPane>
                          <EventsTab type="inactive" />
                        </TabPane>
                      </Tab>
                    </Tabs>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </DashboardMasterPage>
  );
};

export default Events;
