import React, { useEffect } from 'react';
import { Container } from './styles';
import { useEvent } from '../../hooks/EventContext';

const DefaultMasterPage: React.FC = (props: any) => {
  return (
    <Container id="login" className="container-fluid full-height" customization={{}}>
        <div className="row full-height">
          <div className="col-lg-12 col-md-12 align-self-center py-12  full-height">
            <div className="d-flex justify-content-center  full-height">
              <div className="form-s full-height">
                {props.children}
              </div>
            </div>
          </div>
        </div>
    </Container>
  );
};

export default DefaultMasterPage;
