import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import React from 'react';
import ptbr from "../assets/locales/pt-br.json";
import enus from "../assets/locales/en-us.json";

import { useEvent } from './EventContext';

const I18Provider: React.FC = ({ children }) => {

  const eventContext = useEvent();
  let lang = localStorage.getItem('defaultLanguage') || eventContext.event?.lang || `pt`;

  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: {
        en: enus,
        pt: ptbr
      },
      lng: lang,
      fallbackLng: ['pt', 'en', 'es'],
      interpolation: {
        escapeValue: false
      }
    });

  return <>
    {children}
  </>;
};

export { I18Provider };
