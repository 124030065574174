import React, { useState, useCallback, useEffect, useMemo } from 'react';
import 'react-day-picker/lib/style.css';
import 'firebase/firestore';
import 'firebase/auth';
import { useAuth } from '../../hooks/Auth2';
import api from '../../services/api';
import DashboardMasterPage from '../../masterpages/DashboardMasterPage';
import {
  Row,
  Col,
  Card,
  Container,
  Tabs,
  Tab,
  Nav,
  Table,
  DropdownButton,
  Dropdown,
  Pagination,
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { useTranslation } from 'react-i18next';
import CustomPagination from '../../components/CustomPagination';

const Agencies: React.FC = (props: any) => {
  const { user } = useAuth();
  const [filter, setFilter] = useState<any>({});
  const [list, setList] = useState<any[]>([]);
  const [pagination, setPagination] = useState<{ take: number; skip: number }>({
    take: 20,
    skip: 0,
  });
  const [actives, setActive] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const [isBusy, setIsBusy] = useState(false);
  const [update, setUpdate] = useState<number>(0);
  const history = useHistory();
  const {t}= useTranslation();

  useEffect(() => {
    setIsBusy(true);
    loadAgencies();
    // const _pagination = page
    // ? { ...pagination, skip: (page - 1) * pagination.take }
    // : pagination;

    // api.post('admin/agency/list').then((result) => {
    //   setList(result.data.result);
    //   setCount(result.data.count);
    // }).finally(() => {
    //   setIsBusy(false);
    // });;

    //fire

    return () => {};
  }, [filter, update, pagination]);

  function loadAgencies(page?: number, _filters?: any) {
      setIsBusy(true);

      const _pagination = page
        ? { ...pagination, skip: (page - 1) * pagination.take }
        : pagination;

        api.post('admin/agency/list', {
          filter: {...(_filters) },
          pagination: _pagination,
        }).then((result) => {
          setList(result.data.result);
          setCount(result.data.count);
        }).finally(() => {
          setIsBusy(false);
        });;
  }

  function edit(entity: any) {
    history.push(`/agency/${entity.id}`);
  }

  function deleteEntity(entity: any) {
    if (window.confirm(t("are you sure that you want to delete this?"))) {
      api.delete(`admin/agency/${entity.id}`).then((result) => {
        setUpdate(update + 1);
      });
    }
  }

  const changePage = (page: number) => {
    setPagination({ ...pagination, skip: (page - 1) * pagination.take });
    setActive(page);
  };

  let active = 2;
  let items = [];
  for (let num = 1; num <= count / 20; num++) {
    items.push(
      <Pagination.Item key={num} active={num === active}>
        {num}
      </Pagination.Item>,
    );
  }

  const [lgShow, setLgShow] = useState(false);

  return (
    <DashboardMasterPage {...props}>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={t("Agencias")}
              breadcrumbItems={[
                { title: 'Admin', link: '#' },
                { title: 'Dashboard', link: '#' },
              ]}
            />
            <Row>
              <Col sm={12} className="btn-page">
                <Card className="user-profile-list card-body">
                  <Card.Body>
                    <Table
                      striped
                      hover
                      id="data-table-zero"
                      className="table-condensed"
                    >
                      <thead>
                        <tr>
                          <th>{t("agency")}</th>
                          <th>{t("description")}</th>
                          <th>{t("date")}</th>
                          <th>{t("edition")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {list.map((item: any, i: number) => (
                          <tr key={i}>
                            <td>
                              {!!item.name
                                ? item.name.toUpperCase()
                                : item.key.toUpperCase()}
                            </td>
                            <td>{item.description}</td>
                            <td>{item.createdAt}</td>
                            <td>
                              <DropdownButton
                                id="dropdown-basic-button"
                                title={t("update")}
                              >
                                <Dropdown.Item onClick={() => edit(item)}>
                                  {t("edit")}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => deleteEntity(item)}
                                >
                                  {t("delete")}
                                </Dropdown.Item>
                              </DropdownButton>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    {!isBusy && count > 0 && (
                      <CustomPagination
                        page={actives}
                        count={count}
                        take={pagination.take}
                        onPageChange={(page) => changePage(page)}
                        size="sm"
                      />
                    )}
                    {/* <Pagination size="sm">{items}</Pagination> */}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </DashboardMasterPage>
  );
};

export default Agencies;
