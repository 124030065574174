import { createGlobalStyle, css } from 'styled-components';
import '@fortawesome/fontawesome-svg-core/styles.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/scss/style.scss';
import { shade } from 'polished';
import 'antd/dist/antd.css';
import 'rc-color-picker/assets/index.css';

interface ContainerPros {
  customization: any;
}

export default createGlobalStyle<ContainerPros>`

.bg-body {

}

.button_send_form
{
  ${(props) => css`
    background-color: ${props.customization.buttonBackgroundColor || '#000'};
    color: ${props.customization.buttonTextColor || '#FFF'};
  `}

  height: 56px;
  border-radius: 3px;
  border: 0;
  padding: 0 5px;
  width: 100%;
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: 8px;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${shade(0.2, '#2463B2')};
  }
}

#root {
  height: 100%;
  width: 100%;
}

span.error {
  color: #bf1650;
}

span.error::before {
  display: inline;
  content: "⚠ ";
}

.link,
.action-button {
  padding: 5px;
}

.card-body {
  padding-bottom: 100px;
}

.full-height {
  height: 100%;
}

`;

