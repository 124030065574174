import React from 'react';
import {
  Route as ReactDomRoute,
  RouteProps as ReactDomRouteProps,
  Redirect,
} from 'react-router-dom';

import { useAuth } from '../hooks/Auth2';
import DashboardMasterPage from '../masterpages/DashboardMasterPage';

interface RouteProps extends ReactDomRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<any> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const { user } = useAuth();

  return (
    <ReactDomRoute
      {...rest}
      render={() => {
        return isPrivate && !user ? (
          <Redirect to={`/signin`} />
        ) : (
          <Component {...rest} {...rest.computedMatch} />
        );
      }}
    />
  );
};

export default Route;
