import React, { useState, useEffect } from 'react';
import 'react-day-picker/lib/style.css';
import 'firebase/firestore';
import 'firebase/auth';
import api from '../../../services/api';
import {
  Row,
  Col,
  Table,
  Form,
  Dropdown,
  DropdownButton,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useToast } from '../../../hooks/Toast';
import firebase from 'firebase';
import { Button, Checkbox } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

type FormData = {
  apiToken?: string;
  symplaEventId?: string;
};

const Sympla: React.FC<{ event: any }> = (props: any) => {
  const [registerField, setRegisterField] = useState<FormData>({});
  const { register, getValues, setValue, handleSubmit, errors, reset } = useForm<
    FormData
  >({
    defaultValues: {
      apiToken: '',
      symplaEventId: '',
    },
  });
  const { addToast } = useToast();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [orders, setOrders] = useState<any>([]);
  const [symplaId, setSymplaId] = useState()
  const [checked, setChecked] = useState(false);
  const { t } = useTranslation();

  const onSubmit = async function ({ ...data }: any) {
    try {
      setLoadingSubmit(true);

      const register = {
        ...registerField,
        ...data,
        createdAt: moment.utc().valueOf()
      };
      register.eventId = props.event.id;
      register.eventKey = props.event.key;
      register.active = props.event.active;
      register.emailSend = checked;

      if (symplaId) {
        register.id = symplaId;
      }

      console.log('onSubmit', { registerField, data, register});

      await api.post('/admin/sympla', register);

      setRegisterField({});
      reset();

      addToast({
        type: 'success',
        title: t("successfull register"),
        description: t("your info was sent successfully"),
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: t("error on register"),
        description:
          error?.response?.data?.message ||
          error.message ||
          t("an error has ocurred on register, try again."),
      });
    }

    setLoadingSubmit(false);
  };

  useEffect(() => {

    if (props.params.id) {
      const unsubscribe = firebase
        .firestore()
        .collection('sympla')
        .where('eventId', '==', props.params.id)
        .onSnapshot((snapshot) => {
          const docs = snapshot.docs.map((m) => m.data());
          if (docs.length === 0) {
            return
          }
          else {
            const _keys = Object.keys(docs[0]);
            _keys.map((key) => setValue(key, docs[0][key]));
            setSymplaId(docs[0].id)
            setChecked(docs[0].emailSend)
            getOrders(docs[0].apiToken);
          }
        });
      const getOrders = (token: string) => {
        firebase
          .firestore()
          .collection('sympla_orders')
          .where('apiToken', '==', token)
          .onSnapshot((snapshot) => {
            const docs = snapshot.docs.map((m) => m.data());
            if (docs.length === 0) {
              return;
            }
            else {
              setOrders(docs)
            }
          });
      }

      return () => {
        unsubscribe();
      };
    }
  }, [props.params.id, loadingSubmit]);

  async function deleteEntity(entity: any) {
    if (window.confirm(t("are you sure that you want to delete this?"))) {
      const result = await api.delete('/admin/sympla/' + entity.id);
      setLoadingSubmit(true);
    }
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group as={Col} md="4">
            <Form.Label htmlFor="name">E-mail</Form.Label>
            <div className="site-checkbox-all-wrapper">
              <Checkbox
                onChange={(e) => setChecked(e.target.checked)}
                value={checked}
                checked={checked}
              >
                Send E-mail
              </Checkbox>
            </div>
          </Form.Group>
          <Form.Group as={Col} md="8">
            <Form.Label htmlFor="title">{t("API access key")}</Form.Label>
            <Form.Control
              type="text"
              name="apiToken"
              ref={register({ required: true })}
            />
            <>
              {errors[`apiToken`] && (
                <span className="error">{t("API access key is obligatory")}</span>
              )}
            </>
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label htmlFor="title">{t("event id")}</Form.Label>
            <Form.Control
              type="text"
              name="symplaEventId"
              ref={register({ required: true })}
            />
            <>
              {errors[`symplaEventId`] && (
                <span className="error">{t("event id is obligatory")}</span>
              )}
            </>
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label htmlFor="password">
            {t("event fixed password")}
            </Form.Label>
            <Form.Control
              type="text"
              name="password"
              ref={register({ required: false })}
            />
          </Form.Group>
          <Form.Group
            as={Col}
            sm={12}
            className="mt-3"
            style={{ textAlign: 'right' }}
          >
            <Button loading={loadingSubmit} htmlType="submit">
            {t("save")}
            </Button>
          </Form.Group>

      </Form>
      <Table size="sm" style={{ tableLayout: 'fixed' }}>
        <thead>
          <tr>
            <th style={{ width: '15%' }}>#</th>
            <th style={{ width: '30%' }}>{t("name")}</th>
            <th style={{ width: '20%' }}>Email</th>
            <th style={{ width: '10%' }}>{t("order status")}</th>
            <th style={{ width: '15%' }}>{t("transaction type")}</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((item: any, i: number) => (
            <tr key={i}>
              <th scope="row">{item.id}</th>
              <td>{item.buyer_first_name + " " + item.buyer_last_name}</td>
              <td>{item.buyer_email}</td>
              <td>{item.order_status}</td>
              <td>{item.transaction_type}</td>
              <td>
                <Row>
                  <Col xl={3} md={6}>
                    <DropdownButton title={t("update")}>
                      <Dropdown.Item onClick={() => deleteEntity(item)}>
                        {t("delete")}
                      </Dropdown.Item>
                    </DropdownButton>
                  </Col>
                </Row>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>

  );
};

export default Sympla;
