import React, { useState, useCallback, useEffect, useMemo } from 'react';
import 'react-day-picker/lib/style.css';
import 'firebase/firestore';
import 'firebase/auth';
import { useAuth } from '../../hooks/Auth2';
import api from '../../services/api';
import { ILanguage } from '../../interfaces/Languages';

import DashboardMasterPage from '../../masterpages/DashboardMasterPage';
import {
  Row,
  Col,
  Card,
  Container,
  Tabs,
  Tab,
  Nav,
  Table,
  DropdownButton,
  Dropdown,
  Pagination,
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { useTranslation } from 'react-i18next';

const Languages: React.FC = (props: any) => {
  const { user } = useAuth();
  const [filter, setFilter] = useState<any>({});
  const [list, setList] = useState<ILanguage[]>([]);
  const [count, setCount] = useState<number>(0);
  const [update, setUpdate] = useState<number>(0);
  const history = useHistory();
  const {t}= useTranslation();

  useEffect(() => {
    api.post('admin/language/list').then((result) => {
      setList(result.data.result);
      setCount(result.data.count);
    });

    //fire

    return () => {};
  }, [filter, update]);

  function edit(entity: any) {
    history.push(`/language/${entity.id}`);
  }

  function deleteEntity(entity: any) {
    if (window.confirm(t("are you sure that you want to delete this?"))) {
      api.delete(`admin/language/${entity.id}`).then((result) => {
        setUpdate(update + 1);
      });
    }
  }

  let active = 2;
  let items = [];
  for (let num = 1; num <= count / 20; num++) {
    items.push(
      <Pagination.Item key={num} active={num === active}>
        {num}
      </Pagination.Item>,
    );
  }

  const [lgShow, setLgShow] = useState(false);

  return (
    <DashboardMasterPage {...props}>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={t("languages")}
              breadcrumbItems={[
                { title: 'Admin', link: '#' },
                { title: 'Dashboard', link: '#' },
              ]}
            />
            <Row>
              <Col sm={12} className="btn-page">
                <Card className="user-profile-list card-body">
                  <Card.Body>
                    <Table
                      striped
                      hover
                      id="data-table-zero"
                      className="table-condensed"
                    >
                      <thead>
                        <tr>
                          <th>{t("language")}</th>
                          <th>Label</th>
                          <th>Flag</th>
                        </tr>
                      </thead>
                      <tbody>
                        {list.map((item, i: number) => (
                          <tr key={i}>
                            <td>{item.language}</td>
                            <td>{item.label}</td>
                            <td>
                              <img
                                src={item.flag}
                                style={{ maxWidth: '150px' }}
                              />
                            </td>
                            <td>
                              <DropdownButton
                                id="dropdown-basic-button"
                                title={t("update")}
                              >
                                <Dropdown.Item onClick={() => edit(item)}>
                                  {t("edit")}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => deleteEntity(item)}
                                >
                                  {t("delete")}
                                </Dropdown.Item>
                              </DropdownButton>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Pagination size="sm">{items}</Pagination>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </DashboardMasterPage>
  );
};

export default Languages;
