import React, { useState, useCallback, useEffect, useMemo } from 'react';
import 'react-day-picker/lib/style.css';
import 'firebase/firestore';
import 'firebase/auth';
import { useAuth } from '../../../hooks/Auth2';
import api from '../../../services/api';
import * as Yup from 'yup';
import DashboardMasterPage from '../../../masterpages/DashboardMasterPage';
import { Row, Col, Card, Form, Container, Alert } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { useToast } from '../../../hooks/Toast';
import { Checkbox } from 'antd';
import Button from '../../../components/Button';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { useTranslation } from 'react-i18next';

const CompanyDetails: React.FC<any> = (props: any) => {
  const { user } = useAuth();
  const [entity, setEntity] = useState<any>({});
  const [agencies, setAgencies] = useState<any[]>([]);
  const { register, setValue, handleSubmit, errors, reset } = useForm<any>();
  const history = useHistory();
  const { addToast } = useToast();
  const { t } = useTranslation();

  const onSubmit = handleSubmit(async (data: any) => {
    try {
      // const schema = Yup.object().shape({
      //   email: Yup.string()
      //     .required('E-mail é obrigatório')
      //     .email('Digite um e-mail válido'),
      //   password: Yup.string().min(6, 'Senha deve ter no mínimo 6 digitos'),
      // });

      // await schema.validate(data, {
      //   abortEarly: true,
      // });

      const result = await api.post('/admin/company', { ...entity, ...data });

      if (!props.modal && !props?.params?.id && result.data?.id) {
        history.replace(`/company/${result.data?.id}`);
      }

      addToast({
        type: 'success',
        title: t("successfull register"),
        description: t("your info was sent successfully"),
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: t("error on register"),
        description:
          error?.response?.data?.message ||
          error.message ||
          t("an error has ocurred on register, try again."),
      });
    }
  });

  useEffect(() => {
    reset();

    if (props?.params?.id) {
      api
        .get(`admin/company/details/${props.params.id}`)
        .then((result: any) => {
          setEntity(result.data);
        });
    } else {
      api.get(`admin/company/default`).then((result: any) => {
        setEntity(result.data);
      });
    }

    return () => {};
  }, [props?.params?.id]);

  useEffect(() => {
    setTimeout(() => {
      api.post(`admin/agency/list`).then((result: any) => {
        setAgencies(result.data.result);
      });
    }, 2000);

    return () => {};
  }, []);

  useEffect(() => {
    if (agencies.length && user?.agencyId) {
      setValue('agencyId', user.agencyId);
    }
  }, [agencies, user]);

  const content = (
    <Row>
      <Col sm={12} className="btn-page">
        <Card className="card-body">
          <Card.Body>
            <h6>{t("company data")}</h6>
            <hr />
            <Form onSubmit={onSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="6">
                  <Form.Label htmlFor="name">{t("company name")}</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    id="name"
                    placeholder={t("company name")}
                    autoComplete="off"
                    defaultValue={entity?.name}
                    ref={register({ required: true })}
                  />
                  <>
                    {errors[`name`] && (
                      <span className="error">{t("name is obligatory")}</span>
                    )}
                  </>
                </Form.Group>

                <Form.Group as={Col} md="12">
                  <Form.Label htmlFor="name">{t("description")}</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="description"
                    placeholder={t("description")}
                    autoComplete="off"
                    defaultValue={entity?.description}
                    ref={register}
                  />
                  <>
                    {errors[`description`] && (
                      <span className="error">{t("description is obligatory")}</span>
                    )}
                  </>
                </Form.Group>

                <Form.Group as={Col} md="12">
                  <Form.Label htmlFor="agencyId">{t("agency")}Agencia</Form.Label>
                  <Form.Control
                    as="select"
                    name="agencyId"
                    disabled={!!user?.agencyId}
                    defaultValue={user?.agencyId || entity?.agencyId || ''}
                    ref={register}
                  >
                    <option value="">{t("select an agency")}Selecione uma agencia</option>
                    {agencies.map((agency) => (
                      <option key={agency.id} value={agency.id}>
                        {agency.name}
                      </option>
                    ))}
                  </Form.Control>
                  <Alert className="mt-2" variant="secondary">
                    {t("fullfill this field to create an client/company for an agency")}
                  </Alert>
                </Form.Group>

                <Form.Group as={Col} sm={12} className="mt-3">
                  <Button type="submit">{t("save")}</Button>
                </Form.Group>
              </Form.Row>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );

  return (
    <>
      {!props.modal && (
        <DashboardMasterPage {...props}>
          <React.Fragment>
            <div className="page-content">
              <Container fluid>
                <Breadcrumbs
                  title={t("Companies")}
                  breadcrumbItems={[
                    { title: 'Admin', link: '#' },
                    { title: 'Dashboard', link: '#' },
                  ]}
                />
                {content}
              </Container>
            </div>
          </React.Fragment>
        </DashboardMasterPage>
      )}

      {props.modal && content}
    </>
  );
};

export default CompanyDetails;
