import React, { useState, useEffect, useRef } from 'react';
import 'react-day-picker/lib/style.css';
import 'firebase/firestore';
import 'firebase/auth';

import {
  Row,
  Col,
  Table,
  Form,
  Dropdown,
  DropdownButton,
  Pagination,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import country_region_data from 'country-region-data/data.json';

import { useToast } from '../../../hooks/Toast';
import Button from '../../../components/Button';
import api from '../../../services/api';

type FormData = {
  event_type: string;
  vendor: string;
  format: string;
  platform: string;
  initiative: string;
  event_status: string;
  start_date: string;
  end_date: string;
  duration: string;
  qtd_participants: string;
  event_location: string;
  event_uf: string;
  products: string[];
  qtd_products: string;
  bu: string;
  cod_mapp: string;
  platform_value: string;
  other_costs: string;
};

const MoreInfo: React.FC<{ event: any }> = (props: any) => {
  const refFile = useRef<HTMLInputElement>(null);
  const { t, i18n } = useTranslation();

  const [products, setProducts] = useState(['', '', '']);
  const [states, setStates] = useState<any[]>([]);
  const [registerField, setRegisterField] = useState<any>({});
  const { register, setValue, handleSubmit, errors, reset } = useForm<FormData>(
    {
      defaultValues: props?.event?.report_data || {
        products: ['', '', ''],
        // event_type: 'Consultoria',
        // vendor: 'RSTCOM',
        // format: 'Hibrido',
        // platform: 'Local',
        // initiative: 'Regional',
        // event_status: 'Realizado',
        // start_date: '08/02/2020',
        // end_date: '09/02/2020',
        // duration: '1 a 3 horas',
        // qtd_participants: '7',
        // event_location: 'Plataforma RSTCom',
        // event_uf: 'Sâo Paulo',
        // products: ['Eliquis', 'Bextra', 'N/A'],
        // qtd_products: '2',
        // bu: 'Vacines',
        // cod_mapp: 'MAPP-003579',
        // platform_value: '5000',
        // other_costs: '2500',
      },
    },
  );
  const { addToast } = useToast();

  const [isBusy, setIsBusy] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  useEffect(() => {
    const _states = country_region_data.filter(
      (r) => r.countryShortCode === 'BR',
    )?.[0]?.regions;

    setStates(_states);
  }, []);

  useEffect(() => {
    console.log('MoreInfo useEffect', props.event.report_data);
    if (props.event?.report_data)
      Object.keys(props.event.report_data).map((key) =>
        setValue(key, props.event.report_data[key]),
      );
  }, [props.event?.report_data]);

  const onSubmit = async function (data: any) {
    try {
      setLoadingSubmit(true);

      const $data = { ...props.event, report_data: data };

      console.log('save report data', $data);
      const result = await api.post('/admin/event', $data);

      addToast({
        type: 'success',
        title: t("successfull register"),
        description: t("your info was sent successfully"),
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: t("error on register"),
        description:
          error?.response?.data?.message ||
          error.message ||
          t("an error has ocurred on register, try again."),
      });
    }

    setLoadingSubmit(false);
  };

  console.log('render MoreInfo', country_region_data);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Row>
          <Form.Group as={Col} xs="12" md="4">
            <Form.Label htmlFor="event_type">{t("event type")}</Form.Label>
            <Form.Control
              type="text"
              name="event_type"
              autoComplete="off"
              ref={register({ required: false })}
            />
            <>
              {errors.event_type && (
                <span className="error">{errors.event_type?.message}</span>
              )}
            </>
          </Form.Group>

          <Form.Group as={Col} xs="12" md="4">
            <Form.Label htmlFor="vendor">{t("vendor")}</Form.Label>
            <Form.Control
              type="text"
              name="vendor"
              autoComplete="off"
              ref={register({ required: false })}
            />
            <>
              {errors.vendor && (
                <span className="error">{errors.vendor?.message}</span>
              )}
            </>
          </Form.Group>
          <Form.Group as={Col} xs="12" md="4">
            <Form.Label htmlFor="format">{t("format")}</Form.Label>
            <Form.Control
              type="text"
              name="format"
              autoComplete="off"
              ref={register({ required: false })}
            />
            <>
              {errors.format && (
                <span className="error">{errors.format?.message}</span>
              )}
            </>
          </Form.Group>
          <Form.Group as={Col} xs="12" md="4">
            <Form.Label htmlFor="platform">{t("platform")}</Form.Label>
            <Form.Control
              type="text"
              name="platform"
              autoComplete="off"
              ref={register({ required: false })}
            />
            <>
              {errors.platform && (
                <span className="error">{errors.platform?.message}</span>
              )}
            </>
          </Form.Group>
          <Form.Group as={Col} xs="12" md="4">
            <Form.Label htmlFor="initiative">{t("initiative")}</Form.Label>
            <Form.Control
              type="text"
              name="initiative"
              autoComplete="off"
              ref={register({ required: false })}
            />
            <>
              {errors.initiative && (
                <span className="error">{errors.initiative?.message}</span>
              )}
            </>
          </Form.Group>
          <Form.Group as={Col} xs="12" md="4">
            <Form.Label htmlFor="event_status">{t("event status")}</Form.Label>
            <Form.Control
              type="text"
              name="event_status"
              autoComplete="off"
              ref={register({ required: false })}
            />
            <>
              {errors.event_status && (
                <span className="error">{errors.event_status?.message}</span>
              )}
            </>
          </Form.Group>
          <Form.Group as={Col} xs="12" md="4">
            <Form.Label htmlFor="start_date">
              {t("event initial date")}
            </Form.Label>
            <Form.Control
              type="text"
              name="start_date"
              autoComplete="off"
              ref={register({ required: false })}
            />
            <>
              {errors.start_date && (
                <span className="error">{errors.start_date?.message}</span>
              )}
            </>
          </Form.Group>
          <Form.Group as={Col} xs="12" md="4">
            <Form.Label htmlFor="end_date">{t("event end date")}</Form.Label>
            <Form.Control
              type="text"
              name="end_date"
              autoComplete="off"
              ref={register({ required: false })}
            />
            <>
              {errors.end_date && (
                <span className="error">{errors.end_date?.message}</span>
              )}
            </>
          </Form.Group>
          <Form.Group as={Col} xs="12" md="4">
            <Form.Label htmlFor="duration">{t("hours total duration")}</Form.Label>
            <Form.Control
              type="text"
              name="duration"
              autoComplete="off"
              ref={register({ required: false })}
            />
            <>
              {errors.duration && (
                <span className="error">{errors.duration?.message}</span>
              )}
            </>
          </Form.Group>

          <Form.Group as={Col} xs="12" md="4">
            <Form.Label htmlFor="qtd_participants">
              {t("participants quantity")}
            </Form.Label>
            <Form.Control
              type="text"
              name="qtd_participants"
              autoComplete="off"
              ref={register({ required: false })}
            />
            <>
              {errors.qtd_participants && (
                <span className="error">
                  {errors.qtd_participants?.message}
                </span>
              )}
            </>
          </Form.Group>

          <Form.Group as={Col} xs="12" md="4">
            <Form.Label htmlFor="event_location">{t("event local")}</Form.Label>
            <Form.Control
              type="text"
              name="event_location"
              autoComplete="off"
              ref={register({ required: false })}
            />
            <>
              {errors.event_location && (
                <span className="error">{errors.event_location?.message}</span>
              )}
            </>
          </Form.Group>
          <Form.Group as={Col} xs="12" md="4">
            <Form.Label htmlFor="event_uf">{t("event state")}</Form.Label>
            <Form.Control
              as="select"
              type="text"
              name="event_uf"
              autoComplete="off"
              ref={register({ required: false })}
            >
              <option>{t("select")}</option>
              {states.map((region: any) => (
                <option>{region.name}</option>
              ))}
            </Form.Control>
            <>
              {errors.event_uf && (
                <span className="error">{errors.event_uf?.message}</span>
              )}
            </>
          </Form.Group>

          {products.map((p, i) => (
            <Form.Group key={`product_${i}`} as={Col} xs="12" md="4">
              <Form.Label htmlFor={`products[${i}]`}>
              {t("product")}Produto {i + 1}
              </Form.Label>
              <Form.Control
                type="text"
                name={`products[${i}]`}
                autoComplete="off"
                ref={register({ required: false })}
              />
              <>
                {errors.products?.[i] && (
                  <span className="error">{errors.products?.[i]?.message}</span>
                )}
              </>
            </Form.Group>
          ))}
          <Form.Group as={Col} xs="12" md="4">
            <Form.Label htmlFor="qtd_products">{t("product quantity")}</Form.Label>
            <Form.Control
              type="text"
              name="qtd_products"
              autoComplete="off"
              ref={register({ required: false })}
            />
            <>
              {errors.qtd_products && (
                <span className="error">{errors.qtd_products?.message}</span>
              )}
            </>
          </Form.Group>
          <Form.Group as={Col} xs="12" md="4">
            <Form.Label htmlFor="bu">BU</Form.Label>
            <Form.Control
              type="text"
              name="bu"
              autoComplete="off"
              ref={register({ required: false })}
            />
            <>
              {errors.bu && <span className="error">{errors.bu?.message}</span>}
            </>
          </Form.Group>
          <Form.Group as={Col} xs="12" md="4">
            <Form.Label htmlFor="cod_mapp">Cód. MAPP</Form.Label>
            <Form.Control
              type="text"
              name="cod_mapp"
              autoComplete="off"
              ref={register({ required: false })}
            />
            <>
              {errors.cod_mapp && (
                <span className="error">{errors.cod_mapp?.message}</span>
              )}
            </>
          </Form.Group>
          <Form.Group as={Col} xs="12" md="4">
            <Form.Label htmlFor="platform_value">{t("platform value")}</Form.Label>
            <Form.Control
              type="text"
              name="platform_value"
              autoComplete="off"
              ref={register({ required: false })}
            />
            <>
              {errors.platform_value && (
                <span className="error">{errors.platform_value?.message}</span>
              )}
            </>
          </Form.Group>
          <Form.Group as={Col} xs="12" md="4">
            <Form.Label htmlFor="other_costs">
              {t("other costs (comunication) total")}
            </Form.Label>
            <Form.Control
              type="text"
              name="other_costs"
              autoComplete="off"
              ref={register({ required: false })}
            />
            <>
              {errors.other_costs && (
                <span className="error">{errors.other_costs?.message}</span>
              )}
            </>
          </Form.Group>
        </Form.Row>
        <Form.Group
          as={Col}
          sm={12}
          className="mt-3"
          style={{ textAlign: 'right' }}
        >
          <Button loading={loadingSubmit} type="submit">
            {t("save")}
          </Button>
        </Form.Group>
      </Form>
    </>
  );
};

export default MoreInfo;
