import React from 'react';
import { Spinner } from 'react-bootstrap';

const Loader: React.FC = () => {
  return (
    <div
      className="d-inline-flex align-items-center"
      style={{ height: '30vh' }}
    >
      <Spinner className="mr-1" animation="grow" size="sm" />
      <Spinner className="mr-1" animation="grow" size="sm" />
      <Spinner animation="grow" size="sm" />
    </div>
  );
};

export default Loader;
