import React, { Component } from 'react';

// MetisMenu
import MetisMenu from 'metismenujs';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

//i18n
// import { withNamespaces } from 'react-i18next';

import { connect } from 'react-redux';
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader,
} from '../../store/actions';
import { withTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/Auth2';

const menu_items_base = [
  {
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'ri-dashboard-line',
  },
  {
    name: 'events',
    to: '/#',
    icon: 'ri-slideshow-2-line',
    items: [
      {
        name: 'new event',
        to: '/event',
      },
      {
        name: 'events list',
        to: '/events',
      },
    ],
  },
  {
    name: 'Chats',
    to: '/chats',
    icon: 'ri-question-answer-line',
  },
  {
    name: 'Timelines',
    to: '/timelines',
    icon: 'ri-question-answer-line',
  },
  {
    name: 'users',
    to: '/#',
    icon: 'ri-user',
    items: [
      {
        name: 'new user',
        to: '/user',
      },
      {
        name: 'users list',
        to: '/users',
      },
    ],
  },
  {
    name: 'companies',
    to: '/#',
    icon: 'ri-building-3-line',
    items: [
      {
        name: 'new company',
        to: '/company',
      },
      {
        name: 'companies list',
        to: '/companies',
      },
    ],
  },
  {
    name: 'Agencias',
    to: '/#',
    icon: 'ri-building-3-line',
    items: [
      {
        name: 'new agency',
        to: '/agency',
      },
      {
        name: 'agencies list',
        to: '/agencies',
      },
    ],
  },
  {
    name: 'languages',
    to: '/#',
    icon: 'ri-building-3-line',
    items: [
      {
        name: 'new language',
        to: '/language',
      },
      {
        name: 'languages list',
        to: '/languages',
      },
    ],
  },
  {
    name: 'Mail Marketing',
    to: '/#',
    icon: 'ri-dashboard-line',
    items: [
      {
        name: 'new e-mail',
        to: '/email',
      },
      {
        name: 'emails list',
        to: '/emails',
      },
    ],
  },
];

class SidebarContent extends Component {
  state = {
    menu_items: [],
    menu_items_base : [
      {
        name: 'Dashboard',
        to: '/dashboard',
        icon: 'ri-dashboard-line',
        isModerator: !!this.props.user?.moderator
      },
      {
        name: 'events',
        to: '/#',
        icon: 'ri-slideshow-2-line',
        items: [
          {
            name: 'new event',
            to: '/event',
          },
          {
            name: 'events list',
            to: '/events',
          },
        ],
        isModerator: !!this.props.user?.moderator
      },
      {
        name: 'Chats',
        to: '/chats',
        icon: 'ri-question-answer-line',
        isModerator: !!this.props.user?.moderator
      },
      {
        name: 'Timelines',
        to: '/timelines',
        icon: 'ri-question-answer-line',
        isModerator: !!this.props.user?.moderator
      },
      {
        name: 'users',
        to: '/#',
        icon: 'ri-user',
        items: [
          {
            name: 'new user',
            to: '/user',
          },
          {
            name: 'users list',
            to: '/users',
          },
        ],
        isModerator: !!this.props.user?.moderator
      },
      {
        name: 'companies',
        to: '/#',
        icon: 'ri-building-3-line',
        items: [
          {
            name: 'new company',
            to: '/company',
          },
          {
            name: 'companies list',
            to: '/companies',
          },
        ],
        isModerator: !!this.props.user?.moderator
      },
      {
        name: 'Agencias',
        to: '/#',
        icon: 'ri-building-3-line',
        items: [
          {
            name: 'new agency',
            to: '/agency',
          },
          {
            name: 'agencies list',
            to: '/agencies',
          },
        ],
        isModerator: !!this.props.user?.moderator
      },
      {
        name: 'languages',
        to: '/#',
        icon: 'ri-building-3-line',
        items: [
          {
            name: 'new language',
            to: '/language',
          },
          {
            name: 'languages list',
            to: '/languages',
          },
        ],
        isModerator: !!this.props.user?.moderator
      },
      {
        name: 'Mail Marketing',
        to: '/#',
        icon: 'ri-dashboard-line',
        items: [
          {
            name: 'new e-mail',
            to: '/email',
          },
          {
            name: 'emails list',
            to: '/emails',
          },
        ],
        isModerator: !!this.props.user?.moderator
      },
    ]
  };

  async componentDidMount() {
    const user = this.props.user;
    console.log('menu', user);
    const menu_items = menu_items_base.filter(
      (menu) =>
        !user.agencyId ||
        (menu.name !== 'Agencias' && menu.name !== 'Linguagens'),
    );

    await this.setState({ menu_items });
    this.state.menu_items.map((items) => {
      items.isModerator = !!this.props.user?.moderator
    })
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.type !== prevProps.type) {
        this.initMenu();
      }
    }
  }

  initMenu() {
    new MetisMenu('#side-menu');

    var matchingMenuItem = null;
    var ul = document.getElementById('side-menu');
    var items = ul.getElementsByTagName('a');
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add('active');
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show');

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add('mm-active');
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    const user = this.props.user;
    return (
      <React.Fragment>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{this.props.t('Menu')}</li>
            {this.state.menu_items_base.filter((menu) => !!menu.isModerator === false || menu.name === 'events').map((menu) => (
              <li key={`menu_${menu.name}`}>
                <Link
                  to={menu.to}
                  className={`waves-effect ${
                    menu.items?.length > 0 ? 'has-arrow' : ''
                  }`}
                >
                  <i className={menu.icon}></i>
                  {menu.badge && (
                    <span className="badge badge-pill badge-success float-right">
                      {menu.badge}
                    </span>
                  )}
                  <span className="ml-1">{this.props.t(menu.name)}</span>
                </Link>

                {menu.items && (
                  <ul
                    key={`submenu_${menu.name}`}
                    className="sub-menu"
                    aria-expanded="false"
                  >
                    {user.moderator === 'yes' ? (
                      <React.Fragment>
                        {menu.items
                          .filter((menu) => menu.name !== 'new event')
                          .map((filteredMenu) => (
                            <li key={`sub_menu_item_${filteredMenu.name}`}>
                              <Link to={filteredMenu.to}>
                                {this.props.t(filteredMenu.name)}
                              </Link>
                            </li>
                          ))}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {menu.items.map((subMenu) => (
                          <li key={`sub_menu_item_${subMenu.name}`}>
                            <Link to={subMenu.to}>
                              {this.props.t(subMenu.name)}
                            </Link>
                          </li>
                        ))}
                      </React.Fragment>
                    )}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

const Wrapper = (props) => {
  const { user } = useAuth();

  return <SidebarContent {...props} user={user} />;
};

const mapStatetoProps = (state) => {
  return { ...state.Layout };
};

export default withRouter(
  connect(mapStatetoProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changePreloader,
  })(withTranslation()(Wrapper)),
);
