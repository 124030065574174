import React, { useState, useEffect, useRef } from 'react';
import 'react-day-picker/lib/style.css';
import 'firebase/firestore';
import 'firebase/auth';

import {
  Row,
  Col,
  Table,
  Form,
  Dropdown,
  DropdownButton,
  Pagination,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import firebase from 'firebase';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';

import { useToast } from '../../../hooks/Toast';
import Button from '../../../components/Button';
import CustomPagination from '../../../components/CustomPagination';
import api from '../../../services/api';
import Loader from '../../../components/Loader';
import { useAuth } from '../../../hooks/Auth';
import Util from '../../../utils/util';

type FieldsItemType = {
  name: string;
};

const Participants: React.FC<{ event: any }> = (props: any) => {
  const refFile = useRef<HTMLInputElement>(null);
  const { user } = useAuth();
  const { t, i18n } = useTranslation();
  const [cleanParticipantsIsBusy, setCleanParticipantsIsBusy] = useState<
    boolean
  >(false);
  const [logoutParticipantsIsBusy, setLogoutParticipantsIsBusy] = useState<
    boolean
  >(false);
  const [active, setActive] = useState<number>(1);
  const [registerField, setRegisterField] = useState<any>({});
  const { register, setValue, handleSubmit, errors, reset } = useForm<any>({});
  const { addToast } = useToast();
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState('');
  const [filters, setFilters] = useState<any>({});
  const [pagination, setPagination] = useState<{ take: number; skip: number }>({
    take: 20,
    skip: 0,
  });
  const [fields, setFields] = useState<Array<FieldsItemType>>([]);
  const [participants, setParticipants] = useState<any[]>([]);
  const [resources, setResources] = useState<any>({});
  const [visibleImport, setVisibleImport] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>();
  const [import_email, setImportEmail] = useState('');
  const [allParticipants, setAllParticipants] = useState<any[]>([]);
  const [allParticipantsIsBusy, setAllParticipantsIsBusy] = useState(false);

  const showModal = () => {
    setVisibleImport(true);
  };

  const handleCancel = () => {
    setVisibleImport(false);
  };

  function edit(entity: any) {
    setRegisterField(entity);

    window.scrollTo({ top: 0, behavior: 'smooth' });

    for (const key in entity) {
      if (entity.hasOwnProperty(key)) {
        const element = entity[key];
        try {
          if (key != 'resources' && key != 'picture') {
            setValue(key, element);
          }
        } catch (error) { }
      }
    }
  }

  async function logout(entity: any) {
    if (
      window.confirm(
        `Você tem certeza que deseja deslogar o usuário ${entity.name || entity.email
        }?`,
      )
    ) {

      const sessionId = sessionStorage.getItem('4yourlive:tabid');

      await api.get(`/sessions/logout/${entity.id}/${sessionId}`).then((r) => {
        addToast({
          type: 'success',
          title: 'Usuário deslogado com sucesso',
          description: `O usuário ${entity.name} foi deslogado com sucesso!`,
        });
      });
    }
  }

  async function logoutParticipant(participant: any, attempts = 0) {
    const sessionId = sessionStorage.getItem('4yourlive:tabid');

    await api
      .get(`/sessions/logout/${participant.id}/${sessionId}`)
      .then((r) => {
        console.log('usuario deslogado', participant);
      })
      .catch(async () => {
        if (attempts < 10) {
          await Util.sleep(0.5);
          logoutParticipant(participant, attempts + 1);
        } else {
          console.log('logoutParticipant fail all attempts', participant);
        }
      });
  }

  async function logoutParticipants() {
    try {
      if (
        window.confirm(`Você tem certeza que deseja deslogar todos usuários?`)
      ) {
        setLogoutParticipantsIsBusy(true);

        console.log('logoutParticipants', allParticipants);

        for (let participant of allParticipants) {
          await logoutParticipant(participant);
          await Util.sleep(0.05);
        }

        alert('Usuários deslogados!');

        setLogoutParticipantsIsBusy(false);
      }
    } catch (e) {
      console.log('logoutParticipants error', e);
    }
  }

  function loadParticipants(page?: number, _filters?: any) {
    if (props.event.id) {
      setIsBusy(true);

      const _pagination = page
        ? { ...pagination, skip: (page - 1) * pagination.take }
        : pagination;

      api
        .post('/admin/participant/list', {
          filter: { eventId: props.event.id, ...(_filters || filters) },
          pagination: _pagination,
        })
        .then((r) => {
          setParticipants(r.data.result);
          getFields(r.data.result);
          setCount(r.data.count);
        })
        .finally(() => {
          setIsBusy(false);
        });
    }
  }

  function getFields(participants: any[]): Array<FieldsItemType> {
    const fields: Array<string> = [];

    participants.forEach(
      (p) => p.fields && Object.keys(p.fields).map((item) => fields.push(item)),
    );

    console.log('participants fields');

    const items = Array.from(new Set(fields))
      .filter((f) => f !== 'CRM' && f !== 'UF' && !f.startsWith('__EMPTY'))
      .map<FieldsItemType>((f) => ({
        name: f,
      }));

    setFields(items);
    return items;
  }

  async function deleteEntity(entity: any) {
    if (window.confirm('Você tem certeza que deseja deletar isto?')) {
      const result = await api.delete('/admin/participant/remove/' + entity.id);
      loadParticipants();
    }
  }

  useEffect(() => {
    reset();
    setRegisterField({});
    loadParticipants();
  }, [props.params.id, props.event.id, pagination]);

  useEffect(() => {
    console.log('useEffect participants', props);
    if (props.event?.id) {
      (async () => {
        setAllParticipantsIsBusy(true);
        let _participants: any[] = [];
        let count = 0;
        let page = 1;
        let take = 200;
        do {
          const _result = await api.post<{ result?: any[]; count?: number }>(
            `/admin/participant/list`,
            {
              filter: { eventId: props.event.id },
              pagination: { skip: (page - 1) * take, take },
            },
          );

          if (_result.data.count) count = _result.data.count;

          if (_result.data.result)
            _participants = [..._participants, ..._result.data.result];

          page++;
          console.log('dowhile', { _participants, count });
        } while (count > _participants.length);

        setAllParticipants(_participants);
        setAllParticipantsIsBusy(false);
      })();
    }
  }, [props.event]);

  // useEffect(() => {
  //   if (!resources.list) {
  //     const data = firebase
  //       .firestore()
  //       .collection('settings')
  //       .doc('resources')
  //       .get()
  //       .then((doc) => {
  //         setResources(doc.data());
  //       });
  //   }
  // }, [props.params.id]);

  const onSelectedFile = (file: React.ChangeEvent<HTMLInputElement>) => {
    if (file.currentTarget.files !== null)
      setSelectedFile(file.currentTarget.files[0]);
  };

  function handleFilterChange(e: React.ChangeEvent<HTMLInputElement>) {
    const _value = e.currentTarget.value;
    setFilter(_value);
  }

  function handleFilterClick() {
    setActive(1);
    setFilters((prev: any) => {
      if (!filter) {
        const { name, email, ..._filter } = prev;
        loadParticipants(1, _filter);

        return _filter;
      }

      const _filters = {
        ...prev,
        name: filter,
        email: filter,
      };

      loadParticipants(1, _filters);

      return _filters;
    });
  }

  function handleEnterKeyPressed(e: React.KeyboardEvent<HTMLInputElement>) {
    console.log('key', { key: e.key, code: e.keyCode });
    if (e.key.toLowerCase() === 'enter') {
      handleFilterClick();
    }
  }

  async function upload(inputFile: any): Promise<string> {
    if (inputFile) {
      const uploaded: any = await firebase
        .storage()
        .ref(`/companies/${props.event.key}/importation/${v4()}`)
        .put(inputFile);
      return (await uploaded.ref.getDownloadURL()) || '';
    }

    return '';
  }

  const importFile = async () => {
    try {
      setLoading(true);

      if (!selectedFile) {
        addToast({
          type: 'error',
          title: t("select a file"),
          description: t("file is obligatory"),
        });
        return;
      }

      if (!import_email) {
        addToast({
          type: 'error',
          title: t("inform an email for notification"),
          description: t("email is obligatory"),
        });
        return;
      }

      const link = await upload(selectedFile);

      const result = await api.post('/admin/participant/import', {
        eventId: props.event.id,
        eventKey: props.event.key,
        email: import_email,
        link,
      });

      addToast({
        type: 'success',
        title: t("importation sent successfully"),
        description: t("your info was sent successfully"),
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: t("error on register"),
        description:
          error?.response?.data?.message ||
          error.message ||
          t("an error has ocurred on register, try again."),
      });
    } finally {
      setLoading(false);
    }
    setSelectedFile(undefined);
    if (refFile?.current) refFile.current.value = '';
    setImportEmail('');
    setVisibleImport(false);
  };

  const changePage = (page: number) => {
    setPagination({ ...pagination, skip: (page - 1) * pagination.take });
    setActive(page);
  };

  async function cleanParticipants() {
    try {
      if (window.confirm(t("are you sure that you want to clear the participants?"))) {
        setCleanParticipantsIsBusy(true);

        const _response = await api.delete(
          `/admin/participant/byevent/${props.event?.id}`,
        );

        // get all participants with current filter and without pagination
        // const _response = await api.post('/admin/participant/list', {
        //   filter: { eventId: props.event.id, ...filters },
        //   pagination: { take: count, skip: 0 },
        // });

        // const _participants = _response.data.result;

        // for (const participant of _participants) {
        //   const result = await api.delete(
        //     '/admin/participant/' + participant.id,
        //   );
        // }

        loadParticipants();
        setCleanParticipantsIsBusy(false);
      }
    } catch (error) { }
  }

  const onSubmit = async function (data: any) {
    try {
      setLoadingSubmit(true);
      const fields = { ...registerField.fields, ...data.fields }; // merge fields to prevent data loss
      const register = {
        ...registerField,
        ...data,
        fields,
      };
      register.password = data.password?.trim();
      register.eventId = props.event.id;
      register.eventKey = props.event.key;

      const result = await api.post('/admin/participant', register);
      console.log('resultado:', result);
      setRegisterField({});
      reset();
      loadParticipants();

      addToast({
        type: 'success',
        title: t("successfull register"),
        description: t("your info was sent successfully"),
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: t("error on register"),
        description:
          error?.response?.data?.message ||
          error.message ||
          t("an error has ocurred on register, try again."),
      });
    }

    setLoadingSubmit(false);
  };

  console.log('participants render', { participants, event: props?.event });

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Row>
          <Form.Group as={Col} md="6">
            <Form.Label htmlFor="key">{t("name")}</Form.Label>
            <Form.Control
              defaultValue={registerField.name}
              type="text"
              name="name"
              autoComplete="off"
              ref={register({ required: true })}
            />
            <>
              {errors[`name`] && (
                <span className="error">{t("name is obligatory")}</span>
              )}
            </>
          </Form.Group>

          <Form.Group as={Col} md="6">
            <Form.Label htmlFor="email">E-mail</Form.Label>
            <Form.Control
              defaultValue={registerField.email}
              type="text"
              name="email"
              autoComplete="off"
              ref={register({ required: true })}
            />
            <>
              {errors[`email`] && (
                <span className="error">{t("email is obligatory")}</span>
              )}
            </>
          </Form.Group>

          <Form.Group as={Col} md="6">
            <Form.Label htmlFor="password">{t("password")}</Form.Label>
            <Form.Control
              type="text"
              name="password"
              autoComplete="off"
              ref={register({ required: false })}
            />
          </Form.Group>

          <Form.Group as={Col} md="3">
            <Form.Label htmlFor="fields[CRM]">CRM</Form.Label>
            <Form.Control
              defaultValue={registerField.fields?.CRM}
              type="text"
              name="fields[CRM]"
              autoComplete="off"
              ref={register({ required: false })}
            />
          </Form.Group>

          <Form.Group as={Col} md="3">
            <Form.Label htmlFor="fields[UF]">UF</Form.Label>
            <Form.Control
              maxLength={2}
              defaultValue={registerField.fields?.UF}
              type="text"
              name="fields[UF]"
              autoComplete="off"
              ref={register({ required: false })}
            />
          </Form.Group>

          {fields.map(
            (field) =>
              field.name !== 'avatar' && (
                <Form.Group as={Col} md="4" key={`field.${field.name}`}>
                  <Form.Label htmlFor={`fields[${field}]`}>
                    {field.name}
                  </Form.Label>
                  <Form.Control
                    defaultValue={registerField.fields?.[field.name]}
                    type="text"
                    name={`fields[${field.name}]`}
                    autoComplete="off"
                    ref={register({ required: false })}
                  />
                </Form.Group>
              ),
          )}

          <Form.Group as={Col} md="4">
            <Form.Label htmlFor="role">{t("type")}</Form.Label>
            <Form.Control
              name="role"
              as="select"
              value={registerField.role || 'participant'}
              onChange={(e) => {
                setRegisterField({ ...registerField, role: e.target.value });
              }}
              ref={register({ required: true })}
            >
              <option value="participante">{t("participant")}</option>
              <option value="moderator">{t("moderator")}</option>
              <option value="attendance">{t("attendance")}</option>
            </Form.Control>
            <>
              {errors[`role`] && (
                <span className="error">{t("type is obligatory")}</span>
              )}
            </>
          </Form.Group>
        </Form.Row>
        <Form.Group
          as={Col}
          sm={12}
          className="mt-3"
          style={{ textAlign: 'right' }}
        >
          <Button loading={loadingSubmit} type="submit">
            {t("save")}
          </Button>
        </Form.Group>
      </Form>

      <div>
        <div className="row">
          <div className="col-12  col-md-auto d-flex text-right">
            <Button type="button" onClick={showModal}>
              {t("import sheet")}
            </Button>
          </div>
          <div className="col-12 col-md-auto d-flex text-right">
            <Button
              type="button"
              loading={cleanParticipantsIsBusy}
              onClick={cleanParticipants}
            >
              {t("clean participants")}
            </Button>
          </div>
          <div className="col-12 col-md-auto d-flex text-right">
            <Button
              type="button"
              loading={logoutParticipantsIsBusy}
              disabled={allParticipantsIsBusy}
              onClick={logoutParticipants}
            >
              {t("logout all users")}
            </Button>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-12 col-md">
            <div className="form-inline">
              <label>{t("filter")}: </label>

              <input
                type="text"
                name="filter"
                placeholder={t("search for participant name or email")}
                className="form-control flex-grow-1 ml-2 mr-2"
                value={filter}
                onChange={(e) => handleFilterChange(e)}
                onKeyUp={handleEnterKeyPressed}
              />
              <button
                className="btn btn-primary m-0"
                onClick={handleFilterClick}
              >
                Filtrar
              </button>
            </div>
          </div>
        </div>
        {/* <div style={{ textAlign: 'left', padding: 10 }}>
          <Button type="primary" onClick={showModal}>
            Importar CSV
          </Button>
        </div> */}

        <Modal
          visible={visibleImport}
          title={t("import participants sheet")}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              {t("cancel")}
            </Button>,
            <Button
              key="submit"
              type="button"
              loading={loading}
              onClick={importFile}
            >
              {t("import")}
            </Button>,
          ]}
        >
          <Form.Group as={Col} md={12}>
            <Form.Label htmlFor="import">{t("import list")}</Form.Label>
            <div className="custom-file">
              <Form.File
                ref={refFile}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  console.log('file on change', e);
                  onSelectedFile(e);
                }}
                accept=".xlsx"
                label={t("select file")}
                name="import"
              />
            </div>
          </Form.Group>
          <Form.Group as={Col} md={12}>
            <Form.Label htmlFor="import_email">E-mail</Form.Label>
            <div className="custom-file">
              <Form.Control
                value={import_email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setImportEmail(e.target.value);
                }}
                placeholder={t("email for notification")}
                name="import_email"
              />
            </div>
          </Form.Group>
        </Modal>
      </div>

      <Table size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>{t("name")}</th>
            <th>Email</th>
            <th>{t("type")}</th>
            <th>Status</th>
            <th>{t("update")}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {isBusy && (
            <tr>
              <td colSpan={5} className="text-center">
                <Loader />
              </td>
            </tr>
          )}
          {!isBusy &&
            participants.map((item: any, i: number) => (
              <tr key={active * pagination.take + i}>
                <th scope="row">{i + 1}</th>
                <td>{item.name}</td>
                <td>{item.email}</td>
                <td>{item.role}</td>
                <td>{item.status !== 0 ? t("active") : t("inactive")}</td>
                <td>
                  <Row>
                    <Col xl={3} md={6}>
                      <DropdownButton title={t("update")}>
                        <Dropdown.Item onClick={() => edit(item)}>
                          {t("edit")}
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => logout(item)}>
                          {t("logout")}
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => deleteEntity(item)}>
                          {t("delete")}
                        </Dropdown.Item>
                      </DropdownButton>
                    </Col>
                  </Row>
                </td>
                <td></td>
              </tr>
            ))}
        </tbody>
      </Table>
      {!isBusy && count > 0 && (
        <CustomPagination
          page={active}
          count={count}
          take={pagination.take}
          onPageChange={(page) => changePage(page)}
          size="sm"
        />
      )}
    </>
  );
};

export default Participants;
