import React, { useState, useCallback, useEffect, useMemo } from 'react';
import 'react-day-picker/lib/style.css';
import 'firebase/firestore';
import 'firebase/auth';
import { useAuth } from '../../hooks/Auth2';
import api from '../../services/api';
import DashboardMasterPage from '../../masterpages/DashboardMasterPage';
import {
  Row,
  Col,
  Card,
  Container,
  Tabs,
  Tab,
  Nav,
  Table,
  DropdownButton,
  Dropdown,
  Pagination,
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const Users: React.FC = (props: any) => {
  const { user } = useAuth();
  const [filter, setFilter] = useState<any>('');
  const [list, setList] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);
  const [update, setUpdate] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<any>({});
  const [agencies, setAgencies] = useState<Array<{ name: string }>>([]);
  const { t } = useTranslation();

  const history = useHistory();
  const qtd = 20;

  const getUsers = useCallback(() => {
    api
      .post('admin/participant/list', {
        pagination: {
          skip: (page - 1) * qtd,
          take: qtd,
        },
        filter: {
          ...filters,
          $or: [{ eventKey: { $exists: false } }, { eventKey: null }],
          role: 'admin',
        },
      })
      .then((result) => {
        setList(result.data.result);
        setCount(result.data.count);
      });
  }, [page, filters]);

  useEffect(() => {
    getUsers();
    return () => {};
  }, [page, filters, getUsers]);

  function edit(entity: any) {
    history.push(`/user/${entity.id}`);
  }

  function deleteEntity(entity: any) {
    if (window.confirm(t("are you sure that you want to delete this?"))) {
      api.delete(`admin/participant/${entity.id}`).then((result) => {
        getUsers();
      });
    }
  }

  function handleFilterChange(e: React.ChangeEvent<HTMLInputElement>) {
    const _value = e.currentTarget.value;
    setFilter(_value);
  }

  const handleFilterClick = React.useCallback(() => {
    let _filters = {};
    if (!filter) {
      const { name, email, ..._filter } = filters;
      _filters = _filter;
    } else {
      const _regex = new RegExp(`${filter}`);
      _filters = {
        ...filters,
        name: filter.trim(),
        email: filter.trim(),
      };
    }

    setFilters(_filters);
  }, [filter, filters]);

  function handleEnterKeyPressed(e: React.KeyboardEvent<HTMLInputElement>) {
    console.log('key', { key: e.key, code: e.keyCode });
    if (e.key.toLowerCase() === 'enter') {
      handleFilterClick();
    }
  }

  let active = 2;
  let items = [];
  for (let num = 1; num <= Math.ceil(count / qtd); num++) {
    items.push(
      <Pagination.Item
        key={num}
        active={num === page}
        onClick={() => setPage(num)}
      >
        {num}
      </Pagination.Item>,
    );
  }

  console.log('render', { filter, filters });

  return (
    <DashboardMasterPage {...props}>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={t("users")}
              breadcrumbItems={[
                { title: 'Admin', link: '#' },
                { title: 'Dashboard', link: '#' },
              ]}
            />
            <Row>
              <Col sm={12} className="btn-page">
                <Card className="user-profile-list card-body">
                  <Card.Body>
                    <div className="row mb-2">
                      <div className="col-12">
                        <div className="form-inline">
                          <label>{t("filter")}: </label>

                          <input
                            type="text"
                            name="filter"
                            placeholder={t("search for event name or key")}
                            className="form-control flex-grow-1 ml-2 mr-2"
                            value={filter}
                            onChange={(e) => handleFilterChange(e)}
                            onKeyUp={handleEnterKeyPressed}
                          />
                          <button
                            className="btn btn-primary m-0"
                            onClick={handleFilterClick}
                          >
                            Filtrar
                          </button>
                        </div>
                      </div>
                      <div className="col-6 d-flex align-items-end"></div>
                    </div>

                    <Table
                      striped
                      hover
                      id="data-table-zero"
                      className="table-condensed"
                    >
                      <thead>
                        <tr>
                          <th>{t("name")}</th>
                          <th>Email</th>
                          <th>Agency</th>
                          <th>{t("created at")}</th>
                          <th>{t("edition")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {list.map((item: any, i: number) => (
                          <tr key={i}>
                            <td>
                              {!!item.name
                                ? item.name.toUpperCase()
                                : item.email.toUpperCase()}
                            </td>
                            <td>{item.email}</td>
                            <td>{item.agency?.name || '-'}</td>
                            <td>
                              {moment(item.createdAt).format(
                                'DD/MM/YYYY HH:mm',
                              )}
                            </td>
                            <td>
                              <DropdownButton
                                id="dropdown-basic-button"
                                title={t("update")}
                              >
                                <Dropdown.Item onClick={() => edit(item)}>
                                  {t("edit")}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => deleteEntity(item)}
                                >
                                  {t("delete")}
                                </Dropdown.Item>
                              </DropdownButton>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Pagination size="sm">{items}</Pagination>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </DashboardMasterPage>
  );
};

export default Users;
