import React, { useState, useCallback, useEffect, useMemo } from 'react';
import 'react-day-picker/lib/style.css';
import 'firebase/firestore';
import 'firebase/auth';
import { useAuth } from '../../../hooks/Auth2';
import api from '../../../services/api';
import * as Yup from 'yup';
import DashboardMasterPage from '../../../masterpages/DashboardMasterPage';
import { Row, Col, Card, Form, Container, Alert } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { useToast } from '../../../hooks/Toast';
import { Checkbox } from 'antd';
import countryList from 'react-select-country-list';
import Button from '../../../components/Button';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { useTranslation } from 'react-i18next';

type FormData = {
  name: string;
  email: string;
  agencyId: string;
  password: string;
  nationality: string;
  moderator: string;
};

const UserDetails: React.FC<any> = (props: any) => {
  const { user } = useAuth();
  const [entity, setEntity] = useState<any>({});
  const [agencies, setAgencies] = useState<any[]>([]);
  const { register, setValue, handleSubmit, errors, reset, watch } = useForm<
    FormData
  >({
    defaultValues: {
      name: '',
      email: '',
      agencyId: '',
      password: '',
      moderator: '',
      nationality: '',
    },
  });
  const history = useHistory();
  const { addToast } = useToast();
  const { t } = useTranslation();
  const countries = useMemo(() => countryList().getData(), []);

  const onSubmit = handleSubmit(async (data: any) => {
    try {
      const result = await api.post('/admin/participant', {
        ...entity,
        ...data,
        role: 'admin',
      });

      if (!props.modal && !props?.params?.id && result.data?.id) {
        history.replace(`/user/${result.data?.id}`);
      }

      addToast({
        type: 'success',
        title: t("successfull register"),
        description: t("your info was sent successfully"),
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: t("error on register"),
        description:
          error?.response?.data?.message ||
          error.message ||
          t("an error has ocurred on register, try again."),
      });
    }
  });

  useEffect(() => {
    reset();

    if (props?.params?.id && agencies.length > 0) {
      api
        .get(`admin/participant/details/${props.params.id}`)
        .then((result: any) => {
          setEntity(result.data);
        });
    } else {
      api.get(`admin/participant/default`).then((result: any) => {
        setEntity(result.data);
      });
    }

    return () => {};
  }, [props?.params?.id, agencies]);

  useEffect(() => {
    setTimeout(() => {
      api.post(`admin/agency/list`).then((result: any) => {
        setAgencies(result.data.result);
      });
    }, 2000);

    return () => {};
  }, []);

  useEffect(() => {
    if (entity?.id) {
      console.log('useEffect', { entity });
      Object.keys(entity).map((key) => setValue(key, entity[key]));
      setValue('agencyId', entity['agencyId']);
    }
  }, [entity]);

  console.log('render agency', {
    entity,
    agencyId2: entity?.agencyId,
    agencyId: watch('agencyId'),
    name: watch('name'),
  });

  return (
    <>
      {!props.modal && (
        <DashboardMasterPage {...props}>
          <React.Fragment>
            <div className="page-content">
              <Container fluid>
                <Breadcrumbs
                  title={t("companies")}
                  breadcrumbItems={[
                    { title: 'Admin', link: '#' },
                    { title: 'Dashboard', link: '#' },
                  ]}
                />
                <Row>
                  <Col sm={12} className="btn-page">
                    <Card className="card-body">
                      <Card.Body>
                        <h6>{t("users data")}</h6>
                        <hr />

                        <Form onSubmit={onSubmit}>
                          <Form.Row>
                            <Form.Group as={Col} md="12">
                              <Form.Label htmlFor="nationality">
                                Nacionalidade
                              </Form.Label>
                              <Form.Control
                                as="select"
                                name="nationality"
                                defaultValue="Brazil"
                                ref={register}
                              >
                                <option value="" hidden>
                                  Nacionalidade
                                </option>
                                {countries.map((country) => (
                                  <option
                                    key={country.value}
                                    value={country.label}
                                  >
                                    {country.label}
                                  </option>
                                ))}
                              </Form.Control>
                            </Form.Group>

                            <Form.Group as={Col} md="12">
                              <Form.Label htmlFor="name">{t("name")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="name"
                                id="name"
                                placeholder= {t("name")}
                                autoComplete="off"
                                ref={register({ required: true })}
                              />
                              <>
                                {errors[`name`] && (
                                  <span className="error">
                                    {t("name is obligatory")}
                                  </span>
                                )}
                              </>
                            </Form.Group>

                            <Form.Group as={Col} md="12">
                              <Form.Label htmlFor="email">E-mail</Form.Label>
                              <Form.Control
                                type="text"
                                name="email"
                                placeholder="E-mail"
                                autoComplete="off"
                                ref={register({ required: true })}
                              />
                              <>
                                {errors[`email`] && (
                                  <span className="error">
                                    {t("email is obligatory")}
                                  </span>
                                )}
                              </>
                            </Form.Group>

                            <Form.Group as={Col} md="12">
                              <Form.Label htmlFor="agencyId">
                                {t("agency")}
                              </Form.Label>
                              <Form.Control
                                as="select"
                                name="agencyId"
                                defaultValue={entity?.agencyId || ''}
                                ref={register}
                              >
                                <option value="" hidden>{t("select an agency")}</option>
                                {agencies.map((agency) => (
                                  <option key={agency.id} value={agency.id}>
                                    {agency.name}
                                  </option>
                                ))}
                              </Form.Control>
                              <Alert className="mt-2" variant="secondary">
                                {t("fullfill this field to create an administrator for an agency or leave in blank to create an administrator system")}
                              </Alert>
                            </Form.Group>

                            <Form.Group as={Col} md="12">
                              <Form.Label htmlFor="moderator">
                                Moderador
                              </Form.Label>
                              <Form.Control
                                as="select"
                                name="moderator"
                                defaultValue="no"
                                ref={register}
                              >
                                <option value="yes">Sim</option>
                                <option value="no">Não</option>
                              </Form.Control>
                              <Alert className="mt-2" variant="secondary">
                                Marque 'Sim' para criar um moderador.
                              </Alert>
                            </Form.Group>

                            <Form.Group as={Col} md="12">
                              <Form.Label htmlFor="password">{t("password")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="password"
                                placeholder={t("password")}
                                autoComplete="off"
                                ref={register}
                              />
                              <>
                                {errors[`password`] && (
                                  <span className="error">
                                    {t("password is obligatory")}
                                  </span>
                                )}
                              </>
                            </Form.Group>

                            <Form.Group as={Col} sm={12} className="mt-3">
                              <Button type="submit">{t("save")}</Button>
                            </Form.Group>
                          </Form.Row>
                        </Form>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          </React.Fragment>
        </DashboardMasterPage>
      )}

      {/* {props.modal && content} */}
    </>
  );
};

export default UserDetails;
