import React, { Component } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';

//i18n
// import { withNamespaces } from 'react-i18next';

const Breadcrumbs: React.FC<any> = ({ breadcrumbItems, ...props }) => {
  const { t } = useTranslation();
  const itemsLength = breadcrumbItems?.length || 0;

  return (
    <React.Fragment>
      <Row>
        <Col xs={12}>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h4 className="mb-0">{t(props.title)}</h4>

            <div className="page-title-right">
              <Breadcrumb listClassName="m-0">
                {breadcrumbItems?.map((item: any, key: number) =>
                  key + 1 === itemsLength ? (
                    <BreadcrumbItem key={key} active>
                      {t(item.title)}
                    </BreadcrumbItem>
                  ) : (
                    <BreadcrumbItem key={key}>
                      <Link to={item.link}>{t(item.title)}</Link>
                    </BreadcrumbItem>
                  ),
                )}
              </Breadcrumb>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Breadcrumbs;
